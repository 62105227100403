import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJournalEntries, createJournalEntry } from "../app/Service/journalSlice";
import { fetchAccounts, selectAccounts } from "../app/Service/accountsSlice";
import { Table, Spin, Alert, Card, Modal, Button, Form, Input, Select, DatePicker } from "antd";
import dayjs from "dayjs";

const { Option } = Select;

const JournalEntries = () => {
    const dispatch = useDispatch();
    const journalEntries = useSelector((state) => state.journal.entries);
    const loading = useSelector((state) => state.journal.loading);
    const error = useSelector((state) => state.journal.error);
    const accounts = useSelector(selectAccounts);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(fetchJournalEntries());
        dispatch(fetchAccounts());
    }, [dispatch]);

    // Handle Add Journal Entry
    const handleAddJournalEntry = () => {
        form.validateFields()
            .then((values) => {
                dispatch(createJournalEntry(values));
                setIsModalOpen(false);
                form.resetFields();
            })
            .catch((info) => console.log("Validation Failed:", info));
    };

    // Flatten journal entries to have separate rows for debit and credit
    const flattenedData = journalEntries.flatMap((entry) =>
        entry.entries.map((subEntry, index) => ({
            key: `${entry._id}-${index}`,
            entryNo: entry.entryNo,
            date: dayjs(entry.date).format("YYYY-MM-DD hh:mm A"), // 12-hour format with AM/PM
            description: entry.description,
            account: subEntry.account.name,
            debit: subEntry.debit || null,
            credit: subEntry.credit || null,
            type: subEntry.debit > 0 ? "debit" : "credit",
        }))
    );

    const columns = [
        { title: "Entry No", dataIndex: "entryNo", key: "entryNo" },
        { title: "Date", dataIndex: "date", key: "date" },
        { title: "Description", dataIndex: "description", key: "description" },
        { title: "Account", dataIndex: "account", key: "account" },
        {
            title: "Debit",
            dataIndex: "debit",
            key: "debit",
            render: (value) => (value ? <b style={{ color: "green" }}>{value?.toFixed(2)}</b> : ""),
        },
        {
            title: "Credit",
            dataIndex: "credit",
            key: "credit",
            render: (value) => (value ? <b style={{ color: "red" }}>{value?.toFixed(2)}</b> : ""),
        }
    ];

    return (
        <Card title="Journal Entries">
            {/* <Button type="primary" onClick={() => setIsModalOpen(true)} style={{ marginBottom: "10px" }}>
                Add Journal Entry
            </Button> */}

            {loading && <Spin />}
            {error && <Alert type="error" message={error} />}

            <Table
                columns={columns}
                dataSource={flattenedData}
                rowKey="key"
                bordered
                rowClassName={(record, index) => (index % 2 === 0 ? "white-row" : "gray-row")}
                summary={(pageData) => {
                    const totalDebit = pageData.reduce((sum, record) => sum + (record.debit || 0), 0);
                    const totalCredit = pageData.reduce((sum, record) => sum + (record.credit || 0), 0);

                    return (
                        <Table.Summary.Row style={{ backgroundColor: "#f0f0f0", fontSize: "16px" }}>
                            <Table.Summary.Cell index={0} colSpan={4} style={{ fontWeight: "bold", textAlign: "center" }}>
                                Total
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} style={{ fontWeight: "bold", color: "green", textAlign: "center" }}>
                                {totalDebit?.toFixed(2)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} style={{ fontWeight: "bold", color: "red", textAlign: "center" }}>
                                {totalCredit?.toFixed(2)}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}

            />

            {/* Custom Styles for Table Rows */}
            <style>
                {`
        .white-row { background-color: #ffffff; } /* White */
        .gray-row { background-color: #f5f5f5; } /* Light gray */
    `}
            </style>
            <Modal
                title="Add Journal Entry"
                open={isModalOpen}
                onOk={handleAddJournalEntry}
                onCancel={() => setIsModalOpen(false)}
                okText="Add"
                cancelText="Cancel"
            >
                <Form form={form} layout="vertical">
                    <Form.Item label="Date" name="date" rules={[{ required: true, message: "Please select a date!" }]}>
                        <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                        <Input placeholder="Enter description" />
                    </Form.Item>
                    <Form.Item label="Account" name={["entries", 0, "account"]} rules={[{ required: true, message: "Select an account!" }]}>
                        <Select placeholder="Select Account">
                            {accounts?.map((account) => (
                                <Option key={account._id} value={account._id}>
                                    {account.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Debit" name={["entries", 0, "debit"]}>
                        <Input type="number" placeholder="Enter debit amount" />
                    </Form.Item>
                    <Form.Item label="Credit" name={["entries", 0, "credit"]}>
                        <Input type="number" placeholder="Enter credit amount" />
                    </Form.Item>
                </Form>
            </Modal>


        </Card>
    );
};

export default JournalEntries;
