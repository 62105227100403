import React, { useEffect, useRef, useState } from 'react';
import { Table, Button, Input, Dropdown, Menu, Pagination, Card, Flex, Modal, Form, Space, Select, Row, Col, Spin, Tooltip, Upload, notification, Progress, List, Tag } from 'antd';
import { FilterOutlined, FileExcelOutlined, FilePdfOutlined, PrinterOutlined, MinusCircleOutlined, PlusOutlined, CloseOutlined, SearchOutlined, PrinterFilled, ImportOutlined, EditFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { exportplot, fetchplot, handlePrint, impotplot, selectLoading, selectplot } from '../app/Service/plotSlice';
import { useDispatch, useSelector } from 'react-redux'
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { useForm } from 'antd/es/form/Form';
import { fetchBlocks, fetchPhases, resetBlocks, resetPhases, selectBlocks, selectPhases } from '../app/Service/DataSlice';
import { uploadSingleFile } from '../app/Service/fileSlice';
import axios from 'axios';
import { plotServices } from '../app/Service/plotService';
import AssignFile from './AssignFile';
import PlotModal from './PlotModal';
import { fetchWitNominee } from '../app/Service/witNomineeSlice';
import { fetchmember } from '../app/Service/memberslice';
const FileList = () => {
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate()
  const [start, setStart] = useState(0)
  const [limit, setLimit] = useState(100)
  const [Company, setCompany] = useState()
  const company_name = useSelector(selectcompanies_name)
  const [valuestext, setvaluestext] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleAssign, setIsModalVisibleAssign] = useState(false);
  const Plots = useSelector(selectplot)
  const dispatch = useDispatch()
  const Phases = useSelector(selectPhases)
  const blocks = useSelector(selectBlocks)
  const loading = useSelector(selectLoading)
  const scrollRef = useRef(null);
  const [filterType, setFilterType] = useState()
  const [form] = useForm()
  const [isBottom, setIsBottom] = useState(false);
  const [Plots__, setPlots__] = useState([]);
  const handleSearch = (e) => {
    setvaluestext(e.target.value)
    dispatch(fetchplot({ id: Company, query: e.target.value, limit, start }));
  };
  const [modalVisible, setModalVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [messages, setMessages] = useState([]);
  const [records, setRecords] = useState([]);
  const [summary, setSummary] = useState({ added: 0, updated: 0, missed: 0 });
  const [isLoading, setLoading] = useState(loading)
  const [record_, setRecord] = useState()
  useEffect(() => {
    dispatch(getCompaniesByName({}));
    dispatch(resetPhases())
    dispatch(resetBlocks())
  }, []);

  useEffect(() => {
    if (company_name?.length > 0) {
      form.setFieldsValue({
        company: company_name[0]?._id
      })
      setCompany(company_name[0]?._id)
      dispatch(fetchplot({ id: company_name[0]?._id, query: "", limit, start }));
    }
  }, [company_name])


  useEffect(() => {
    if (Company?.length > 0) {
      dispatch(resetPhases())
      dispatch(resetBlocks())
      setFilterType()
      form.resetFields(["text", "Phase", "Block"])
      dispatch(fetchPhases({ modelName: "Phase", company_id: Company }))

      // dispatch(fetchplot({ id: company_name, query: "", limit, start }));
    }
  }, [Company])



  const tableContainerStyle = {
    width: "100%",
    maxWidth: "100%",
    overflowX: "auto",
    margin: "20px 0",
    border: "1px solid #ddd",
    borderRadius: "5px",
  };
  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
  };
  const theadStyle = {
    backgroundColor: "#ddd",
    color: "black",
    position: "sticky",
    top: "0",
    zIndex: "2",
    with: "10%"
  };
  const cellStyle = {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "left",
    backgroundColor: "#fff",
    with: "10%"

  };
  const cellStyle1 = {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "left",
    backgroundColor: "#fff",
    // with: "5%"

  };
  const rowStyleEven = {
    backgroundColor: "#f9f9f9",
  };
  const hoverEffect = {
    backgroundColor: "#cce5ff",
    transition: "0.3s ease",
  };
  const handleSelectphase = (e) => {
    if (e === 'Block') {
      form.resetFields(["text", "Phase", "Block"])
    }
    if (e === 'Phase') {
      form.resetFields(["text", "Phase", "Block"])
    }
    if (e === "Phase" || e === "Block") {
      dispatch(fetchPhases({ modelName: "Phase", company_id: Company }))
      setFilterType(e)
    } else {
      setFilterType(e)
    }
  }
  const handleFiinish = async (values) => {
    setPlots__([])
    dispatch(fetchplot({ id: values.company, query: JSON.stringify(values), limit, start }));
  }
  const handlePhaseSelect = (e) => {
    dispatch(resetBlocks())
    form.resetFields(["Block"])
    dispatch(fetchBlocks({ modelName: "Block", company_id: Company, Phase: e }));
  }
  const handelUploadFile = async ({ file }) => {
    try {
      if (!Company) {
        notification.error({
          message: "Please Select Company",
          description: "There was an error uploading the file. Please try again.",
          placement: "topRight",
        });
        return;
      }

      let values = form.getFieldsValue();
      setModalVisible(true);
      setMessages([]); // Clear previous messages
      setRecords([]);  // Clear previous records
      setProgress(0);  // Reset progress
      setSummary({ added: 0, updated: 0, missed: 0 });

      const res = await dispatch(uploadSingleFile(file));
      if (res.meta.requestStatus === "fulfilled") {
        const fileId = res?.payload.file._id;

        const url = process.env.REACT_APP_SEVER;
        const response = await fetch(
          `${url}/api/plot/import/data?fileId=${fileId}&id=${Company}&pid=${values?.Phase}&did=${values?.Block}`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value, { stream: true });

          chunk.split("\n").forEach((line) => {
            if (line) {
              try {
                const data = line?.length > 5 && JSON.parse(line);

                if (data.message) {
                  setMessages((prev) => [...prev, data.message]);
                }
                if (data.progress) {
                  setProgress(data.progress);
                }
                if (data.record) {
                  setRecords((prev) => [...prev, data.record]);
                }
                if (data.error) {
                  console.error("Error:", data.error);
                  notification.error({
                    message: "Import Error",
                    description: data.error,
                    placement: "topRight",
                  });
                  reader.cancel();
                }

                // Handle Added, Updated, and Missed Records
                if (data.AddedRecords !== undefined) {
                  setSummary((prev) => ({
                    added: (data.AddedRecords || 0),
                    updated: (data.UpdatedRecords || 0),
                    missed: (data.MissedRecords || 0),
                  }));
                  if (data.record && data.action) {
                    setMessages((prev) => [
                      ...prev,
                      `${data.action === "Added" ? "✅" : data.action !== "Updated" ? "❌" : "✏️"} Record ${data.record} : ${data.action}`,
                    ]);
                  }
                }
                // Close modal after completion
                if (data.final) {
                  setTimeout(() => setModalVisible(true), 2000);
                }
              } catch (parseError) {
                console.error("JSON Parse Error:", parseError);
              }
            }
          });
        }
      }
    } catch (error) {
      console.error("Error handling file upload:", error);
      notification.error({
        message: "File Upload Failed",
        description: "There was an error uploading the file. Please try again.",
        placement: "topRight",
      });
    }
  };
  let array1 = Plots?.plots || []
  let table = [...array1, ...Plots__]
  const handleScroll = async (event) => {
    const { target } = event;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 10) {
      let array2 = Plots__ ? Plots__ : []
      if (table.length < Plots?.totalCount) {
        setLoading(true)
        let values = form.getFieldsValue();
        const data = await plotServices.getAllplot({ id: values.company, query: JSON.stringify(values), limit, start })
        setStart(start + limit);
        let newdata = [...array2, ...data?.plots]
        setPlots__(newdata)
        setLoading(false)
      }
    }
  };

  const columns = [
    { title: "File ID", dataIndex: "File_ID", key: "file_id" },

    {
      title: "Phase",
      dataIndex: ["Phase", "Phase"],
      key: "phase",
      width: 80,
    },
    {
      title: "Block",
      dataIndex: ["Block", "Block"],
      key: "block",
      width: 100,

    },
    {
      title: "Plot No", dataIndex: "Plot_No", key: "plot_no",
      width: 80,

    },
    { title: "Street", dataIndex: "Street", key: "street", width: 80 },

    // { title: "Purpose", dataIndex: "Purpose", key: "purpose" },
    {
      title: "Residential Type",
      dataIndex: ["Residential_Type", "Residential"],
      key: "residential_type"
    },
    {
      title: "Property Type",
      dataIndex: ["Property_Type", "Property"],
      key: "property_type"
    },
    { title: "Land Size", dataIndex: "Land_Size", key: "land_size", width: 100 },
    { title: "City", dataIndex: "City", key: "city" },

    {
      title: "Dimensions",
      key: "dimensions",
      width: 100,

      render: (_, record) =>
        record?.Length && record?.Width
          ? `${record.Length} x ${record.Width} ${record.Unit || ""}`
          : "N/A"
    },

    {
      title: "File Status",
      dataIndex: "file_status",
      key: "file_status",
      render: (text) => (
        <Button size={"small"} color={text === "Open" ? "purple" : "danger"} shape={"round"} variant="filled">
          {text}
        </Button>
      ),
      width: 100,  // Adjust width if needed
    },

    // { title: "Address", dataIndex: "Address", key: "address" },


    {
      title: "Status",
      dataIndex: "Status",
      key: "status",
      render: (status) => status.length ? status.map(s => s.Status).join(", ") : "N/A"
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          {/* <Button type="link" onClick={() => handleEdit(record)}>Edit</Button> */}
          {/* <Button type="link" danger onClick={() => handleDelete(record._id)}>Delete</Button> */}
          <Button size={"small"} type={"primary"} onClick={() => {
            setRecord(record)
            dispatch(fetchWitNominee(Company))
            dispatch(fetchplot({ id: Company, query: "", limit: "", start: "" }));
            dispatch(fetchmember({ id: Company, }));
            setIsModalVisibleAssign(true)
          }}>Assign</Button>

          <Button size={"small"} color={"green"} onClick={() => {
            setRecord(record)
            setIsModalVisible(true)
          }} icon={<EditFilled />}></Button>
        </Space>
      )
    }
  ];


  return (
    <Card title="File List" extra={<>    <Button
      type="primary" onClick={() => setIsModalVisible(true)}>+ Add File</Button></>}>
      <div>
        <AssignFile open={isModalVisibleAssign} onClose={() => {
          setRecord(null);
          setIsModalVisibleAssign(false)
        }} data={record_} />

        <Modal
          width={600}
          title="File Import Progress"
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
        >
          {/* Progress Bar */}
          <Progress percent={progress} status="active" />

          {/* Messages List */}
          <List
            size="small"
            bordered
            dataSource={messages}
            renderItem={(msg) => <List.Item>{msg}</List.Item>}
            style={{ marginTop: 10, maxHeight: 200, overflowY: "auto" }}
          />

          {/* Summary Section */}
          <div style={{ marginTop: 10 }}>
            <strong>📌 Summary:</strong>
            <p>✅ Added: {summary.added}</p>
            <p>✏️ Updated: {summary.updated}</p>
            <p>❌ Missed: {summary.missed}</p>
          </div>
        </Modal>
        {isModalVisible &&
          <PlotModal
            visible={isModalVisible}
            onClose={() => {
              setRecord(null)
              setIsModalVisible(false)
              setPlots__([])
            }}
            company={Company}
            record={record_}
          />}
        <Flex justify={"space-between"} align={"center"}>

          <Form form={form} onFinish={handleFiinish}>
            <Space>
              <Form.Item noStyle name={"company"}>
                <Select
                  placeholder="Select Company"
                  value={Company}
                  options={company_name?.map(company => ({
                    label: company?.companyName,
                    value: company?._id,
                  }))}
                  style={{ width: 150, marginBottom: 16 }}
                  onChange={(e) => setCompany(e)}
                />
              </Form.Item>
              <>
                <Form.Item noStyle name={"Phase"} >
                  <Select placeholder="Select Phase"
                    allowClear
                    style={{ width: 150, marginBottom: 16 }} onSelect={handlePhaseSelect}
                    options={Phases?.data?.map((item, i) => ({
                      label: item.Phase,
                      key: i,
                      value: item?._id
                    }))}
                  />
                </Form.Item>
                <Form.Item noStyle name={"Block"}>
                  <Select placeholder="Select Block"
                    allowClear
                    style={{ width: 150, marginBottom: 16 }}
                    options={blocks?.data?.map((item, i) => ({
                      label: item.Block,
                      key: i,
                      value: item?._id
                    }))}
                  />

                </Form.Item>
                <Form.Item noStyle name={"text"}>
                  <Input
                    placeholder="Search File"
                    value={valuestext}
                    style={{ width: 150, marginBottom: 16 }}
                  />
                </Form.Item>
              </>
              <Button htmlType='submit' style={{ marginBottom: 16 }} icon={<SearchOutlined />} type="primary"> </Button>
            </Space>
          </Form>

          <div style={{ marginBottom: 16 }}>
            <span color='success' style={{ marginRight: "10px" }}>
              {table?.length} of {Plots?.totalCount}
            </span>


            <Tooltip title="Import">
              <Upload
                accept=".xls,.xlsx"
                showUploadList={false}
                beforeUpload={() => false}
                onChange={handelUploadFile} >

                <Button variant={'outlined'} color={'danger'} icon={<ImportOutlined />} style={{ marginRight: 8 }}></Button>
              </Upload>
            </Tooltip>
            <Tooltip title="Export in to Excel"> <Button variant={'outlined'} onClick={() => dispatch(exportplot({ type: "excel", id: Company, start, limit }))} color={"primary"} icon={<FileExcelOutlined />} style={{ marginRight: 8 }} /></Tooltip>
            <Tooltip title="Export in to Pdf">
              <Button variant={'outlined'} onClick={() => dispatch(exportplot({ type: "pdf", id: Company, start, limit }))} icon={<FilePdfOutlined />} style={{ marginRight: 8, borderColor: "green", color: "green" }} /> </Tooltip>
            <Tooltip title="Print"><Button variant={'outlined'} onClick={() => dispatch(handlePrint(Company))} style={{ borderColor: "orange", color: "orange" }} icon={<PrinterFilled />} /> </Tooltip>
          </div>
        </Flex>
        <Table
          columns={columns}
          dataSource={table}
          rowKey="_id"
          pagination={false}  // Disables pagination
          scroll={{ y: `calc(100vh - 180px)` }}  // Enables vertical scrolling
          bordered
          loading={isLoading || !table}
          onScroll={handleScroll}
          size={"small"}
        />
      </div>
    </Card >
  );
};

export default FileList;
