import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Card, Flex, Form, Space, Select, Typography, Tag, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { fetchChargesApplied, fetchWithScrollChargesApplied, selectChargesApplied, selectChargesAppliedLoading } from '../app/Service/ChargesSlice';
import dayjs from 'dayjs';
import ImportCharges from './ImportCharges';
import ReceviePayment from './ReceviePayment';
import AdvancedReceipt from '../Reports/Recept';

const { Title } = Typography;

const Charges = () => {
    const [Company, setCompany] = useState();
    const company_name = useSelector(selectcompanies_name);
    const Charges = useSelector(selectChargesApplied);
    const loading = useSelector(selectChargesAppliedLoading);


    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [limit, setLimit] = useState(100)
    const [start, setStart] = useState(0)


    useEffect(() => {
        dispatch(getCompaniesByName({}));
    }, []);

    useEffect(() => {
        if (company_name?.length > 0) {
            form.setFieldsValue({ company: company_name[0]?._id });
            setCompany(company_name[0]?._id);
            dispatch(fetchChargesApplied({ id: company_name[0]?._id, limit, start: start }));
            setStart(0)

        }
    }, [company_name]);

    useEffect(() => {
        if (Company?.length > 0) {
            form.resetFields(["text", "Phase", "Block"]);
        }
    }, [Company]);
    const columns = [
        { title: "#", key: "index", width: 50, render: (text, record, index) => index + 1 },
        { title: "C-ID", dataIndex: "sr", key: "sr", },
        { title: "File Number", dataIndex: ["fileId", "File_ID"], key: "File_ID", width: 100 },

        { title: "Charges", dataIndex: ["chargeName", "ChargeName"], key: "ChargeName", width: 100 },
        { title: "Amount", dataIndex: "amount", key: "amount" },
        { title: "Discount", dataIndex: "discountPrice", key: "discountPrice" },
        { title: "Downpayment", dataIndex: "downPayment", key: "downPayment" },
        { title: "Left amount", dataIndex: "leftAmount", key: "Paid amount" },
        {
            title: "Installment",
            dataIndex: "installment",
            key: "installment",
            render: (status) => {
                let color = status === 0 ? "#3364ff" : "#52c41a";
                return <Tag color={color}>{status == 0 ? "NO" : "YES"}</Tag>;
            },
        },
        { title: "Instal... Type", dataIndex: "installmentType", key: "installmentType" },
        { title: "T Installment", dataIndex: "numPayments", key: "numPayments" },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status, data) => {
                let color = status === "Paid" ? "#87d068" : status === "Partially Paid" ? "#108ee9" : "#f50";
                return <Space>
                    <Tag color={color}>{status}</Tag>
                    {(status === "Paid") ? <AdvancedReceipt record={data} data={null} /> : ""}
                </Space>;
            },
        },


    ];



    const testRecord = {
        fileId: { File_ID: "12345ABC" },
        chargeName: { ChargeName: "Service Fee" },
        company_id: {
            companyName: "Tech Solutions Ltd.",
            address: "123 Business St, Tech City",
            phone: "+1234567890"
        },
        sr: "56789",
        installment: true,
        amount: 500,
        paidAmount: 450,
        status: "Paid",
        paymentMethod: "Credit Card"
    };

    const testData = {
        sr: "98765",
        amount: 250,
        paidAmount: 250,
        status: "Paid",
        paymentMethod: "PayPal"
    };

    const expandedRowRender = (record) => {
        const chargeColumns = [
            { title: "Sr#", dataIndex: "sr", key: "sr", width: 50 },
            { title: "Amount", dataIndex: "amount", key: "amount" },
            { title: "Paid amount", dataIndex: "paidAmount", key: "Paid amount" },
            { title: "Per Day Fine", dataIndex: "per_day_fine", key: "per_day_fine" },
            { title: "OverdueDays", dataIndex: "overdueDays", key: "overdueDays" },
            { title: "Fine Amount", dataIndex: "fineAmount", key: "fineAmount" },

            { title: "Left amount", dataIndex: "leftAmount", key: "Paid amount" },

            { title: "Last Date", dataIndex: "lastDate", key: "lastDate", render: (date) => dayjs(date).format("MMM, DD YYYY") },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (status, data, index) => {
                    let color = status === "Paid" ? "#87d068" : status === "Partially Paid" ? "#108ee9" : "#f50";
                    return <Space>
                        <Tag color={color}>{status}</Tag>
                        {(status === "Paid") ? <AdvancedReceipt record={record} data={data} /> : ""}
                    </Space>;;
                },
            },

        ];
        return <Table columns={chargeColumns} dataSource={record.charges_list} pagination={false} />;
    };
    const handleFinish = async (values) => {
        dispatch(fetchChargesApplied({ id: company_name[0]?._id, fid: values.charge, query: values.text, limit, start: start }));
        setStart(0)
    }
    const handlescroll = async (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;

        if (scrollTop + clientHeight >= scrollHeight - 1 && Charges?.count > Charges?.data?.length) {
            const values = form.getFieldsValue()
            dispatch(fetchWithScrollChargesApplied({ id: company_name[0]?._id, fid: values.charge, query: values.text, limit: limit, start: start + limit }))
            setStart(start + limit)
        }
    };



    return (
        <Card style={{ height: "100vh" }} title={<Title level={4}>📂 Charges</Title>} extra={<Space><ImportCharges Company={Company} /> <ReceviePayment Company={Company} limit={limit} setStart={setStart} />

        </Space>}>
            {/* <AdvancedReceipt record={testRecord} data={testData} /> */}

            <Flex justify="space-between" style={{ height: "50px" }} align="center">
                <Form form={form} onFinish={handleFinish}>
                    <Space>
                        <Form.Item noStyle name={"company"}>
                            <Select
                                placeholder="Select Company"
                                value={Company}
                                options={company_name?.map(company => ({
                                    label: company?.companyName,
                                    value: company?._id,
                                }))}
                                style={{ width: 150, marginBottom: 16 }}
                                onChange={(e) => setCompany(e)}
                            />
                        </Form.Item>
                        <Form.Item noStyle name={"charge"}>
                            <Select
                                placeholder="Select Phase"
                                allowClear
                                style={{ width: 250, marginBottom: 16 }}
                                // onSelect={handlePhaseSelect}
                                options={[{
                                    label: 'C-ID',
                                    value: "C-ID"
                                }, {
                                    label: "File Number",
                                    value: "File Number"
                                }, {
                                    label: 'Charge Name',
                                    value: "Charge Name"
                                }, {
                                    label: 'IsInstallment',
                                    value: "IsInstallment"
                                }, {
                                    label: 'Installment Type',
                                    value: "Installment Type"
                                }]}
                            />
                        </Form.Item>
                        <Form.Item noStyle name={"text"}>
                            <Input placeholder="Search File" style={{ width: 250, marginBottom: 16 }} />
                        </Form.Item>

                        <Button htmlType='submit' style={{ marginBottom: 16 }} icon={<SearchOutlined />} type="primary" >Search</Button>
                    </Space>
                </Form>
                show {Charges?.data?.length} / {Charges?.count}
            </Flex>

            <Table
                columns={columns}
                dataSource={Charges?.data}
                pagination={false}
                loading={loading}
                expandable={{
                    expandedRowRender,
                    rowExpandable: (record) => record.installment === 1,

                }}
                scroll={{ y: `calc(100vh - 160px)` }}
                rowKey="_id"
                onScroll={handlescroll}
            />
        </Card>
    );
};

export default Charges;
