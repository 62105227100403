import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { departmentServices } from "./departmentService";
export const fetchDepartments = createAsyncThunk(
    "department/fetchAll",
    async ({ id,query, limit, start }, { rejectWithValue }) => {
        try {
            return await departmentServices.getAllDepartments({ id,query, limit, start });
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.error : error.message);
        }
    }
);
export const getDepartmentByName = createAsyncThunk(
    "department/name",
    async ({ }, { rejectWithValue }) => {
        try {
            return await departmentServices.getDepartmentsByName({});
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.error : error.message);
        }
    }
);
export const handlePrint = createAsyncThunk(
    "department/print",
    async (id, { rejectWithValue }) => {
        try {
            return await departmentServices.handlePrint(id);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);
export const exportDepartment = createAsyncThunk(
    "department/export",
    async (query, { rejectWithValue }) => {
        try {
            return await departmentServices.exportDepartments(query);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

export const addDepartment = createAsyncThunk(
    "department/add",
    async (data, { rejectWithValue }) => {
        try {
            return await departmentServices.createDepartment(data);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);
export const importDepartment = createAsyncThunk(
    "department/import",
    async (data, { rejectWithValue }) => {
        try {
            return await departmentServices.importDepartment(data);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

export const updateDepartment = createAsyncThunk(
    "department/update",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await departmentServices.updateDepartment(id, data);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);
export const deleteDepartment = createAsyncThunk(
    "department/delete",
    async (id, { rejectWithValue }) => {
        try {
            await departmentServices.deleteDepartment(id);
            return id;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

const departmentSlice = createSlice({
    name: "department",
    initialState: {
        departments: [],
        loading: false,
        error: null,
        importLoading: false,
        importError: null,
        importSuccess: false,
        department_name: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDepartments.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchDepartments.fulfilled, (state, action) => {
                state.loading = false;
                state.departments = action.payload;
            })
            .addCase(fetchDepartments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(getDepartmentByName.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDepartmentByName.fulfilled, (state, action) => {
                state.loading = false;
                state.department_name = action.payload;
            })
            .addCase(getDepartmentByName.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(addDepartment.fulfilled, (state, action) => {

            })
            .addCase(updateDepartment.fulfilled, (state, action) => {

            })
            .addCase(deleteDepartment.fulfilled, (state, action) => {

            })
            .addCase(importDepartment.pending, (state) => {
                state.importLoading = true;
                state.importSuccess = false;
                state.importError = null;
            })
            .addCase(importDepartment.fulfilled, (state, action) => {
                state.importLoading = false;
                state.importSuccess = true;

            })
            .addCase(importDepartment.rejected, (state, action) => {
                state.importLoading = false;
                state.importError = action.error.message;
            });
    },
});

export const selectDepartment = (state) => state.departmentSlice.departments;
export const selectDepartments_name = (state) => state.departmentSlice.department_name;
export const selectLoading = (state) => state.departmentSlice.loading;
export const selectError = (state) => state.departmentSlice.error;
export const selectImportLoading = (state) => state.departmentSlice.importLoading;
export const selectImportError = (state) => state.departmentSlice.importError;
export const selectImportSuccess = (state) => state.departmentSlice.importSuccess;

export default departmentSlice.reducer;
