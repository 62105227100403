import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Flex, Form, Input, List, Row, Select, Space, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react'
const { Text } = Typography
export default function SearchFile() {
    const owners = [
        {
            label: "Asad",
            value: "Asad",
            avatar: "https://i.pravatar.cc/40?img=1",
            cnic: "42101-1234567-8",
            phone: "+92 312 3456789",
            father: "Muhammad Ali",
        },
        {
            label: "John Doe",
            value: "John Doe",
            avatar: "https://i.pravatar.cc/40?img=2",
            cnic: "42201-9876543-2",
            phone: "+92 311 9876543",
            father: "David Doe",
        },
        {
            label: "Jane Smith",
            value: "Jane Smith",
            avatar: "https://i.pravatar.cc/40?img=3",
            cnic: "42301-1122334-5",
            phone: "+92 333 1122334",
            father: "Robert Smith",
        },
    ];
    return (
        <Form layout='vertical'
            name="dynamic_form_complex"
            autoComplete="off"
            initialValues={{ items: [{}] }}>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Card title={"Search File"} className='search_card'>
                        <Select
                            allowClear
                            showSearch
                            style={{
                                width: "100%",
                                height: "60px",
                                fontSize: "16px",
                                borderRadius: "8px",
                                border: "1px solid #ddd",
                                background: "#fff",
                            }}
                            placeholder="Select an Owner"
                            options={owners.map((owner) => ({
                                label: (
                                    <>
                                        <Flex justify={"space-between"}>
                                            <div style={{ width: "300px" }}>
                                                <Space>
                                                    <Avatar src={owner.avatar} size={50} />

                                                    <div>
                                                        {<Text strong>{owner.label}</Text>}
                                                        {<p strong>{owner.father}</p>}
                                                    </div>

                                                </Space>
                                            </div>
                                            <div>
                                                <Space direction={"vertical"}>
                                                    {<p strong>{owner.cnic}</p>}
                                                    {<p strong>{owner.phone}</p>}

                                                </Space>
                                            </div>
                                        </Flex>
                                    </>
                                ),
                                value: owner.value,
                            }))}
                        />
                        {/* <Form.Item label="Description" >
                            <TextArea style={{ width: "100%" }} rows={3} maxLength={380}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum auctor libero eu justo vulputate, nec efficitur erat efficitur. Curabitur ac turpis et lacus gravida malesuada at id eros. Vestibulum auctor libero eu justo vulputate, nec efficitur erat efficitur. Curabitur ac turpis et lacus gravida alesuada at id eros. Vestibulum auctor libero eu justo vulputate, nec efficitur erat efficitur. Curabitur ac tu
                            </TextArea>
                        </Form.Item> */}
                    </Card>
                </Col>
            </Row>
        </Form>
    )
}
