import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Table, Button, Modal, Form, Input, Spin, Alert, Space } from "antd";
import { fetchSuppliers, addSupplier, deleteSupplier, selectSuppliers, selectSupplierLoading, selectSupplierError } from "../app/Service/supplierSlice";

const SupplierList = () => {
    const dispatch = useDispatch();
    const suppliers = useSelector(selectSuppliers);
    const loading = useSelector(selectSupplierLoading);
    const error = useSelector(selectSupplierError);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(fetchSuppliers());
    }, [dispatch]);

    // Handle Add Supplier
    const handleAddSupplier = () => {
        form.validateFields()
            .then((values) => {
                dispatch(addSupplier(values));
                setIsModalOpen(false);
                form.resetFields();
            })
            .catch((error) => console.log("Validation Failed:", error));
    };

    // Handle Delete Supplier
    const handleDelete = (id) => {
        dispatch(deleteSupplier(id));
    };

    // Table Columns
    const columns = [
        {
            title: "Supplier Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Contact",
            dataIndex: "contact",
            key: "contact",
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <Space>
                    <Button type="link" danger onClick={() => handleDelete(record._id)}>
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <Card title="Supplier Management" bordered={true} style={{ width: "100%", margin: "20px auto" }}>
            <Button type="primary" onClick={() => setIsModalOpen(true)} style={{ marginBottom: "10px" }}>
                Add Supplier
            </Button>

            {loading && <Spin />}
            {error && <Alert type="error" message={error} />}

            <Table
                columns={columns}
                dataSource={suppliers}
                rowKey="_id"
                bordered
                pagination={{ pageSize: 5 }}
            />

            {/* Modal for Adding Supplier */}
            <Modal
                title="Add Supplier"
                open={isModalOpen}
                onOk={handleAddSupplier}
                onCancel={() => setIsModalOpen(false)}
                okText="Add"
                cancelText="Cancel"
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        label="Supplier Name"
                        name="name"
                        rules={[{ required: true, message: "Please enter supplier name!" }]}
                    >
                        <Input placeholder="Enter supplier name" />
                    </Form.Item>

                    <Form.Item label="Contact" name="contact">
                        <Input placeholder="Enter contact number" />
                    </Form.Item>

                    <Form.Item label="Address" name="address">
                        <Input placeholder="Enter address" />
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    );
};

export default SupplierList;
