import api from '../api';

const API_URL = '/api/witness-nominee';

export const fetchWitNomineeApi = async (id) => {
    try {
        const response = await api.get(API_URL + "?id=" + id);
        return response.data.data;
    } catch (error) {
        throw error.response?.data || 'Error fetching data';
    }
};
export const addWitNomineeApi = async (data) => {
    try {
        const response = await api.post(API_URL, data);
        return response.data;
    } catch (error) {
        throw error.response?.data || 'Error add data';
    }
};
export const updateWitnessApi = async (data) => {
    try {
        const response = await api.put(API_URL, data);
        return response.data;
    } catch (error) {
        throw error.response?.data || 'Error update data';
    }
};

