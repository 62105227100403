import React, { useEffect, useRef, useState } from 'react';
import { Table, Button, Input, Card, Flex, Modal, Form, Space, Select, Spin, Tooltip, Upload, notification, Progress, List, Tag, Typography, Tabs } from 'antd';
import { FileExcelOutlined, FilePdfOutlined, SearchOutlined, PrinterFilled, ImportOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { exportplot, fetchplot, handlePrint, selectLoading, } from '../app/Service/plotSlice';
import { useDispatch, useSelector } from 'react-redux'
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { useForm } from 'antd/es/form/Form';
import { uploadSingleFile } from '../app/Service/fileSlice';
import { fetchAssignplot, fetchScrollAssignplot, selectassignplot } from '../app/Service/AssignSlice';
import dayjs from 'dayjs';
import ApplicationForm from '../Reports/ApplicationForm';
import TransferModal from './TransferModal';
import ModalCharges from './ModalCharges';
const { Title } = Typography
const AssignFileList = () => {
    const navigate = useNavigate()
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(50)
    const [Company, setCompany] = useState()
    const company_name = useSelector(selectcompanies_name)
    const [valuestext, setvaluestext] = useState('')
    const Plots = useSelector(selectassignplot)
    const dispatch = useDispatch()
    const loading = useSelector(selectLoading)
    const [form] = useForm()
    const [modalVisible, setModalVisible] = useState(false);
    const [progress, setProgress] = useState(0);
    const [messages, setMessages] = useState([]);
    const [miss, setmiss] = useState([]);
    const [hit, sethit] = useState([]);
    const [data, setData] = useState([])
    const [add, setadd] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [summary, setSummary] = useState({ added: 0, updated: 0, missed: 0 });
    useEffect(() => {
        dispatch(getCompaniesByName({}));
    }, []);

    const onSelectChange = (selectedKeys) => {
        console.log('Plots?.data[0]', Plots?.data[0])
        console.log('selectedKeys', selectedKeys)
        const selectedData = Plots?.data?.filter(plot => selectedKeys.includes(plot?.File?.file_id?._id));
        setData(selectedData);
        setSelectedRowKeys(selectedKeys);
    };


    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        if (company_name?.length > 0) {
            form.setFieldsValue({
                company: company_name[0]?._id
            })
            setCompany(company_name[0]?._id)
            dispatch(fetchAssignplot({ id: company_name[0]?._id, query: "", limit, start }));
        }
    }, [company_name])


    useEffect(() => {
        if (Company?.length > 0) {
        }
    }, [Company])


    const handleFiinish = async (values) => {
        setLimit(100)
        setStart(0)
        dispatch(fetchAssignplot({ id: values.company, query: JSON.stringify(values), limit: 100, start: 0 }));
    }

    const handelUploadFile = async ({ file }) => {
        try {
            if (!Company) {
                notification.error({
                    message: "Please Select Company",
                    description: "You must select a company before uploading.",
                    placement: "topRight",
                });
                return;
            }
            let values = form.getFieldsValue();
            setModalVisible(true);
            setMessages([]);
            setmiss([]);
            sethit([]);
            setadd([]);
            setProgress(0);
            setSummary({ added: 0, updated: 0, missed: 0 });


            // 🔹 Upload the file first
            const res = await dispatch(uploadSingleFile(file));
            if (res.meta.requestStatus !== "fulfilled") {
                throw new Error("File upload failed.");
            }

            const fileId = res?.payload.file._id;
            const url = `${process.env.REACT_APP_SEVER}/api/plot/assign/data?fileId=${fileId}&id=${Company}&pid=${values?.Phase}&did=${values?.Block}`;

            const response = await fetch(url, {
                method: "GET",
                credentials: "include",
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            // ✅ Process streaming data
            const processStream = async () => {
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    const chunk = decoder.decode(value, { stream: true });
                    chunk.split("\n").forEach((line) => {
                        if (!line) return;
                        try {
                            const data = JSON.parse(line);
                            console.log('data===>', data)
                            if (data.message) {
                                setMessages((prev) => [...prev, data.message]);
                            }
                            if (data.progress) {
                                setProgress(data.progress);
                            }
                            if (data.progress) {
                                setProgress(data.progress);
                            }

                            if (data.record) {
                                // if (data.status === 1) {
                                //     setmiss((prev) => [...prev, data.record]);

                                // }
                                if (data.status === 2) {
                                    setadd((prev) => [...prev, data.record]);

                                }
                                // if (data.status === 3) {
                                //     sethit((prev) => [...prev, data.record]);

                                // }
                            }
                            if (data.error) {
                                console.error("Error:", data.error);
                                notification.error({
                                    message: "Import Error",
                                    description: data.error,
                                    placement: "topRight",
                                });
                                reader.cancel(); // Stop reading on error
                            }

                            // ✅ Track Added, Updated, and Missed Records
                            if (data.addedRecords !== undefined) {
                                setSummary({
                                    added: data.addedRecords || 0,
                                    updated: data.updatedRecords || 0,
                                    missed: data.missedRecords || 0,
                                });

                                if (data.record && data.action) {
                                    setMessages((prev) => [
                                        ...prev,
                                        `${data.action === "Added" ? "✅" : data.action !== "Updated" ? "❌" : "✏️"} Record ${data.record}: ${data.action}`,
                                    ]);
                                }
                            }

                            // ✅ Close modal after processing completes
                            if (data.final) {
                                setTimeout(() => setModalVisible(false), 2000);
                            }
                        } catch (parseError) {
                            console.error("JSON Parse Error:", parseError);
                        }
                    });
                }
            };

            await processStream();
        } catch (error) {
            console.error("Error handling file upload:", error);
            notification.error({
                message: "File Upload Failed",
                description: "There was an error uploading the file. Please try again.",
                placement: "topRight",
            });
        }
    };

    const columns = [
        {
            title: "#",
            key: "index",
            width: 50,
            render: (text, record, index) => index + 1 // Adding row count
        },
        { title: "File Number", dataIndex: ["File", "file_id", "File_ID"], key: "File_ID", width: 100 },
        {
            title: " Membership",
            dataIndex: "owners",
            key: "MemberShip",
            width: 100,
            render: (Owners) => Owners?.length > 0 ? Owners.map(owner => (
                <div key={owner?._id}>{owner?.name?.membership_id || "N/A"}</div>
            )) : "N/A",
        },
        {
            title: "Owner Name",
            dataIndex: "owners",
            key: "owner_name",
            width: 180,
            render: (Owners) => Owners?.length > 0 ? Owners.map(owner => (
                <div key={owner?._id}>{owner?.name?.owner_name || "N/A"}</div>
            )) : "N/A",
        },
        {
            title: "CNIC",
            dataIndex: "owners",
            key: "owner_name",
            width: 100,
            render: (Owners) => Owners?.length > 0 ? Owners.map(owner => (
                <div key={owner?._id}>{owner?.name?.cnic || "N/A"}</div>
            )) : "N/A",
        },


        {
            title: "Booking Date",
            dataIndex: "updatedAt",
            key: "MemberShip",
            width: 100,
            render: (date) => dayjs(date).format("MMM,DD YYYY")
        },

        {
            title: "File Status",
            dataIndex: "status",
            key: "status",
            width: 100,
            render: (status) => <Tag color={status === "Pending" ? "#004ebf" : "#00bf7a"}>{status}</Tag>,
        },
       
       
    ];
    const handleScroll_ = async (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (scrollTop + clientHeight >= scrollHeight - 1 && Plots?.totalCount > Plots?.data?.length) {
            let values = form.getFieldsValue();
            dispatch(fetchScrollAssignplot({ id: values.company, query: JSON.stringify(values), limit, start: start + limit }))
            setStart(start + limit);
        }
    };

    const columns_ = [
        { title: "SNO", dataIndex: "SNO", key: "SNO" },
        { title: "Name", dataIndex: "NAME", key: "NAME" },
        { title: "CNIC", dataIndex: "CNIC", key: "CNIC" },
        { title: "MSN", dataIndex: "MSN", key: "MSN" },
        { title: "Plot", dataIndex: "Plot", key: "Plot" },
        { title: "Phase", dataIndex: "Phase", key: "Phase" },
        { title: "Street", dataIndex: "Street", key: "Street" },
        { title: "Block", dataIndex: "Block", key: "Block" },
    ];
    return (
        <Card title={<Title level={4} style={{ margin: 0 }}>
            📂 Assign File
        </Title>} extra={


            <Space>
                <ModalCharges selectedRowKeys={selectedRowKeys} defaultCompanyId={Company} />
                <TransferModal Company={Company} />
                {/* <ApplicationForm data={data} /> */}
            </Space>

        }  >
            <div>
                <Modal
                    width={750}
                    title="File Import Progress"
                    open={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    footer={null}
                >
                    <Progress percent={progress} status="active" />

                    <Tabs items={[{
                        key: "1",
                        label: "Uploading Streem",
                        children: <>
                            <List
                                size="small"
                                bordered
                                dataSource={messages}
                                renderItem={(msg) => <List.Item>{msg}</List.Item>}
                                style={{ marginTop: 10, maxHeight: 200, overflowY: "auto" }}
                            />
                        </>
                    },
                    {
                        key: "3",
                        label: "Added Records",
                        children: <><Table style={{ marginTop: 10, maxHeight: 200, overflowY: "auto" }} dataSource={add} columns={columns_} bordered pagination={false} /></>
                    },
                    {
                        key: "4",
                        label: "Updated Records",
                        children: <><Table style={{ marginTop: 10, maxHeight: 200, overflowY: "auto" }} dataSource={hit} columns={columns_} bordered pagination={false} /></>
                    },
                    {
                        key: "2",
                        label: "Skipped Records",
                        children: <><Table style={{ marginTop: 10, maxHeight: 200, overflowY: "auto" }} dataSource={miss} columns={columns_} bordered pagination={false} /></>
                    }

                    ]}
                    />
                    <div style={{ marginTop: 10 }}>
                        <strong>📌 Summary:</strong>
                        <p>✅ Added: {summary.added}</p>
                        <p>✏️ Updated: {summary.updated}</p>
                        <p>❌ Skipped: {summary.missed}</p>
                    </div>
                </Modal>
                <Flex justify={"space-between"} align={"center"}>

                    <Form form={form} onFinish={handleFiinish}>
                        <Space>
                            <Form.Item noStyle name={"company"}>
                                <Select
                                    placeholder="Select Company"
                                    value={Company}
                                    options={company_name?.map(company => ({
                                        label: company?.companyName,
                                        value: company?._id,
                                    }))}
                                    style={{ width: 200, marginBottom: 16 }}
                                    onChange={(e) => setCompany(e)}
                                />
                            </Form.Item>
                            <>
                                <Form.Item noStyle name={"search"} >
                                    <Select placeholder="Select By"
                                        allowClear
                                        style={{ width: 200, marginBottom: 16 }}
                                        options={[
                                            {
                                                label: "File Number",
                                                value: "file",
                                                key: "file"
                                            },
                                            {
                                                label: "Membership",
                                                value: "membership",
                                                key: "membership"
                                            },
                                            {
                                                label: "Name",
                                                value: "name",
                                                key: "name"
                                            },
                                            {
                                                label: "Cnic",
                                                value: "cnic",
                                                key: "cnic"
                                            },
                                            {
                                                label: "Status",
                                                value: "status",
                                                key: "status"
                                            },
                                        ]}
                                    />
                                </Form.Item>

                                <Form.Item noStyle name={"text"}>
                                    <Input
                                        placeholder="Search File"
                                        value={valuestext}
                                        style={{ width: 200, marginBottom: 16 }}
                                    />
                                </Form.Item>
                            </>
                            <Button htmlType='submit' style={{ marginBottom: 16 }} icon={<SearchOutlined />} type="primary"> </Button>
                        </Space>
                    </Form>

                    <div style={{ marginBottom: 16 }}>
                        <span color='success' style={{ marginRight: "10px" }}>
                            Show  {Plots?.data?.length} of {Plots?.totalCount}
                        </span>


                        <Tooltip title="Import">
                            <Upload
                                accept=".xls,.xlsx"
                                showUploadList={false}
                                beforeUpload={() => false}
                                onChange={handelUploadFile} >

                                <Button variant={'outlined'} color={'danger'} icon={<ImportOutlined />} style={{ marginRight: 8 }}></Button>
                            </Upload>
                        </Tooltip>
                        <ApplicationForm data={data} index={1} />
                        {/* <Tooltip title="Export in to Excel"> <Button variant={'outlined'} onClick={() => dispatch(fetchExportassignplot({ type: "excel", id: Company, start, limit }))} color={"primary"} icon={<FileExcelOutlined />} style={{ marginRight: 8 }} /></Tooltip>
                        <Tooltip title="Export in to Pdf">
                            <Button variant={'outlined'} onClick={() => dispatch(fetchExportassignplot({ type: "pdf", id: Company, start, limit }))} icon={<FilePdfOutlined />} style={{ marginRight: 8, borderColor: "green", color: "green" }} /> </Tooltip>
                        <Tooltip title="Print"><Button disabled variant={'outlined'} onClick={() => dispatch(handlePrint(Company))} style={{ borderColor: "orange", color: "orange" }} icon={<PrinterFilled />} /> </Tooltip> */}
                    </div>
                </Flex>
                <Table
                    scroll={{ y: `calc(100vh - 180px)` }}
                    columns={columns}
                    dataSource={Plots?.data?.map((item, i) => ({ key: item?.File?.file_id?._id, ...item }))}
                    onScroll={handleScroll_}
                    pagination={false}
                    rowSelection={rowSelection}
                    size={"small"}
                    locale={loading}
                >
                </Table>
            </div>
        </Card >
    );
};

export default AssignFileList;
