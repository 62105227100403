import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Modal, Form, Input, Select, Spin, Alert, Space, Card } from "antd";
import { fetchProducts, createProduct, deleteProduct, selectProducts, selectProductLoading, selectProductError } from "../app/Service/productSlice";
import { fetchSuppliers, selectSuppliers, selectSupplierLoading, selectSupplierError } from "../app/Service/supplierSlice";

const { Option } = Select;

const ProductList = () => {
    const dispatch = useDispatch();
    const products = useSelector(selectProducts);
    const loading = useSelector(selectProductLoading);
    const error = useSelector(selectProductError);
    const suppliers = useSelector(selectSuppliers);
    const supplierLoading = useSelector(selectSupplierLoading);
    const supplierError = useSelector(selectSupplierError);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(fetchProducts());
        dispatch(fetchSuppliers()); // ✅ Fetch suppliers when component loads
    }, [dispatch]);

    const handleAddProduct = () => {
        form.validateFields().then((values) => {
            dispatch(createProduct(values));
            setIsModalOpen(false);
            form.resetFields();
        });
    };

    const handleDelete = (id) => {
        dispatch(deleteProduct(id));
    };

    const columns = [
        { title: "Product Name", dataIndex: "name", key: "name" },
        { title: "Unit", dataIndex: "sku", key: "sku" },
        // { title: "Supplier", dataIndex: ["supplier", "name"], key: "supplier" },
        { title: "Purchase Price", dataIndex: "purchasePrice", key: "purchasePrice" },
        { title: "Selling Price", dataIndex: "sellingPrice", key: "sellingPrice" },
        // { title: "Stock", dataIndex: "stock", key: "stock" },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <Space>
                    <Button type="link" danger onClick={() => handleDelete(record._id)}>
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <Card title="Products List">
            <Button type="primary" onClick={() => setIsModalOpen(true)} style={{ marginBottom: "10px" }}>
                Add Product
            </Button>

            {loading && <Spin />}
            {error && <Alert type="error" message={error} />}
            {supplierLoading && <Spin />}
            {supplierError && <Alert type="error" message={supplierError} />}

            <Table columns={columns} dataSource={products} rowKey="_id" bordered pagination={{ pageSize: 5 }} />

            {/* Modal for Adding Product */}
            <Modal title="Add Product" open={isModalOpen} onOk={handleAddProduct} onCancel={() => setIsModalOpen(false)} okText="Add" cancelText="Cancel">
                <Form form={form} layout="vertical">
                    <Form.Item label="Product Name" name="name" rules={[{ required: true, message: "Enter product name!" }]}>
                        <Input placeholder="Enter product name" />
                    </Form.Item>
                    <Form.Item label="Unit" name="sku" rules={[{ required: true, message: "Enter Unit!" }]}>
                        <Input placeholder="Enter unit" />
                    </Form.Item>
                    {/* <Form.Item label="Supplier" name="supplier" rules={[{ required: true, message: "Select a supplier!" }]}>
                        <Select placeholder="Select supplier">
                            {suppliers.map((supplier) => (
                                <Option key={supplier._id} value={supplier._id}>
                                    {supplier.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item> */}
                    <Form.Item label="Purchase Price" name="purchasePrice" rules={[{ required: true, message: "Enter purchase price!" }]}>
                        <Input type="number" placeholder="Enter price" />
                    </Form.Item>
                    <Form.Item label="Selling Price" name="sellingPrice" rules={[{ required: true, message: "Enter selling price!" }]}>
                        <Input type="number" placeholder="Enter price" />
                    </Form.Item>
                    {/* <Form.Item label="Stock" name="stock">
                        <Input type="number" placeholder="Enter stock quantity" />
                    </Form.Item> */}
                </Form>
            </Modal>
        </Card>
    );
};

export default ProductList;
