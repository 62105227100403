import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getmember, selectmember } from "../app/Service/memberslice";
import { Descriptions, Table, Card, Avatar, Row, Col, Typography, Input, Button, Spin, Flex } from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import api from "../app/api";

const { Title, Text } = Typography;

const MemberDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const member = useSelector(selectmember);
    const [searchText, setSearchText] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (id) {
            dispatch(getmember(id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (member?.properties_owned?.length) {
            setFilteredData(member.properties_owned);
        }
    }, [member]);

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        if (!member?.properties_owned) return;

        const filtered = member.properties_owned.filter((item) =>
            Object.values(item || {}).some((val) =>
                val?.toString().toLowerCase().includes(value)
            )
        );
        setFilteredData(filtered);
    };

    const exportToExcel = () => {
        if (!filteredData.length) return;


        console.log('filteredData',)
        const ws = XLSX.utils.json_to_sheet(filteredData?.map((item, i) => ({
            key: i + 1,
            "File ID": item?.File_ID,
            Company: item?.Company?.companyName,
            Phase: item?.Phase?.Phase,
            Block: item?.Block?.Block,
            Plot: item?.Plot_No,
            "Land Size": item?.Land_Size,
            "File Status": item?.file_status


        })));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Properties");
        XLSX.writeFile(wb, "Member_Properties.xlsx");
    };

    const columns = [
        { title: "#", dataIndex: "index", key: "index", render: (_, __, index) => index + 1 },
        { title: "Company", dataIndex: ["Company", "companyName"], key: "company", render: (text) => text || "N/A" },
        { title: "Phase", dataIndex: ["Phase", "Phase"], key: "phase", render: (text) => text || "N/A" },
        { title: "Block", dataIndex: ["Block", "Block"], key: "block", render: (text) => text || "N/A" },
        { title: "Plot No", dataIndex: "Plot_No", key: "plot_no", render: (text) => text || "N/A" },
        { title: "Land Size", dataIndex: "Land_Size", key: "land_size", render: (text) => text || "N/A" },
        { title: "File Status", dataIndex: "file_status", key: "file_status", render: (text) => text || "N/A" },
        { title: "Street", dataIndex: "Street", key: "street", render: (text) => text || "N/A" },
        { title: "File ID", dataIndex: "File_ID", key: "file_id", render: (text) => text || "N/A" },
        { title: "Created At", dataIndex: "createdAt", key: "created_at", render: (date) => (date ? new Date(date).toLocaleDateString() : "N/A") },
    ];
    const getInitials = (name) => {
        if (!name) return "NA";
        const parts = name.split(" ");
        return parts.length > 1 ? parts[0][0] + parts[1][0] : parts[0][0];
    };
    return (

        !member ? <Flex justify="center" align="center" style={{ height: "100vh" }}>
            <Spin></Spin>
        </Flex> :
            <Card style={{ padding: "10px" }}>
                <Row gutter={[8, 8]}>
                    <Col xs={24} md={9}>
                        <Card style={{ textAlign: "center", padding: "5px" }} actions={[
                            <>ID :  {member?.membership_id} </>, <>Phone : {member?.contact_number}</>
                        ]}>
                            {member?.image ? (
                                <Avatar src={api.getUri() + "/api/" + member.image} size={120} />
                            ) : (
                                <Avatar size={120} style={{ backgroundColor: "#87d068", fontSize: "32px" }}>
                                    {getInitials(member.owner_name)}
                                </Avatar>
                            )}
                            <Title level={4} style={{ marginTop: "10px" }}>{member.owner_name + " " + member.fatherName}</Title>
                            <Text type="secondary">{member.email || "N/A"}</Text>
                            <br />
                            <Text type="secondary">{member.address || "N/A"}</Text>

                        </Card>
                    </Col>
                    <Col xs={24} md={15}>
                        <Card style={{ padding: "3px" }}>
                            <Descriptions bordered={false} column={2}>
                                <Descriptions.Item label="Member ID">{member.membership_id || "N/A"}</Descriptions.Item>
                                <Descriptions.Item label="Date of Birth">{member.dateOfBirth ? new Date(member.dateOfBirth).toLocaleDateString() : "N/A"}</Descriptions.Item>
                                <Descriptions.Item label="CNIC">{member.cnic || "N/A"}</Descriptions.Item>
                                <Descriptions.Item label="CNIC Expiry">{member.cnicExpire ? new Date(member.cnicExpire).toLocaleDateString() : "N/A"}</Descriptions.Item>
                                <Descriptions.Item label="Company">{member.company_id?.companyName || "N/A"}</Descriptions.Item>
                                <Descriptions.Item label="City">{member.city || "N/A"}</Descriptions.Item>
                                <Descriptions.Item label="Country">{member.nationality || "N/A"}</Descriptions.Item>
                                <Descriptions.Item label="NTN">{member.ntn || "N/A"}</Descriptions.Item>
                                <Descriptions.Item label="Passport #">{member.passport_number || "N/A"}</Descriptions.Item>
                                <Descriptions.Item label="Phone">{member.contact_number || "N/A"}</Descriptions.Item>
                                <Descriptions.Item label="Email">{member.email || "N/A"}</Descriptions.Item>
                                <Descriptions.Item label="Permanent Address">{member.permanent_address || "N/A"}</Descriptions.Item>
                                <Descriptions.Item label="Properties Owned">{member.properties_owned?.length}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                </Row>

                <Card title="Properties Owned" bordered={false} style={{ marginTop: "10px" }}>
                    <Row gutter={16} style={{ marginBottom: "10px" }}>
                        <Col span={12}>
                            <Input placeholder="Search properties..." value={searchText} onChange={handleSearch} prefix={<SearchOutlined />} />
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={exportToExcel} disabled={!filteredData.length}>
                                Download Excel
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        dataSource={filteredData.map((item, index) => ({ ...item, index: index + 1 }))}
                        columns={columns}
                        pagination={{ pageSize: 5 }}
                        bordered
                    />
                </Card>
            </Card>
    );
};

export default MemberDetails;
