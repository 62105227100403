import { Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import CreateFile from './CreateFile';
import AssignFile from './AssignFile';

import Phase from './Phase';
import Block from './Block';
import Category from './Category';
import Status from './Status';
import Residential from './Residential';
import Prpperty from './Prpperty';
import WitnessNominee from './WitnessNominee';
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWitNominee } from '../app/Service/witNomineeSlice';
import ChargesName from './ChargesName';
function File() {
    const [key, setKey] = useState("3")
    const dispatch = useDispatch()
    const company_name = useSelector(selectcompanies_name);

    useEffect(() => {
        dispatch(getCompaniesByName({}));
    }, [dispatch]);
    return (
        <>
            <div style={{ background: "" }}>
                <Tabs
                    destroyInactiveTabPane={true}
                    onChange={(e) => {
                        if (e === "1") {
                            console.log('e', e)

                            dispatch(fetchWitNominee(company_name[0]?._id))
                        }
                        setKey(e)
                    }
                    }
                    type="card"
                    items={[
                        { label: "Phase", key: "3", children: <Phase key={key} /> },
                        { label: "Block", key: "4", children: <Block key={key} /> },
                        { label: "Category", key: "5", children: <Category key={key} /> },
                        { label: "Status", key: "6", children: <Status key={key} /> },
                        { label: "Residential Type", key: "7", children: <Residential key={key} /> },
                        { label: "Property Type", key: "8", children: <Prpperty key={key} /> },
                        { label: "Witness & Nominee & Agent", key: "1", children: <WitnessNominee key={key} /> },
                        { label: "Charges", key: "9", children: <ChargesName key={key} /> },

                    ]}
                />

            </div>
        </>
    )
}

export default File