import { useState, useEffect } from "react";
import { Card, Button, Table, Input, Typography, Layout, List, Select, DatePicker, Flex } from "antd";
import { fetchProducts, selectProducts } from "../app/Service/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { createSalesOrder } from "../app/Service/salesOrderSlice";

const { Title } = Typography;
const { Sider, Content } = Layout;
const { Option } = Select;

const SaleOrder = () => {

  const dispatch = useDispatch();
  const products = useSelector(selectProducts);

  const [salesOrders, setSalesOrders] = useState([]);
  const [newOrder, setNewOrder] = useState({ customer: "", date: null, items: [] });

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [tableSearch, setTableSearch] = useState("");

  useEffect(() => {
    dispatch(fetchProducts());

  }, []);
  useEffect(() => {
    setFilteredProducts(
      products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [products]);

  const addProductToOrder = (product) => {
    setNewOrder({ ...newOrder, items: [{ ...product, quantity: 1, tax: 10 }, ...newOrder.items] });
  };

  const updateQuantity = (index, quantity) => {
    setNewOrder((prevOrder) => ({
      ...prevOrder,
      items: prevOrder.items.map((item, i) =>
        i === index ? { ...item, quantity: Number(quantity) } : item
      ),
    }));
  };

  const updateTax = (index, tax) => {
    setNewOrder((prevOrder) => ({
      ...prevOrder,
      items: prevOrder.items.map((item, i) =>
        i === index ? { ...item, tax: Number(tax) } : item
      ),
    }));
  };

  const deleteRow = (index) => {
    setNewOrder((prevOrder) => ({
      ...prevOrder,
      items: prevOrder.items.filter((_, i) => i !== index),
    }));
  };


  const calculateTotals = () => {
    const subTotal = newOrder.items.reduce((acc, item) => acc + (item.sellingPrice * (item.quantity || 1)), 0);
    const tax = newOrder.items.reduce((acc, item) => acc + (item.sellingPrice * (item.quantity || 1) * (item.tax / 100)), 0);
    const grandTotal = subTotal + tax;
    return { subTotal, tax, grandTotal };
  };


  const { subTotal, tax, grandTotal } = calculateTotals();

  const filteredTableData = newOrder.items.filter((item) =>
    item.name.toLowerCase().includes(tableSearch.toLowerCase())
  );

  const columns = [
    { title: "Product", dataIndex: "name", key: "name" },
    { title: "Price (₨)", dataIndex: "sellingPrice", key: "price" },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, record, index) => (
        <Input type="number" min={1} defaultValue={1} onChange={(e) => updateQuantity(index, e.target.value)} />
      )
    },
    {
      title: "Tax (%)",
      dataIndex: "tax",
      key: "tax",
      render: (_, record, index) => (
        <Select defaultValue={10} onChange={(value) => updateTax(index, value)}>
          <Option value={10}>10%</Option>
          <Option value={13}>13%</Option>
          <Option value={18}>18%</Option>
          <Option value={20}>20%</Option>
        </Select>
      )
    },
    {
      title: "Action",
      key: "action",
      render: (_, __, index) => (
        <Button type="link" onClick={() => deleteRow(index)}><CloseOutlined /></Button>
      )
    }
  ];

  const handleSaveOrder = () => {
    if (!newOrder.date || newOrder.items.length === 0) {
      alert("Please select a customer, date, and at least one product.");
      return;
    }

    const orderData = {
      customer: newOrder.customer,
      date: newOrder.date.format("YYYY-MM-DD"), // Format the date
      items: newOrder.items.map(({ _id, quantity, tax, sellingPrice }) => ({
        product: _id,
        quantity,
        tax, sellingPrice
      })),
      subTotal,
      tax,
      grandTotal
    };

    dispatch(createSalesOrder(orderData))
      .unwrap()
      .then(() => {
        alert("Sales Order Created Successfully!");
        setNewOrder({ customer: "", date: null, items: [] }); // Reset form
      })
      .catch((error) => {
        alert(`Error: ${error}`);
      });
  };

  return (
    <Layout>
      <Sider width={300} style={{ background: "#fff", padding: "16px" }}>
        <Title level={4}>Search Product</Title>
        <Input placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        <List
          dataSource={filteredProducts}
          renderItem={(item) => (
            <List.Item>
              <Button type="link" onClick={() => addProductToOrder(item)}>
                {item.name}
              </Button>
            </List.Item>
          )}
        />
      </Sider>
      <Layout>
        <Content style={{ height: "100vh", backgroundColor: "white" }}>
          <Card title="Billing  Screen">
            <div style={{ display: "flex", gap: "10px", marginBottom: "16px" }}>
              <Select placeholder="Select Customer" style={{ width: 200 }} onChange={(value) => setNewOrder({ ...newOrder, customer: value })}>
                <Option value="Customer A">Customer A</Option>
                <Option value="Customer B">Customer B</Option>
              </Select>
              <DatePicker onChange={(date) => setNewOrder({ ...newOrder, date })} />
              <Input placeholder="Search Table" value={tableSearch} onChange={(e) => setTableSearch(e.target.value)} />
            </div>
            <Table dataSource={filteredTableData} columns={columns} rowKey="id" pagination={false} style={{ height: "70vh", overflowY: "auto" }} />
            <Flex justify="space-between" align="end">
              <div style={{ marginTop: "16px", textAlign: "left" }}>
                <p>Subtotal: ₨{subTotal.toFixed(2)}</p>
                <p>Tax: ₨{tax.toFixed(2)}</p>
                <p><strong>Grand Total: ₨{grandTotal.toFixed(2)}</strong></p>
              </div>
              <Button type="primary" onClick={() => handleSaveOrder()}>
                Save Bill
              </Button>

            </Flex>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SaleOrder;