import { CameraOutlined, CloseOutlined, Loading3QuartersOutlined, LoadingOutlined, RedoOutlined, UploadOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Form, Input, Row, Select, Space, Typography, message, Modal, Descriptions, Flex, List, Divider, Upload, Result } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { fetchWitNominee, selectWitNominees } from '../app/Service/witNomineeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchplot, selectplot } from '../app/Service/plotSlice';
import { selectcompanies_name } from '../app/Service/companySlice';
import { fetchmember, selectmembers } from '../app/Service/memberslice';
import debounce from 'lodash/debounce';
import { addTransferdplot, fetchAssignplot } from '../app/Service/AssignSlice';
import Swal from "sweetalert2";
import Webcam from "react-webcam";
import { uploadSingleFile } from '../app/Service/fileSlice';
import api from '../app/api';

const { Text } = Typography;

export default function TransferModal({ data: recordData, setStart, setLimit, Company }) {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const witnominee = useSelector(selectWitNominees);
    const company_name = useSelector(selectcompanies_name);
    const Plots = useSelector(selectplot);
    const Member = useSelector(selectmembers);
    const [upload, setUpload1] = useState(false)
    const [upload2, setUpload2] = useState(false)
    const [type, setType] = useState("Normal")
    const [data, setData] = useState()


    const webcamRefSeller = useRef(null);
    const webcamRefPurchaser = useRef(null);

    const [sellerImage, setSellerImage] = useState(null);
    const [purchaserImage, setPurchaserImage] = useState(null);
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);

    const captureImageSeller = () => {
        const imageSrc = webcamRefSeller.current.getScreenshot();
        setSellerImage(imageSrc);

        const data = imageSrc.split(',')[1]; // Get the base64 part
        const mimeType = imageSrc.split(';')[0].split(':')[1]; // Get the MIME type (image/jpeg or image/png)
        const byteCharacters = atob(data); // Decode base64 data
        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }

        const file = new File([byteArray], 'seller_image.jpg', { type: mimeType });
        setFile1(file);
    };
    const captureImagePurchaser = () => {
        const imageSrc = webcamRefPurchaser.current.getScreenshot();
        setPurchaserImage(imageSrc);

        const data = imageSrc.split(',')[1]; // Get the base64 part
        const mimeType = imageSrc.split(';')[0].split(':')[1]; // Get the MIME type (image/jpeg or image/png)
        const byteCharacters = atob(data); // Decode base64 data
        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }
        const file = new File([byteArray], 'seller_image.jpg', { type: mimeType });
        setFile2(file);
    };


    const handleUpload = (info, setImage) => {
        const file = info.fileList[0].originFileObj;
        if (file) {
            setImage(file);
        }
    };

    const dispatch = useDispatch();
    const handleFinish = async (values) => {
        console.log('values', values);
        if (values.formType === "Open") {
            if (!file1) {
                Swal.fire({
                    icon: 'error',
                    title: 'Missing Files',
                    text: 'Please upload Seller image files.',
                    confirmButtonText: 'Okay',
                });
                return;
            }
        } else {
            if (!file1 || !file2) {
                Swal.fire({
                    icon: 'error',
                    title: 'Missing Files',
                    text: 'Please upload both Seller and Purchaser image files.',
                    confirmButtonText: 'Okay',
                });
                return;
            }
        }

        if (values.formType !== "Open") {
            if (!values.purchasers || values.purchasers.length === 0) {
                Swal.fire({ icon: "error", title: "Error", text: "At least one purchaser is required." });
                return;
            }

            const ownerNames = values.purchasers.map(owner => owner.name);
            const uniqueOwnerNames = new Set(ownerNames);

            if (ownerNames.length !== uniqueOwnerNames.size) {
                Swal.fire({ icon: "error", title: "Duplicate Purchasers", text: "Purchaser names must be unique." });
                return;
            }

            const totalShare = values.purchasers.reduce((sum, owner) => sum + (parseInt(owner.share) || 0), 0);
            if (totalShare !== 100) {
                Swal.fire({ icon: "error", title: "Invalid Share", text: "Total share must be exactly 100%." });
                return;
            }
        }

        if (values.witnesses && values.witnesses.length > 0) {
            const witnessNames = values.witnesses.map(witness => witness.name);
            const uniqueWitnessNames = new Set(witnessNames);

            if (witnessNames.length !== uniqueWitnessNames.size) {
                Swal.fire({ icon: "error", title: "Duplicate Witnesses", text: "Witness names must be unique." });
                return;
            }
        }

        try {
            // ✅ Upload Seller Image
            const res1 = await dispatch(uploadSingleFile(file1));
            if (res1.meta.requestStatus !== "fulfilled") {
                throw new Error("Seller image upload failed.");
            }

            let sellerImg = res1?.payload?.file?._id;
            let purchaserImg = null;

            // ✅ Upload Purchaser Image (ONLY if NOT "Open" form)
            if (values.formType !== "Open" && file2) {
                const res2 = await dispatch(uploadSingleFile(file2));
                if (res2.meta.requestStatus !== "fulfilled") {
                    throw new Error("Purchaser image upload failed.");
                }
                purchaserImg = res2?.payload?.file?._id;
            }

            // ✅ Prepare Data for Submission
            values.purchaserImg = purchaserImg;
            values.sellerImg = sellerImg;
            values.File = {
                file_id: data?.File?.file_id?._id,
                F_ID: data?.File?.F_ID,
                F_OID: data?.File?.F_OID,
            };
            values.sellers = data?.owners;

            // ✅ Submit Data
            const res = await dispatch(addTransferdplot({ data: values, id: data?.company_id, type: "Normal" }));
            if (res?.meta?.requestStatus === "fulfilled") {
                dispatch(fetchAssignplot({ id: Company, query: "", limit: 100, start: 0 }));
                setLimit(100)
                setStart(0)
                form.resetFields();
                Swal.fire({ icon: "success", title: "Success", text: "Plot Transferd Request Successfully successfully!" })
                    .then(() => setIsModalVisible(false));
            } else {
                throw new Error(res?.payload?.message || "Something went wrong!");
            }
        } catch (error) {
            Swal.fire({ icon: "error", title: "Error", text: error.message || "Failed to assign plot." });
        }
    };


    const handleMemberSearch = debounce((value) => {
        if (data?.Company?._id) {
            dispatch(fetchmember({ id: data?.Company?._id, query: value }));
        }
    }, 500);
    // 
    const handleChange = async (e) => {
        setType('Normal')
        form.resetFields()
        const data_ = await api.get('/api/plot/single/file?id=' + Company + "&text=" + e.target.value)
        if (data_?.data) {
            setData(data_.data)
        } else {
            setData()
        }
    }

    useEffect(() => {
        if (data && isModalVisible) {
            form.setFieldsValue({
                formType: data?.formType
            });
        }
    }, [data, isModalVisible]);
    const handleSavePurchaserDetails = async () => {
        try {
            const values = await form.validateFields(); // Ensures required fields are filled

            if (!values.purchasers || values.purchasers.length === 0) {
                Swal.fire({ icon: "error", title: "Error", text: "At least one purchaser is required." });
                return;
            }

            if (!file2) {
                Swal.fire({
                    icon: 'error',
                    title: 'Missing Files',
                    text: 'Please upload Purchaser image file.',
                    confirmButtonText: 'Okay',
                });
                return;
            }

            try {
                let purchaserImg;
                if (file2) {
                    const res2 = await dispatch(uploadSingleFile(file2));
                    if (res2.meta.requestStatus !== "fulfilled") {
                        throw new Error("Purchaser image upload failed.");
                    }
                    purchaserImg = res2?.payload?.file?._id;
                }

                // ✅ Prepare Data for Submission
                values.purchaserImg = purchaserImg;
                values.File = {
                    file_id: data?.File?.file_id?._id,
                    F_ID: data?.File?.F_ID,
                    F_OID: data?.File?.F_OID,
                };
                values.sellers = data?.owners;

                const res = await dispatch(addTransferdplot({ data: values, id: data?.company_id, type: "Open" }));

                if (res?.meta?.requestStatus === "fulfilled") {
                    form.resetFields();
                    dispatch(fetchAssignplot({ id: Company, query: "", limit: 100, start: 0 }));
                    setLimit(100)
                    setStart(0)
                    Swal.fire({ icon: "success", title: "Success", text: "Plot Transfer Request Submitted Successfully!" })
                        .then(() => setIsModalVisible(false));
                } else {
                    throw new Error(res?.payload?.message || "Something went wrong!");
                }
            } catch (error) {
                Swal.fire({ icon: "error", title: "Error", text: error.message || "Failed to assign plot." });
            }

        } catch (error) {
            Swal.fire({ icon: "error", title: "Validation Error", text: "Please fill in all required fields correctly." });
        }
    };



    return (
        <>
            <Button size={"small"} type="primary" onClick={() => {
                setIsModalVisible(true)
                dispatch(fetchWitNominee(Company))
                dispatch(fetchplot({ id: Company, query: "", limit: "", start: "" }));
                dispatch(fetchmember({ id: Company }));

            }}>Transfer</Button>
            <Modal
                title={
                    <>
                        <Flex justify={"space-between"} align={"center"}>
                            <Typography.Title level={4}>Transfer Ownership</Typography.Title>
                            <Input onChange={(e) => handleChange(e)} placeholder='Search File' style={{ width: "250px", marginRight: "30px" }} />
                        </Flex>
                    </>
                }
                open={isModalVisible}
                onCancel={() => {
                    form.resetFields()
                    setSellerImage(null)
                    setPurchaserImage(null)
                    setIsModalVisible(false)
                    setFile1(null)
                    setFile2(null)

                }}
                footer={null}
                width={990}
                destroyOnClose
                maskClosable={false}
                style={{ top: 10 }}
            >
                {data ?

                    (data?.status === "Pending" && data.formType === "Normal") ? <Result
                        icon={<LoadingOutlined />}
                        title="Procession Result"
                        subTitle="Your transfer is under process. Please wait until the administrator accepts or rejects it."
                    />
                        :
                        < Form form={form} layout='vertical' name="assign_file_form" autoComplete="off" initialValues={{ owners: [{}] }} onFinish={handleFinish}>
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <Row gutter={[16, 16]}>

                                        <Col span={24}>
                                            <Descriptions column={3} bordered >
                                                <Descriptions.Item label="File ID">{data.File?.file_id?.File_ID}</Descriptions.Item>
                                                <Descriptions.Item label="File OID">{data.File?.file_id?.File_OID}</Descriptions.Item>
                                                <Descriptions.Item label="Sector">Phase {data.File?.file_id?.Phase.Phase} - Block {data.File?.file_id?.Block.Block}</Descriptions.Item>
                                                <Descriptions.Item label="Plot No">{data.File?.file_id?.Plot_No}</Descriptions.Item>
                                                <Descriptions.Item label="Street">{data.File?.file_id?.Street}</Descriptions.Item>
                                                <Descriptions.Item label="Land Size">{data.File?.file_id?.Land_Size}</Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        {data?.owners?.map((item, i) => {
                                            return <Col span={12} key={i}>
                                                <Card actions={[
                                                    <p>MSN: {item.name.membership_id}</p>,
                                                    <p>Files: {item.name.properties_owned?.length}</p>,
                                                    <p>Share:  {item.share} %</p>
                                                ]}
                                                    style={{
                                                        // minWidth: 300,
                                                        paddingTop: "10px "
                                                    }}>
                                                    <Card.Meta
                                                        avatar={<Avatar size={60} src="n" />}
                                                        title={item.name.owner_name}
                                                        description={
                                                            <>
                                                                <p>{item.name.fatherName}</p>
                                                                <p><strong>CNIC:</strong> <Divider type={"vertical"} /> {item.name.cnic}</p>

                                                                <p><strong>Phone:</strong> <Divider type={"vertical"} />{item.name.contact_number}</p>
                                                                <p><strong>Address:</strong> <Divider type={"vertical"} />{item.name.address}</p>
                                                            </>
                                                        }
                                                    />

                                                </Card>
                                            </Col>
                                        })}



                                    </Row>
                                    <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>

                                        {data?.formType !== "Open" &&
                                            <Col span={24}>

                                                <Form.Item label="Form Type" name={"formType"} initialValue={type}>
                                                    <Select onSelect={(e) => setType(e)} value={type} style={{ width: "100%" }} options={[{
                                                        label: "Normal",
                                                        value: "Normal",
                                                    },
                                                    {
                                                        label: "Open",
                                                        value: "Open",
                                                    }



                                                    ]} >
                                                    </Select>
                                                </Form.Item>


                                            </Col>}
                                        {type === "Open" && <Col span={24}>
                                            <Form.Item label="Agent" name="agent" rules={[{ required: true, message: 'Witness is required' }]} style={{ marginTop: "20px" }}>
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    style={{ width: "100%", height: "55px" }}
                                                    placeholder="Select Agent"
                                                    optionFilterProp="filter"
                                                    filterOption={(input, option) =>
                                                        option.filter.toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={witnominee
                                                        ?.filter((item) => item.role === "Agent")
                                                        ?.map((owner) => ({
                                                            label: <Space>
                                                                <Avatar src={api.getUri() + "/api/" + owner.profile_image} size={40} />

                                                                <div>
                                                                    <Text strong>{owner.name}</Text>
                                                                    <p style={{ marginTop: "-8px", fontSize: "12px" }}>{owner.cnic}</p>
                                                                </div>
                                                            </Space>,
                                                            filter: `${owner.name} - ${owner.cnic}`,

                                                            value: owner._id,
                                                        }))}
                                                />
                                            </Form.Item>
                                        </Col>}
                                        {data?.formType !== "Open" && <Col span={type !== "Open" ? 12 : 24}>
                                            <Card
                                                title="Seller Group"
                                                actions={[
                                                    sellerImage ? (
                                                        <Button icon={<RedoOutlined />} onClick={() => setSellerImage(null)}>Capture Again</Button>
                                                    ) : (
                                                        !upload ? <Button icon={<CameraOutlined />} onClick={() => captureImageSeller(webcamRefSeller, setSellerImage)}>Capture Image </Button> : <Button icon={<RedoOutlined />} onClick={() => {
                                                            setSellerImage(null)
                                                            setUpload1(false)
                                                            setFile1(null)
                                                        }}>Capture Again</Button>
                                                    ),
                                                    <Button onClick={() => setUpload1(true)} icon={<UploadOutlined />}>Upload Image</Button>
                                                ]}
                                            >
                                                {upload ? <Upload.Dragger
                                                    beforeUpload={() => false} // Prevents automatic upload
                                                    listType="picture" // Shows uploaded images in a card format
                                                    onPreview={false} // Disables preview of images
                                                    accept='image/*'
                                                    onChange={(info) => handleUpload(info, setFile1)} // Handles the file upload
                                                >
                                                    Upload File
                                                </Upload.Dragger> : sellerImage ? (
                                                    <img src={sellerImage} alt="Seller" style={{ width: "100%", borderRadius: 8 }} />
                                                ) : (
                                                    <Webcam ref={webcamRefSeller} screenshotFormat="image/jpeg" style={{ width: "100%" }} />
                                                )}
                                            </Card>
                                        </Col>
                                        }

                                        {/* Purchaser Group */}
                                        {type !== "Open" &&
                                            <Col span={data?.formType !== "Open" ? 12 : 24}>
                                                <Card
                                                    title="Purchaser Group"
                                                    actions={[
                                                        purchaserImage ? (
                                                            <Button icon={<RedoOutlined />} onClick={() => {
                                                                setFile2()
                                                                setPurchaserImage(null)
                                                            }}>Capture Again</Button>
                                                        ) : (
                                                            !upload2 ? <Button icon={<CameraOutlined />} onClick={() => captureImagePurchaser(webcamRefPurchaser, setPurchaserImage)}>Capture Image</Button> : <Button icon={<RedoOutlined />} onClick={() => {
                                                                setPurchaserImage(null)
                                                                setUpload2(false)
                                                                setFile2(null)
                                                            }}>Capture Again</Button>
                                                        ),

                                                        <Button onClick={() => setUpload2(true)} icon={<UploadOutlined />}>Upload From File</Button>
                                                    ]}
                                                >

                                                    {upload2 ? <Upload.Dragger
                                                        beforeUpload={() => false}
                                                        listType={"picture"}
                                                        accept='image/*'
                                                        onPreview={false} // Disables preview of images
                                                        onChange={(info) => handleUpload(info, setFile2)}
                                                    >
                                                        Upload File
                                                    </Upload.Dragger> :
                                                        purchaserImage ? (
                                                            <img src={purchaserImage} alt="Purchaser" style={{ width: "100%", borderRadius: 8 }} />
                                                        ) : (
                                                            <Webcam ref={webcamRefPurchaser} screenshotFormat="image/jpeg" style={{ width: "100%" }} />
                                                        )}
                                                </Card>
                                            </Col>}
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    {type !== "Open" &&
                                        <Form.List name="purchasers">
                                            {(fields, { add, remove }) => (
                                                <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                                                    <Button type="dashed" onClick={() => add()} block style={{ marginTop: "10px" }}>
                                                        + Add Purchaser
                                                    </Button>
                                                    {fields.map((field) => (
                                                        <Card size="small" bordered={false} style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }} title={`Purchaser ${field.name + 1}`} key={field.key} extra={<CloseOutlined onClick={() => remove(field.name)} />}>
                                                            <Row gutter={[16, 16]}>
                                                                <Col span={24}>
                                                                    <Form.Item label="Name" name={[field.name, 'name']} rules={[{ required: true, message: 'Owner name is required' }]}>
                                                                        <Select
                                                                            allowClear
                                                                            showSearch
                                                                            filterOption={(input, option) =>
                                                                                option.filter.toLowerCase().includes(input.toLowerCase())
                                                                            }
                                                                            onSearch={handleMemberSearch}

                                                                            style={{ width: "100%", height: "55px", lineHeight: "2px" }}
                                                                            placeholder="Select an Owner"
                                                                            options={Member?.members?.map((owner) => ({
                                                                                label: (
                                                                                    <Space>
                                                                                        <Avatar src={owner?.image} size={40} />
                                                                                        <div>
                                                                                            <Text strong>{owner.owner_name}</Text>
                                                                                            <p style={{ marginTop: "-8px" }}>{owner.cnic}</p>
                                                                                        </div>
                                                                                    </Space>
                                                                                ),
                                                                                value: owner._id, // Ensure correct unique ID is used,
                                                                                filter: `${owner.owner_name} - ${owner.cnic}`,
                                                                            })) || []}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Form.Item label="Share" name={[field.name, 'share']} rules={[{ required: true, message: 'Share is required' }]}>
                                                                        <Input type='number' style={{ height: "55px" }} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Form.List name={[field.name, 'nominees']}>
                                                                    {(nomineeFields, { add: addNominee, remove: removeNominee }) => (
                                                                        <>
                                                                            <Button type="dashed" onClick={() => addNominee()} block>+ Add Nominee</Button>
                                                                            {nomineeFields.map((nomineeField) => (
                                                                                <Card key={nomineeField.key} bordered={false} style={{ width: "100%", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }} size="small" title={`Nominee ${nomineeField.name + 1}`} extra={<CloseOutlined onClick={() => removeNominee(nomineeField.name)} />}>
                                                                                    <Row gutter={[16, 16]}>
                                                                                        <Col span={12}>
                                                                                            <Form.Item label="Nominee/Next of Kin" name={[nomineeField.name, 'nominee']} rules={[{ required: true, message: 'Nominee is required' }]}>
                                                                                                <Select
                                                                                                    allowClear
                                                                                                    showSearch
                                                                                                    style={{ width: "100%", height: "55px" }}
                                                                                                    placeholder="Select an Nominee"
                                                                                                    optionFilterProp="filter"
                                                                                                    filterOption={(input, option) =>
                                                                                                        option.filter.toLowerCase().includes(input.toLowerCase())
                                                                                                    }
                                                                                                    options={witnominee
                                                                                                        ?.filter((item) => item.role === "Nominee")
                                                                                                        ?.map((owner) => ({
                                                                                                            label: <Space>
                                                                                                                <Avatar src={owner.Witness} size={40} />
                                                                                                                <div>
                                                                                                                    <Text strong>{owner.name}</Text>
                                                                                                                    <p style={{ marginTop: "-8px", fontSize: "12px" }}>{owner.cnic}</p>
                                                                                                                </div>
                                                                                                            </Space>,
                                                                                                            filter: `${owner.name} - ${owner.cnic}`,

                                                                                                            value: owner._id,
                                                                                                        }))}
                                                                                                />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                        <Col span={12}>
                                                                                            <Form.Item label="Relationship" name={[nomineeField.name, 'relationship']} rules={[{ required: true, message: 'Relationship is required' }]}>
                                                                                                <Input style={{ height: "55px" }} />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Card>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                </Form.List>
                                                            </Row>
                                                        </Card>
                                                    ))}
                                                </div>
                                            )}
                                        </Form.List>}
                                    {data?.formType !== "Open" && <Form.Item label="Witness 1" name="Witness1" rules={[{ required: true, message: 'Witness is required' }]} style={{ marginTop: "20px" }}>
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: "100%", height: "55px" }}
                                            placeholder="Select an Witness"
                                            optionFilterProp="filter"
                                            filterOption={(input, option) =>
                                                option.filter.toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={witnominee
                                                ?.filter((item) => item.role === "Witness")
                                                ?.map((owner) => ({
                                                    label: <Space>
                                                        <Avatar src={api.getUri() + "/api/" + owner.profile_image} size={40} />
                                                        <div>
                                                            <Text strong>{owner.name}</Text>
                                                            <p style={{ marginTop: "-8px", fontSize: "12px" }}>{owner.cnic}</p>
                                                        </div>
                                                    </Space>,
                                                    filter: `${owner.name} - ${owner.cnic}`,

                                                    value: owner._id,
                                                }))}
                                        />
                                    </Form.Item>}
                                    {type !== "Open" && <Form.Item label="Witness 2" name="Witness2" rules={[{ required: true, message: 'Witness is required' }]} style={{ marginTop: "20px" }}>
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: "100%", height: "55px" }}
                                            placeholder="Select an Witness"
                                            optionFilterProp="filter"
                                            filterOption={(input, option) =>
                                                option.filter.toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={witnominee
                                                ?.filter((item) => item.role === "Witness")
                                                ?.map((owner) => ({
                                                    label: <Space>
                                                        <Avatar src={api.getUri() + "/api/" + owner.profile_image} size={40} />

                                                        <div>
                                                            <Text strong>{owner.name}</Text>
                                                            <p style={{ marginTop: "-8px", fontSize: "12px" }}>{owner.cnic}</p>
                                                        </div>
                                                    </Space>,
                                                    filter: `${owner.name} - ${owner.cnic}`,

                                                    value: owner._id,
                                                }))}
                                        />

                                    </Form.Item>}

                                    {data?.formType !== "Open" ? <Button type="primary" block style={{ marginTop: "10px", height: "50px" }} htmlType='submit'>
                                        Assign File
                                    </Button> : <Button type="primary" block style={{ marginTop: "10px", height: "50px" }} onClick={handleSavePurchaserDetails}>
                                        Save Purchaser Details
                                    </Button>}
                                </Col>
                            </Row>
                        </Form > : <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, the File you visited does not exist."
                    />
                }
            </Modal >
        </>
    );
}

