import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSalesOrders, selectSalesOrders } from "../app/Service/salesOrderSlice";
import { Table, Typography, Card } from "antd";

const { Title } = Typography;

const SaleOrderList = () => {
    const dispatch = useDispatch();
    const salesOrders = useSelector(selectSalesOrders);

    useEffect(() => {
        dispatch(fetchSalesOrders());
    }, [dispatch]);

    const columns = [
        // { title: "Customer", dataIndex: "customer", key: "customer" },
        { title: "Order Date", dataIndex: "orderDate", key: "orderDate", render: (date) => new Date(date).toLocaleDateString() },
        { title: "Subtotal (₨)", dataIndex: "subTotal", key: "subTotal" },
        { title: "Tax (₨)", dataIndex: "totalTax", key: "totalTax" },
        { title: "Grand Total (₨)", dataIndex: "grandTotal", key: "grandTotal" },
        { title: "Status", dataIndex: "status", key: "status" }
    ];

    return (
        <Card>
            <Title level={3}>Sales Order List</Title>

            <Table dataSource={salesOrders} columns={columns} rowKey="_id" />
        </Card>
    );
};

export default SaleOrderList;
