import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectChargeIds } from '../app/Service/ChargesSlice'
import { Button, Card, Col, DatePicker, Divider, Form, InputNumber, Row, Select } from 'antd'
import { selectCharges } from '../app/Service/DataSlice';
import { useNavigate } from 'react-router-dom';

function CreateSchedule() {
    const Charges = useSelector(selectCharges);

    const charges = useSelector(selectChargeIds)

    const navigate = useNavigate()
    useEffect(() => {
        if (!charges.length) {
            navigate("/assign-file-list")
        }
    }, [charges])

    return (
        <Card title="Create Schedule manage" style={{ margin: "20px" }}>
            <Form layout={"vertical"}>
                <Row gutter={[8, 8]}>
                    <Col span={8}>
                        <Form.Item label="Charge Name" name="chargeName" rules={[{ required: true, message: 'Please enter charge name' }]}>
                            <Select
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Select Charge Name"}
                                // onSelect={handleFieldChange}
                                options={Charges?.data?.map((item, i) => ({
                                    key: i,
                                    label: item?.ChargeName,
                                    value: item?._id,
                                })) || []}
                            />
                        </Form.Item></Col>
                    <Col span={8}>
                        <Form.Item label="Amount" name="amount" rules={[{ required: true, message: 'Please enter amount' }]}>
                            <InputNumber type="number" style={{ width: "100%" }} min={0} placeholder="Enter amount" />
                        </Form.Item>

                    </Col>
                    <Col span={8}>
                        <Form.Item label="Last Date" name="lastDate" rules={[{ required: true, message: 'Please enter last date' }]}>
                            <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
                        </Form.Item>
                    </Col>
                    <Divider>

                    </Divider>
                    <Button htmlType='Submit' type={"primary"}>Save</Button>
                </Row>
            </Form>
        </Card >
    )
}

export default CreateSchedule