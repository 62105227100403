import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Form, Input, Row, Select, Space, Typography, message, Modal, Descriptions, Tag, Flex } from 'antd';
import React, { useState } from 'react';
import { fetchWitNominee, selectWitNominees } from '../app/Service/witNomineeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchplot, selectplot } from '../app/Service/plotSlice';
import { selectcompanies_name } from '../app/Service/companySlice';
import { fetchmember, selectmembers } from '../app/Service/memberslice';
import debounce from 'lodash/debounce';
import { addAssigndplot } from '../app/Service/AssignSlice';
import Swal from "sweetalert2";
import api from '../app/api';
import WitnessNomineeModal from './WitnessNomineeModal';
import MemberShipModal from './MemberShipModal';

const { Text } = Typography;

export default function AssignFile({ open, onClose, data }) {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const witnominee = useSelector(selectWitNominees);
    const company_name = useSelector(selectcompanies_name);
    const Plots = useSelector(selectplot);
    const Member = useSelector(selectmembers);
    const [modalVisible, setModalVisible] = useState(false);

    const dispatch = useDispatch();
    const handleFinish = async (values) => {
        if (!values.owners || values.owners.length === 0) {
            Swal.fire({ icon: "error", title: "Error", text: "At least one owner is required." });
            return;
        }
        const ownerNames = values.owners.map(owner => owner.name);
        const uniqueOwnerNames = new Set(ownerNames);
        if (ownerNames.length !== uniqueOwnerNames.size) {
            Swal.fire({ icon: "error", title: "Duplicate Owners", text: "Owner names must be unique." });
            return;
        }
        const totalShare = values.owners.reduce((sum, owner) => sum + (parseInt(owner.share) || 0), 0);
        if (totalShare !== 100) {
            Swal.fire({ icon: "error", title: "Invalid Share", text: "Total share must be exactly 100%." });
            return;
        }

        // ✅ Validate Witnesses (No duplicates)
        if (values.witnesses && values.witnesses.length > 0) {
            const witnessNames = values.witnesses.map(witness => witness.name);
            const uniqueWitnessNames = new Set(witnessNames);
            if (witnessNames.length !== uniqueWitnessNames.size) {
                Swal.fire({ icon: "error", title: "Duplicate Witnesses", text: "Witness names must be unique." });
                return;
            }
        }

        // ✅ Attach File Data
        values.File = {
            file_id: data?._id,
            F_ID: data?.File_ID,
            F_OID: data?.File_OID,
        };

        try {
            const res = await dispatch(addAssigndplot({ data: values, id: data?.Company?._id }));
            if (res?.meta?.requestStatus === "fulfilled") {
                form.resetFields();
                Swal.fire({ icon: "success", title: "Success", text: "Plot assigned successfully!" })
                    .then(() => { });
            } else {
                Swal.fire({ icon: "error", title: "Error", text: res?.payload?.message || "Something went wrong!" });
            }
        } catch (error) {
            Swal.fire({ icon: "error", title: "Error", text: error.message || "Failed to assign plot." });
        }
    };




    const handleMemberSearch = debounce((value) => {
        if (data?.Company?._id) {
            dispatch(fetchmember({ id: data?.Company?._id, query: value }));
        }
    }, 500);

    return (
        <>
            {/* <Button type="primary" onClick={() => {
                setIsModalVisible(true)
                dispatch(fetchWitNominee())
                dispatch(fetchplot({ id: data?.Company?._id, query: "", limit: "", start: "" }));
                dispatch(fetchmember({ id: data?.Company?._id }));

            }}>Assign File</Button> */}
            <WitnessNomineeModal visible={isModalVisible} onClose={() => setIsModalVisible(false)} record={null} />
            <MemberShipModal
                open={modalVisible}
                onCancel={() => {
                    setModalVisible(false)
                }}
                title={"Add Member"}
                // SelectedCom={data?.Company?._id}
            />
            <Modal
                title={
                    <>
                        <Flex justify='space-between'>
                            <Typography.Text>Assign File</Typography.Text>
                            <Space style={{ marginRight: "40px" }}>
                                <Button type={"primary"} onClick={() => setModalVisible(true)}>Add Memeber</Button>
                                <Button type={"primary"} onClick={() => setIsModalVisible(true)}>Nominee / Witness</Button>
                            </Space>
                        </Flex>
                    </>
                }
                open={open}
                onCancel={() => {
                    form.resetFields()
                    onClose()
                }}
                footer={null}
                width={900}
                destroyOnClose
                style={{ top: 10 }}

            >
                <Form form={form} layout='vertical' name="assign_file_form" autoComplete="off" initialValues={{ owners: [{}] }} onFinish={handleFinish}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Descriptions column={1} bordered>
                                        <Descriptions.Item label="Sector">Phase {data?.Phase?.Phase} - Block {data?.Block.Block}</Descriptions.Item>
                                        <Descriptions.Item label="Plot No">{data?.Plot_No}</Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col span={12}>
                                    <Descriptions column={1} bordered>
                                        <Descriptions.Item label="Street">{data?.Street}</Descriptions.Item>
                                        <Descriptions.Item label="Land Size">{data?.Land_Size}</Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col span={12}>
                                    <Descriptions column={1} bordered>
                                        <Descriptions.Item label="File OID">{data?.File_OID}</Descriptions.Item>

                                    </Descriptions>
                                </Col>
                                <Col span={12}>
                                    <Descriptions column={1} bordered>
                                        <Descriptions.Item label="File ID">{data?.File_ID}</Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Form.List name="owners">
                                {(fields, { add, remove }) => (
                                    <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                                        <Button type="dashed" onClick={() => add()} block style={{ marginTop: "10px" }}>
                                            + Add Owner
                                        </Button>
                                        {fields.map((field) => (
                                            <Card size="small" bordered={false} style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }} title={`Owner ${field.name + 1}`} key={field.key} extra={<CloseOutlined onClick={() => remove(field.name)} />}>
                                                <Row gutter={[16, 16]}>
                                                    <Col span={24}>
                                                        <Form.Item label="Owner Name" name={[field.name, 'name']} rules={[{ required: true, message: 'Owner name is required' }]}>
                                                            <Select
                                                                allowClear
                                                                showSearch
                                                                filterOption={(input, option) =>
                                                                    option.filter.toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                onSearch={handleMemberSearch}

                                                                style={{ width: "100%", height: "55px", lineHeight: "2px" }}
                                                                placeholder="Select an Owner"
                                                                options={Member?.members?.map((owner) => ({
                                                                    label: (
                                                                        <Space>
                                                                            <Avatar src={api.getUri() + "/api/" + owner?.image} size={40} />
                                                                            <div>
                                                                                <Text strong>{owner.owner_name}</Text>
                                                                                <p style={{ marginTop: "-8px" }}>{owner.cnic}</p>
                                                                            </div>
                                                                        </Space>
                                                                    ),
                                                                    value: owner._id, // Ensure correct unique ID is used,
                                                                    filter: `${owner.owner_name} - ${owner.cnic}`,
                                                                })) || []}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item label="Share" name={[field.name, 'share']} rules={[{ required: true, message: 'Share is required' }]}>
                                                            <Input type='number' style={{ height: "55px" }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Form.List name={[field.name, 'nominees']}>
                                                        {(nomineeFields, { add: addNominee, remove: removeNominee }) => (
                                                            <>
                                                                <Button type="dashed" onClick={() => addNominee()} block>+ Add Nominee</Button>
                                                                {nomineeFields.map((nomineeField) => (
                                                                    <Card key={nomineeField.key} bordered={false} style={{ width: "100%", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }} size="small" title={`Nominee ${nomineeField.name + 1}`} extra={<CloseOutlined onClick={() => removeNominee(nomineeField.name)} />}>
                                                                        <Row gutter={[16, 16]}>
                                                                            <Col span={12}>
                                                                                <Form.Item label="Nominee/Next of Kin" name={[nomineeField.name, 'nominee']} rules={[{ required: true, message: 'Nominee is required' }]}>
                                                                                    <Select
                                                                                        allowClear
                                                                                        showSearch
                                                                                        style={{ width: "100%", height: "55px" }}
                                                                                        placeholder="Select an Nominee"
                                                                                        optionFilterProp="filter"
                                                                                        filterOption={(input, option) =>
                                                                                            option.filter.toLowerCase().includes(input.toLowerCase())
                                                                                        }
                                                                                        options={witnominee
                                                                                            ?.filter((item) => item.role === "Nominee")
                                                                                            ?.map((owner) => ({
                                                                                                label: <Space>
                                                                                                    <Avatar src={api.getUri() + "/api/" + owner.profile_image} size={40} />

                                                                                                    <div>
                                                                                                        <Text strong>{owner.name}</Text>
                                                                                                        <p style={{ marginTop: "-8px", fontSize: "12px" }}>{owner.cnic}</p>
                                                                                                    </div>
                                                                                                    <Tag color='cyan'>{owner?.verification_status}</Tag>
                                                                                                </Space>,
                                                                                                filter: `${owner.name} - ${owner.cnic}`,

                                                                                                value: owner._id,
                                                                                            }))}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col span={12}>
                                                                                <Form.Item label="Relationship" name={[nomineeField.name, 'relationship']} rules={[{ required: true, message: 'Relationship is required' }]}>
                                                                                    <Input style={{ height: "55px" }} />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                ))}
                                                            </>
                                                        )}
                                                    </Form.List>
                                                </Row>
                                            </Card>
                                        ))}
                                    </div>
                                )}
                            </Form.List>
                            <Form.Item label="Witness 1" name="Witness1" rules={[{ required: true, message: 'Witness is required' }]} style={{ marginTop: "20px" }}>
                                <Select
                                    allowClear
                                    showSearch
                                    style={{ width: "100%", height: "55px" }}
                                    placeholder="Select an Witness"
                                    optionFilterProp="filter"
                                    filterOption={(input, option) =>
                                        option.filter.toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={witnominee
                                        ?.filter((item) => item.role === "Witness")
                                        ?.map((owner) => ({
                                            label: <Space>
                                                <Avatar src={api.getUri() + "/api/" + owner.profile_image} size={40} />

                                                <div>
                                                    <Text strong>{owner.name}</Text>
                                                    <p style={{ marginTop: "-8px", fontSize: "12px" }}>{owner.cnic}</p>
                                                </div>
                                                <Tag color='cyan'>{owner?.verification_status}</Tag>
                                            </Space>,
                                            filter: `${owner.name} - ${owner.cnic}`,

                                            value: owner._id,
                                        }))}
                                />
                            </Form.Item>
                            <Form.Item label="Witness 2" name="Witness2" rules={[{ required: true, message: 'Witness is required' }]} style={{ marginTop: "20px" }}>
                                <Select
                                    allowClear
                                    showSearch
                                    style={{ width: "100%", height: "55px" }}
                                    placeholder="Select an Witness"
                                    optionFilterProp="filter"
                                    filterOption={(input, option) =>
                                        option.filter.toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={witnominee
                                        ?.filter((item) => item.role === "Witness")
                                        ?.map((owner) => ({
                                            label: <Space>
                                                <Avatar src={api.getUri() + "/api/" + owner.profile_image} size={40} />

                                                <div>
                                                    <Text strong>{owner.name}</Text>
                                                    <p style={{ marginTop: "-8px", fontSize: "12px" }}>{owner.cnic}</p>
                                                </div>
                                                <Tag color='cyan'>{owner?.verification_status}</Tag>
                                            </Space>,
                                            filter: `${owner.name} - ${owner.cnic}`,

                                            value: owner._id,
                                        }))}
                                />

                            </Form.Item>

                            <Button type="primary" block style={{ marginTop: "10px", height: "50px" }} htmlType='submit'>
                                Assign File
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}
