import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjects, deleteProject, createProject, selectProjects, selectLoading, selectError } from "../app/Service/projectSlice";
import { Table, Button, Space, Popconfirm, message, Card, Modal, Form, Input } from "antd";

const ProjectList = () => {
    const dispatch = useDispatch();
    const projects = useSelector(selectProjects);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(fetchProjects());
    }, [dispatch]);

    const handleDelete = (id) => {
        dispatch(deleteProject(id))
            .unwrap()
            .then(() => message.success("Project deleted successfully"))
            .catch(() => message.error("Failed to delete project"));
    };

    const handleCreate = (values) => {
        dispatch(createProject(values))
            .unwrap()
            .then(() => {
                message.success("Project created successfully");
                setIsModalOpen(false);
                form.resetFields();
            })
            .catch(() => message.error("Failed to create project"));
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure to delete this project?"
                        onConfirm={() => handleDelete(record._id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger>
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <Card
            title="Project List"
            extra={
                <Button type="primary" onClick={() => setIsModalOpen(true)}>
                    Create Project
                </Button>
            }
        >
            {error && <p style={{ color: "red" }}>{error}</p>}
            <Table
                dataSource={projects}
                columns={columns}
                loading={loading}
                rowKey="_id"
                pagination={{ pageSize: 5 }}
            />

            {/* Create Project Modal */}
            <Modal
                title="Create New Project"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                <Form form={form} layout="vertical" onFinish={handleCreate}>
                    <Form.Item
                        name="name"
                        label="Project Name"
                        rules={[{ required: true, message: "Please enter project name" }]}
                    >
                        <Input placeholder="Enter project name" />
                    </Form.Item>
                    <Form.Item
                        name="location"
                        label="Location"
                        rules={[{ required: true, message: "Please enter project location" }]}
                    >
                        <Input placeholder="Enter location" />
                    </Form.Item>
                    <Form.Item
                        name="address"
                        label="Address"
                        rules={[{ required: true, message: "Please enter project address" }]}
                    >
                        <Input placeholder="Enter address" />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Create</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    );
};

export default ProjectList;
