import { Button, Form, Input, Modal, Select, notification } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createData, fetchBlocks, selectPhases } from '../app/Service/DataSlice';
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';

function ModalBlock({ OpenBlock, setOpenBlock }) {
  const [form] = Form.useForm(); // Use Ant Design Form instance
  const dispatch = useDispatch();
  const company_name = useSelector(selectcompanies_name);
  const Phases = useSelector(selectPhases);

  // Notification function
  const openNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      placement: 'topRight',
    });
  };

  const handleFinish = async (values) => {
    console.log('values', values)
    const res = await dispatch(createData({ modelName: "Block", data: values }));

    if (res.meta.requestStatus === "fulfilled") {
      openNotification('success', 'Success', 'Block created successfully');
      form.resetFields(); // Reset form fields
      setOpenBlock(false); // Close modal
      dispatch(fetchBlocks({ modelName: "Block", company_id: values?.company_id }));

    } else {
      openNotification('error', 'Error', res.payload?.error || 'Something went wrong');
    }
  };

  useEffect(() => {
    dispatch(getCompaniesByName({}));
  }, [dispatch]);

  return (
    <Modal
      open={OpenBlock}
      title="Block"
      onCancel={() => {
        form.resetFields(); // Reset form when closing modal
        setOpenBlock(false);
      }}
      footer={null}
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item label="Company" name="company_id" rules={[{ required: true, message: "Please select a company" }]}>
          <Select placeholder="Select Company" options={company_name?.map((dta) => ({ label: dta.companyName, value: dta._id }))} />
        </Form.Item>
        <Form.Item label="Phase" name="Phase_id" rules={[{ required: true, message: "Please select a Phase" }]}>
          <Select placeholder="Select Company" options={Phases?.data?.map((dta) => ({ label: dta.Phase, value: dta._id }))} />
        </Form.Item>
        <Form.Item label="Block" name="Block" rules={[{ required: true, message: "Please enter a Block name" }]}>
          <Input placeholder="Block Name" />
        </Form.Item>

        <br />
        <Button type="primary" htmlType="submit">Ok</Button>
      </Form>
    </Modal>
  );
}

export default ModalBlock;
