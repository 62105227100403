import React, { useRef } from "react";
import { Table, Typography, Row, Col, Divider, Space, Button, Flex } from "antd";
import { useReactToPrint } from "react-to-print";
import { PrinterFilled } from "@ant-design/icons";
import Barcode from "react-barcode";
import dayjs from "dayjs";
const { Title, Text } = Typography;

const PaymentReceipt = ({ record, data }) => {
    const transactionData = [
        { key: "1", date: "ID", description: record?.fileId?.File_ID, amount: "Amount", pamount: record?.installment ? data?.paidAmount : record?.paidAmount, },
        { key: "2", date: "Charge", description: record?.chargeName?.ChargeName, amount: "Status", pamount: record?.installment ? data?.status : record?.status, },
    ];

    const columns = [
        { title: "DATE", dataIndex: "date", key: "date" },
        { title: "DESCRIPTION", dataIndex: "description", key: "description" },
        { title: "AMOUNT ()", dataIndex: "amount", key: "amount", align: "right" },
        { title: "AMOUNT ()", dataIndex: "pamount", key: "pamount", align: "right" },

    ];

    const subtotal = transactionData.reduce((acc, item) => acc + item.amount, 0);
    const tax = subtotal * 0.1;
    const total = subtotal + tax;
    const contentRef = useRef();
    const reactToPrintFn = useReactToPrint({
        contentRef, // Corrected usage
        documentTitle: "Payment Receipt",
        pageStyle: `
            @page { size: A4; margin: 2mm; }
            body { font-family: Arial, sans-serif; }
            .print-container { width: 100%; background: #fff; padding: 10px 20px; }
            .center-text { text-align: center; }
            .info-section { padding: 10px 0; }
        `,
    });

    return (
        <>
            <Button onClick={reactToPrintFn}><PrinterFilled /></Button>
            <div style={{ display: "none" }}>
                <div ref={contentRef}    >

                    {
                        [1, 2, 3].map((item, i) => <div key={i} style={{ maxWidth: "800px", margin: "4px", padding: 20, border: "1px dashed rgb(54 53 53)", borderRadius: 0 }}>

                            <Row gutter={[4, 4]}>
                                <Col span={8}>
                                    <Text strong>{record?.company_id?.companyName}</Text> <br />
                                    <Text>{record?.company_id?.address} </Text> <br />
                                </Col>
                                <Col span={8} style={{ textAlign: "center" }}>
                                    <Text strong>Payment Receipt</Text> <br />
                                </Col>

                                <Col span={8} style={{ textAlign: "right" }}>

                                    <Space direction={"vertical"}>
                                        <Text>#{record?.sr} {record?.installment ? data && ("-" + data?.sr) : ""} </Text>
                                    </Space>
                                </Col>
                                <Col span={18}>
                                    <Flex style={{ width: "100%" }}>
                                        <p strong style={{ minWidth: "35%" }}>Registration No / File No :</p>
                                        <p style={{ border: "1px solid black", minWidth: "70%", }}>{record?.fileId?.File_ID} </p>
                                    </Flex>
                                </Col>
                                <Col span={6}>
                                    <Flex justify={"end"} style={{ width: "100%" }}>
                                        <Space><Text strong>Date:</Text> {dayjs(data ? data.updatedAt : record.updatedAt).format('DD/MM/YYYY')}</Space>
                                    </Flex>
                                </Col>
                                <Col span={24}>
                                    <Flex style={{ width: "100%" }}>
                                        <p strong style={{ minWidth: "20%" }}>Recevied With Thanks Mr/Mrs/Miss:</p>
                                        <p style={{ border: "1px solid black", minWidth: "80%", padding: "1px" }}>
                                            {record?.from
                                                ?.map((item) => `${item.owner_name} (${item?.cnic})`)
                                                .join(", ")}
                                        </p>


                                    </Flex>
                                </Col>
                                <Col span={24}>
                                    <Flex style={{ width: "100%" }}>
                                        <p strong style={{ minWidth: "20%" }}>S/o, D/O, W/O:</p>
                                        <p style={{ border: "1px solid black", minWidth: "80%", padding: "1px" }}>
                                            {record?.from
                                                ?.map((item) => `${item.fatherName}`)
                                                .join("/ ")}
                                        </p>
                                    </Flex>
                                </Col>
                                <Col span={24}>
                                    <Flex style={{ width: "100%" }}>
                                        <p strong style={{ minWidth: "20%" }}>A Sum OF Rupees : </p>
                                        <p style={{ border: "1px solid black", minWidth: "80%", padding: "1px" }}>{data ? data.amount : record?.amount} </p>
                                    </Flex>
                                </Col>
                                <Col span={5}>
                                    <Flex style={{ width: "100%" }}>
                                        <p strong style={{ minWidth: "20%" }}>Plot</p>
                                        <p style={{ border: "1px solid black", minWidth: "80%", padding: "1px" }}>{record?.fileId?.Plot_No} </p>
                                    </Flex>
                                </Col>
                                <Col span={7}>
                                    <Flex style={{ width: "100%" }}>
                                        <p strong style={{ minWidth: "40%" }}>Street No</p>
                                        <p style={{ border: "1px solid black", minWidth: "60%", padding: "1px" }}>{record?.fileId?.Street} </p>
                                    </Flex>
                                </Col>
                                <Col span={5}>
                                    <Flex style={{ width: "100%" }}>
                                        <p strong style={{ minWidth: "30%" }}>Block</p>
                                        <p style={{ border: "1px solid black", minWidth: "70%", padding: "1px" }}>{record?.fileId?.Block?.Block} </p>
                                    </Flex>
                                </Col>
                                <Col span={7}>
                                    <Flex style={{ width: "100%" }}>
                                        <p strong style={{ minWidth: "20%" }}>Type</p>
                                        <p style={{ border: "1px solid black", minWidth: "80%", padding: "1px" }}>{record?.fileId?.Residential_Type?.Residential} </p>
                                    </Flex>
                                </Col>

                            </Row>
                            <Row gutter={16} style={{ marginTop: 15 }}>
                                <Col span={12}>
                                    <Space>
                                        <Text strong>Payment Method:</Text> <br />
                                        <Text>{record?.installment ? data?.paymentMethod : record?.paymentMethod}</Text>
                                    </Space>
                                </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td><Text strong>Amount:</Text></td>
                                                <td>{data ? data.amount : record?.amount}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </Col>
                            </Row>

                            <Row style={{ marginTop: 20 }}>
                                <Col span={12}>
                                    <Text strong>Authorized Signature:</Text>
                                    <div style={{ borderTop: "1px solid black", width: "200px", marginTop: 30 }}></div>
                                </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    <Barcode value={"Asad"} width={1.5} height={50} displayValue={false} />
                                </Col>
                            </Row>
                        </div>)
                    }

                </div>
            </div>



        </>


    );
};

export default PaymentReceipt;
