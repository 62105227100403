import api from '../api'

const dataService = {
    createData: async (modelName, data) => {
        const response = await api.post(`/api/data/create`, { modelName, data });
        return response.data;
    },
    fetchData: async (modelName) => {
        const response = await api.get(`/api/data/fetch?modelName=${modelName?.modelName}&company_id=${modelName?.company_id}&Phase=${modelName?.Phase}`);
        return response.data;
    }
};

export default dataService;
