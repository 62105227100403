import api from '../api'

const API_URL = "/api/acc";

export const accountServices = {
    async importCharges(data) {
        const response = await api.get(API_URL + "/import/data?id=" + data.id + "&fileId=" + data.fileId);
        if (!response.body) {
            throw new Error("ReadableStream not supported");
        }
        return response.data;
    },


};
