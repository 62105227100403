import React, { useRef } from 'react'
import "./ReportDesign.css";
import { Typography, Row, Col, QRCode, Avatar, Button } from 'antd';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { PrinterFilled } from '@ant-design/icons';
import api from '../app/api';
const { Title, Text } = Typography
function ApplicationForm({ data: dataList, index }) {
    const contentRef = useRef()
    const reactToPrintFn = useReactToPrint({
        contentRef,
        documentTitle: "Application Report",
        pageStyle: `
                @page { size: A4; margin: 1mm; }
                body { font-family: Arial, sans-serif; }
                .print-container { width: 100%; background: #fff; padding: 5px 5px; }
                .center-text { text-align: center; }
                .info-section { border-bottom: padding: 10px 0; }
            `,
    });
    return (
        <>
            <Button onClick={reactToPrintFn}><PrinterFilled /></Button>
            <div style={{ display: "none" }}>
                <div ref={contentRef}    >
                    {dataList?.map((data, index) => {
                        { console.log('data', data) }
                        return data?.owners?.map((dta, i) => {
                            return (
                                <div className="print-container" key={i}>

                                    <div className="Header_Section">
                                        <div className="header-logo">
                                            <img src={api.getUri() + "/api/" + data?.company_id?.logo} alt="Company Logo" />

                                        </div>
                                        <div className="company-info">
                                            <Title level={3}>{data?.company_id?.companyName}</Title>
                                            <Text style={{ fontSize: "19px" }}>Application Form</Text>
                                        </div>
                                        <div className="contact-info">

                                            <Avatar size={100} shape={"square"} src={api.getUri() + "/api/" + dta?.name?.image}></Avatar>
                                        </div>
                                    </div>

                                    {/* Owner Information */}
                                    <p style={{ margin: "10px 0px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Vestibulum auctor libero eu justo vulputate, nec efficitur erat efficitur. Curabitur ac turpis et lacus gravida malesuada at id eros. Vestibulum auctor libero eu justo vulputate, nec efficitur erat efficitur. Curabitur ac </p>
                                    <div className="info-section owner-details">
                                        <Title level={5} style={{ textAlign: "left" }} className="section-title">Owner Details</Title>
                                        <div className="details-grid">
                                            <div className="detail-item">
                                                <strong>Name:</strong>
                                                <span>{dta?.name?.owner_name}</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>F's / H's :</strong>
                                                <span>{dta?.name?.fatherName}</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>CNIC:</strong>
                                                <span>{dta?.name?.cnic}</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Phone:</strong>
                                                <span>{dta?.name?.contact_number}</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Address:</strong>
                                                <span>
                                                    {dta?.name?.address?.length > 30
                                                        ? `${dta.name?.address.substring(0, 30)}...`
                                                        : dta.name?.address}
                                                </span>

                                            </div>
                                            <div className="detail-item">
                                                <strong>Share:</strong>
                                                <span>{dta?.share}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info-section owner-details">
                                        <Title level={5}>👩‍👧 Nominee Details</Title>
                                        <div className="details-grid">
                                            <div className="detail-item">
                                                <strong>Name:</strong>
                                                <span>
                                                    {dta?.nominees?.map((dt) => dt?.nominee?.name).filter(Boolean).join(" / ")}
                                                </span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Relation :</strong>
                                                <span>
                                                    {dta?.nominees?.map((dt) => dt?.relationship).filter(Boolean).join(" / ")}
                                                </span>

                                            </div>
                                            <div className="detail-item">
                                                {/* <strong>CNIC:</strong> */}
                                                <span>
                                                    {dta?.nominees?.map((dt) => dt?.nominee?.cnic).filter(Boolean).join(" / ")}
                                                </span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Phone:</strong>
                                                <span>
                                                    {dta?.nominees?.map((dt) => dt?.nominee?.contact).filter(Boolean).join(" / ")}
                                                </span>

                                            </div>
                                            <div className="detail-item">
                                                <strong>Sign:</strong>
                                                <span></span>
                                            </div>

                                        </div>
                                    </div>


                                    {/* Plot Information */}
                                    <div className="info-section plot-details">
                                        <Title level={5} className="section-title" style={{ textAlign: "left" }}> Plot Information</Title>
                                        <div className="details-grid">
                                            <div className="detail-item">
                                                <strong>File Number:</strong>
                                                <span>{data?.File?.file_id?.File_ID}</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Plot Number:</strong>
                                                <span>{data?.File?.file_id?.Plot_No}</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Plot Size:</strong>
                                                <span>{data?.File?.file_id?.Land_Size}</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Sector:</strong>
                                                <span>Phase-{data?.File?.file_id?.Phase?.Phase}, Block-{data?.File?.file_id?.Block?.Block},Street-{data?.File?.file_id?.Street} </span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Booking Date:</strong>
                                                <span>{dayjs(data.updatedAt).format("DD-MM-YYYY")}</span>
                                            </div>
                                            {/* <div className="detail-item">
                <strong>Street:</strong>
                <span>{data?.File?.file_id?.Street}</span>
            </div> */}

                                            <div className="detail-item">
                                                <strong>Property Status:</strong>
                                                <span>{data?.File?.file_id?.file_status}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="info-section owner-details">
                                        <Title level={5} style={{ textAlign: "left" }}>👨‍⚖️ Witness Information</Title>
                                        <div className="details-grid">
                                            {/* {data?.Witness?.map((dt) => (<> */}
                                            <div className="detail-item">
                                                <strong>Name:</strong>
                                                <span>
                                                    {data?.Witness1
                                                        ?.name}
                                                </span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>CNIC:</strong>
                                                {data?.Witness2
                                                    ?.cnic}
                                            </div>
                                            <div className="detail-item">
                                                <strong>Name:</strong>
                                                <span>
                                                    {data?.Witness1
                                                        ?.name}
                                                </span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>CNIC:</strong>
                                                {data?.Witness1
                                                    ?.cnic}
                                            </div>
                                            {/* </>))} */}
                                            <div className="detail-item">
                                                <strong>Sign:</strong>
                                                <span></span>
                                            </div>

                                        </div>
                                    </div>


                                    <div className="footer-section" style={{ marginTop: data?.Witness?.length > 1 ? "" : "" }}>
                                        <Row gutter={16} align="middle" justify="space-between">

                                            <Col span={6} className="qr-code-container">
                                                <QRCode
                                                    value={data?.File?.file_id?.File_ID}
                                                    color={"black"}
                                                    bgColor={"white"}
                                                    bordered={false}
                                                    size={80}
                                                />
                                            </Col>
                                            <Col span={11}>
                                                <p>{data?.company_id?.address}</p></Col>

                                            <Col span={7} className="company-info">
                                                <p>https://ichs.neovatus.com</p>
                                                <p>Email: {data?.company_id?.user_email}</p>

                                            </Col>
                                        </Row>
                                    </div>

                                </div>
                            )
                        })
                    })}
                </div>
            </div >
        </>


    )
}

export default ApplicationForm