import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Upload, Button, notification, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectcompanies_name } from '../app/Service/companySlice';
import { addWitNominee, fetchWitNominee, updateWitness } from '../app/Service/witNomineeSlice';
import { uploadSingleFile } from '../app/Service/fileSlice';
import Swal from "sweetalert2";
import api from '../app/api';

function WitnessNomineeModal({ visible, onClose, record }) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const company_name = useSelector(selectcompanies_name);

    // Fill form when editing
    useEffect(() => {
        if (visible && record) {
            console.log('record', record)
            form.setFieldsValue({
                ...record,
            });
        } else {
            form.resetFields();
        }
    }, [visible, record, form]);


    const handleFinish = async (values) => {
        let cnic_images = {}; // Initialize properly
        let profile_image;

        try {
            // Array to hold file upload promises
            const uploadTasks = [];

            if (values?.front?.file) {
                uploadTasks.push(
                    dispatch(uploadSingleFile(values.front.file)).then((res) => {
                        if (res.meta.requestStatus === "fulfilled") {
                            cnic_images.front = res.payload.file.path;
                        }
                    })
                );
            }

            if (values?.back?.file) {
                uploadTasks.push(
                    dispatch(uploadSingleFile(values.back.file)).then((res) => {
                        if (res.meta.requestStatus === "fulfilled") {
                            cnic_images.back = res.payload.file.path;
                        }
                    })
                );
            }

            if (values?.profile_image?.file) {
                uploadTasks.push(
                    dispatch(uploadSingleFile(values.profile_image.file)).then((res) => {
                        if (res.meta.requestStatus === "fulfilled") {
                            profile_image = res.payload.file.path;
                        }
                    })
                );
            }

            // Wait for all uploads to complete
            await Promise.all(uploadTasks);

            // Include uploaded image paths in values before sending
            const updatedValues = {
                ...values,
                cnic_images: Object.keys(cnic_images).length > 0 ? cnic_images : undefined,
                profile_image: profile_image || undefined,
            };

            let res;
            if (record) {
                res = await dispatch(updateWitness(updatedValues));
            } else {
                res = await dispatch(addWitNominee(updatedValues));
            }

            // Handle response
            if (res.meta.requestStatus === "fulfilled") {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: record ? "Nominee updated successfully!" : "Nominee added successfully!",
                });
                dispatch(fetchWitNominee(values?.company_id))

                onClose();
                form.resetFields();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: res.payload?.error || "Something went wrong!",
                });
            }
        } catch (error) {
            console.error("File upload failed:", error);
            Swal.fire({
                icon: "error",
                title: "Upload Error",
                text: "Failed to upload images. Please try again.",
            });
        }
    };


    const countries = [
        "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia",
        "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
        "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde",
        "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo",
        "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic",
        "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland",
        "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau",
        "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
        "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon",
        "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives",
        "Mali", "Malta", "Mauritania", "Mauritius", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique",
        "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Korea",
        "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines",
        "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
        "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore",
        "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka",
        "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste",
        "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates",
        "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen",
        "Zambia", "Zimbabwe"
    ];

    return (
        <Modal
            open={visible}
            title={record ? "Edit Witness/Nominee/Agent" : "Add Witness/Nominee/Agent"}
            onCancel={onClose}
            footer={null}
            width={900}
        >
            <Form layout="vertical" form={form} onFinish={handleFinish}>
                <Row gutter={[16, 16]}>
                    {/* Role */}

                    <Col span={12}>
                        <Form.Item name="company_id" label="Company" rules={[{ required: true, message: "Enter Name" }]}>
                            <Select
                                placeholder="Select Company"
                                options={company_name?.map((dta) => ({ label: dta.companyName, value: dta._id }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="name" label="Full Name" rules={[{ required: true, message: "Enter Name" }]}>
                            <Input placeholder="Enter Full Name" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="role" label="Role" rules={[{ required: true, message: "Select Role" }]}>
                            <Select placeholder="Select Role">
                                <Select.Option value="Witness">Witness</Select.Option>
                                <Select.Option value="Agent">Agent</Select.Option>
                                <Select.Option value="Nominee">Nominee</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="cnic" label="CNIC Number" rules={[{ required: true, message: "Enter CNIC" }]}>
                            <Input placeholder="Enter CNIC" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="contact" label="Contact Number" rules={[{ required: true, message: "Enter Contact" }]}>
                            <Input placeholder="Enter Contact Number" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name={['address', 'street']} label="Street">
                            <Input placeholder="Enter Street" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Country"
                            name={['address', 'nationality']}
                            rules={[{ required: true, message: 'Please select a country' }]}
                        >
                            <Select allowClear showSearch placeholder="Select Country">
                                {countries.map((country, index) => (
                                    <Select.Option key={index} value={country}>
                                        {country}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={['address', 'city']} label="City" rules={[{ required: true, message: "Enter City" }]}>
                            <Input placeholder="Enter City" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="occupation" label="Occupation">
                            <Input placeholder="Enter Occupation" />
                        </Form.Item>
                    </Col>

                    {/* Verification Status */}
                    <Col span={12}>
                        <Form.Item name="verification_status" label="Verification Status">
                            <Select>
                                <Select.Option value="Pending">Pending</Select.Option>
                                <Select.Option value="Verified">Verified</Select.Option>
                                <Select.Option value="Rejected">Rejected</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="front" label="CNIC Front Image" rules={[{ required: false, message: "Please upload CNIC Front Image" }]} >
                            <Upload.Dragger accept='image/*' maxCount={1} beforeUpload={() => false}

                            >
                                Upload Front Image
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>

                    {/* CNIC Back Image */}
                    <Col span={12}>
                        <Form.Item name="back" label="CNIC Back Image" rules={[{ required: false, message: "Please upload CNIC Back Image" }]}>
                            <Upload.Dragger maxCount={1} accept='image/*' beforeUpload={() => false}

                            >
                                Upload Back Image
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name="profile_image" accept='image/*' label="Profile Image"
                            rules={[{ required: false, message: "Please upload Profile Image" }]}
                        >
                            <Upload.Dragger maxCount={1} beforeUpload={() => false}
                            >
                                Upload Profile Image
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{ textAlign: "right", marginTop: "10px" }}>
                    <Button type="primary" htmlType="submit">
                        {record ? "Update" : "Add"}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}

export default WitnessNomineeModal;
