import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Dropdown, Menu, Card, Flex, Modal, Form, Space, Upload, notification, Typography, Row, Col, Tooltip, Select, message } from 'antd';
import { FileExcelOutlined, FilePdfOutlined, PrinterOutlined, ImportOutlined, PrinterFilled } from '@ant-design/icons';
import { deleteCompany, exportCompanies, fetchCompanies, importCompany, selectCompanies, getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { deleteDepartment, exportDepartment, fetchDepartments, importDepartment, selectDepartment, selectError, selectLoading, addDepartment, updateDepartment, handlePrint, getDepartmentByName, selectDepartments_name } from '../app/Service/departmentSlice';
import { useDispatch, useSelector } from 'react-redux'
import { uploadSingleFile } from '../app/Service/fileSlice';
import { departmentServices } from '../app/Service/departmentService';
import { useForm } from 'antd/es/form/Form';
import Swal from "sweetalert2";

const DepartmentList = () => {
    const [valuestext, setvaluestext] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);
    const [form] = useForm();
    const dispatch = useDispatch()
    const company_name = useSelector(selectcompanies_name)
    const Department = useSelector(selectDepartment);
    const [SelectedCom, setSelectedCom] = useState();


    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const [dept, setDept] = useState([]);
    const [limit, setLimit] = useState(9876543210);
    const [start, setStart] = useState(0);
    const [btnloading, setbtnloading] = useState(false)
    const handleDelete = (id) => {
        Modal.confirm({
            title: "Are you sure?",
            content: "This action cannot be undone.",
            okText: "Yes, Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk: async () => {
                await dispatch(deleteDepartment(id))
                dispatch(fetchDepartments({ id: SelectedCom, query: valuestext, limit, start }));
            },
        });
    };
    useEffect(() => {
        dispatch(getCompaniesByName({}));
    }, []);
    useEffect(() => {
        if (company_name?.length > 0) {
            setSelectedCom(company_name[0]?._id)
            dispatch(fetchDepartments({ id: company_name[0]?._id, query: "", limit, start }));
        }
    }, [company_name])
    useEffect(() => {

        if (SelectedCom) {
            dispatch(fetchDepartments({ id: SelectedCom, query: "", limit, start }));

        }
    }, [SelectedCom])
    const columns = [
        {
            title: '#',
            dataIndex: 'rowNumber',
            render: (text, record, index) => index + 1,
            width: 50,
        },
        {
            title: 'Company Name',
            dataIndex: 'department',
            key: 'department',
        },

        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Dropdown dropdownRender={() => <Menu>
                    <Menu.Item onClick={() => handleEdit(record)}>Edit</Menu.Item>
                    <Menu.Item onClick={() => handleDelete(record?._id)}>Delete</Menu.Item>
                </Menu>}>
                    <Button>Action</Button>
                </Dropdown>
            ),
        },
    ];
    const handelUploadFile = async ({ file }) => {
        try {
            const res = await dispatch(uploadSingleFile(file));

            if (res.meta.requestStatus === "fulfilled") {
                const file = res?.payload.file;

                await dispatch(importDepartment({ file, id: SelectedCom }));

                await dispatch(fetchCompanies({ query: "" }));
                notification.success({
                    message: 'File Uploaded Successfully',
                    description: 'The file was uploaded and Department imported successfully.',
                    placement: 'topRight',
                });
            }
        } catch (error) {
            notification.error({
                message: 'File Upload Failed',
                description: 'There was an error uploading the file. Please try again.',
                placement: 'topRight',
            });

            console.error('Error handling file upload:', error);
        }
    };


    const handleSearch = (e) => {
        setvaluestext(e.target.value)
        dispatch(fetchDepartments({ id: SelectedCom, query: e.target.value }));
    };

    const handleEdit = (record) => {
        setEditingRecord(record);
        form.setFieldsValue(record);
        setIsModalVisible(true);
    };


    const handelFinish = async (values) => {
        setbtnloading(true);
    
        try {
            let res;
    
            if (editingRecord) {
                res = await dispatch(updateDepartment({ id: editingRecord?._id, data: values }));
    
                if (res.meta.requestStatus === 'rejected') {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.payload || "Something went wrong. Please try again.",
                    });
                } else {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Department updated successfully!",
                    });
    
                    dispatch(fetchDepartments({ id: SelectedCom, query: valuestext, limit, start }));
                }
            } else {
                res = await dispatch(addDepartment(values));
    
                if (res.meta.requestStatus === 'rejected') {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.payload?.error || "Something went wrong. Please try again.",
                    });
                } else {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Department added successfully!",
                    });
    
                    dispatch(fetchDepartments({ id: SelectedCom }));
                    form.resetFields();
                }
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "An unexpected error occurred.",
            });
        } finally {
            setbtnloading(false);
        }
    };
    
    const handleScroll = async (e) => {
        const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
        let array1 = (Department && Department.department) ? Department.department : []
        let array2 = dept ? dept : []
        let table = [...array1, ...array2]
        if (bottom && !loading && table.length < Department.totalCount) {
            const data = await departmentServices.getAllDepartments({ id: SelectedCom, query: valuestext, limit: limit, start: start + limit })
            setStart(start + limit);
            let newdata = [...data?.department]
            setDept(newdata)
        }
    };
    let array1 = (Department && Department.department) ? Department.department : []
    let array2 = dept ? dept : []
    let table = [...array1, ...array2]

    return (
        <Card title="Department  List">
            <div>
                <Space style={{ marginBottom: 16 }}>
                    <Button type="primary" onClick={() => setIsModalVisible(true)}>+ Add Department</Button>
                </Space>
                <Flex justify={"space-between"} align={"center"}>
                    <Space>
                        <Select
                            value={SelectedCom}  
                            options={company_name?.map(company => ({
                                label: company?.companyName,
                                value: company?._id,
                            }))}
                            style={{ width: 300, marginBottom: 16, marginRight: 20 }}
                            onChange={(e) => setSelectedCom(e)}
                        />
                        <Input
                            placeholder="Search Department"
                            onChange={handleSearch}
                            value={valuestext}
                            style={{ width: 200, marginBottom: 16 }}
                        />
                    </Space>
                    <div style={{ marginBottom: 16 }}>
                        <Typography.Text style={{ marginRight: "20px" }}>
                            Showing {table?.length} of {Department?.totalCount}
                        </Typography.Text>

                        {/* <Tooltip title="Import">
                            <Upload
                                accept=".xls,.xlsx"
                                onChange={handelUploadFile}
                                showUploadList={false}
                                beforeUpload={() => false}>
                                <Button variant={'outlined'} color={'danger'} icon={<ImportOutlined />} style={{ marginRight: 8 }}></Button>
                            </Upload>
                        </Tooltip> */}
                        <Tooltip title="Export in to Excel"> <Button variant={'outlined'} onClick={() => dispatch(exportDepartment({ type: "excel", id: SelectedCom }))} color={"primary"} icon={<FileExcelOutlined />} style={{ marginRight: 8 }} /></Tooltip>
                        <Tooltip title="Export in to Pdf">
                            <Button variant={'outlined'} onClick={() => dispatch(exportDepartment({ type: "pdf", id: SelectedCom }))} icon={<FilePdfOutlined />} style={{ marginRight: 8, borderColor: "green", color: "green" }} /> </Tooltip>
                        <Tooltip title="Print"><Button variant={'outlined'} onClick={() => dispatch(handlePrint(SelectedCom))} style={{ borderColor: "orange", color: "orange" }} icon={<PrinterFilled />} /> </Tooltip>
                    </div>
                </Flex>
                <Table
                    columns={columns}
                    loading={loading}
                    dataSource={table}
                    pagination={false}
                    scroll={{ y: 'calc(100vh - 300px)' }}
                    onScroll={handleScroll}
                />

                <Modal
                    title={editingRecord ? "Update Department Details" : "Add Department"}
                    open={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}  // Keep null to avoid default buttons
                    style={{ top: 20 }}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handelFinish}
                        autoComplete="off"
                    >
                        <Row gutter={[8, 8]}>
                            <Col span={12}>
                                <Form.Item
                                    name="department"
                                    label=" Department Name"
                                    rules={[{ required: true, message: 'Department  is required' }]}
                                >
                                    <Input disabled={editingRecord} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="phone"
                                    label="Phone"
                                    rules={[{ required: true, message: 'Phone is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="address"
                                    label="Address"
                                    rules={[{ required: true, message: 'Address  is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="parentCompany"
                                    label="Company"
                                    rules={[{ required: true, message: 'Parent Company  is required' }]}

                                >
                                    <Select
                                        options={company_name?.map(company => ({
                                            label: company?.companyName,
                                            value: company?._id,
                                        }))} >

                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                {btnloading ? <Button type="primary" loading={btnloading}>Save</Button> : <Button type="primary" htmlType="submit">Save</Button>}

                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        </Card>
    );
};
export default DepartmentList;
