import axios from "axios";
import api from "../api";

const API_URL = "/api/products"; // Adjust this based on your backend setup

// ✅ Fetch all products
const getAllProducts = async () => {
    const response = await api.get(API_URL);
    return response.data;
};

// ✅ Create new product
const createProduct = async (productData) => {
    const response = await api.post(API_URL, productData);
    return response.data;
};

// ✅ Delete product
const deleteProduct = async (id) => {
    await api.delete(`${API_URL}/${id}`);
};

const productService = {
    getAllProducts,
    createProduct,
    deleteProduct,
};

export default productService;
