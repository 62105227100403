import React, { useEffect, useState } from 'react'
import { Button, Card, Flex, Select, Space, Table } from 'antd'
import ModalStatus from './ModalStatus'
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, fetchStatus, selectStatus } from '../app/Service/DataSlice';

function Status() {
  const [OpenStatus, setOpenStatus] = useState(false);
  const dispatch = useDispatch();
  const company_name = useSelector(selectcompanies_name);
  const Status = useSelector(selectStatus);
  const [company, setCompany] = useState(null);


  useEffect(() => {
    if (company_name.length > 0) {
      const defaultCompanyId = company_name[0]?._id;
      setCompany(defaultCompanyId);
      dispatch(fetchStatus({ modelName: "Status", company_id: defaultCompanyId }));
    }
  }, [company_name, dispatch]);

  // Handle company selection change
  const handleCompanyChange = (value) => {
    setCompany(value);
    dispatch(fetchStatus({ modelName: "Status", company_id: value }));
  };
  return (
    <>
      <ModalStatus OpenStatus={OpenStatus} setOpenStatus={setOpenStatus} />

      <Card
        title={"Status"}
        extra={
          <Select
            style={{ width: "200px" }}
            value={company}
            placeholder="Select Company"
            options={company_name?.map((dta) => ({ label: dta.companyName, value: dta._id }))}
            onChange={handleCompanyChange}
          />
        }
      >
        <Flex style={{ marginBottom: "10px" }}>
          <Space>
            <Button type="primary" onClick={() => setOpenStatus(true)}>+ Add Status</Button>
          </Space>
        </Flex>

        <Table
          columns={[
            {
              key: "1",
              title: "Status Name",
              dataIndex: "Status",
              width: "80%"
            },
            {
              key: "2",
              title: "Action",
              dataIndex: "_id",
              render: (id) => <Button type='text' danger>Delete</Button>
            }
          ]}
          dataSource={Status?.data?.map((Status) => ({ key: Status._id, ...Status }))}
        />
      </Card>
    </>
  );
}

export default Status;
