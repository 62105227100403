import api from "../api";

const API_URL = "/api/suppliers";

// Fetch all suppliers
const getSuppliers = async () => {
    try {
        const response = await api.get(API_URL);
        return response.data;
    } catch (error) {
        throw error.response?.data || "Error fetching suppliers";
    }
};

// Add a new supplier
const addSupplier = async (supplierData) => {
    try {
        const response = await api.post(API_URL, supplierData);
        return response.data;
    } catch (error) {
        throw error.response?.data || "Error adding supplier";
    }
};

// Delete a supplier
const deleteSupplier = async (supplierId) => {
    try {
        const response = await api.delete(`${API_URL}/${supplierId}`);
        return response.data;
    } catch (error) {
        throw error.response?.data || "Error deleting supplier";
    }
};

const supplierService = {
    getSuppliers,
    addSupplier,
    deleteSupplier,
};

export default supplierService;
