import React, { useEffect, useState } from "react";
import { Table, Input, Row, Col, Typography, Spin, Alert, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrialBalance, selectTrialBalance } from "../app/Service/journalSlice";

const { Title } = Typography;

const TrialBalance = () => {
    const dispatch = useDispatch();
    const trialBalance = useSelector(selectTrialBalance);
    const loading = useSelector((state) => state.journal.loading);
    const error = useSelector((state) => state.journal.error);

    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        dispatch(fetchTrialBalance());
    }, [dispatch]);

    const filteredData = trialBalance && Array.isArray(trialBalance)
        ? trialBalance.filter(account => account.name?.toLowerCase().includes(searchText.toLowerCase()))
        : [];

    console.log("Trial Balance Data:", trialBalance);
    console.log("Filtered Data:", filteredData);

    const totalDebit = filteredData.reduce((sum, record) => sum + (record.debit || 0), 0);
    const totalCredit = filteredData.reduce((sum, record) => sum + (record.credit || 0), 0);
    const totalAccounts = filteredData.length;  // ✅ Count number of rows

    const columns = [
        {
            title: "#",
            dataIndex: "#",
            key: "#",
            width: 70,
            render: (value, data, i) => i + 1,
        },
        { title: "Sr No", dataIndex: "srNo", key: "srNo", width: 100, },
        { title: "Account Name", dataIndex: "name", key: "name" },
        {
            title: "Debit",
            dataIndex: "debit",
            key: "debit",
            align: "right",
            render: (value) => value > 0 ? <span style={{ color: "green", fontWeight: "bold" }}>{value.toFixed(2)}</span> : "-",
        },
        {
            title: "Credit",
            dataIndex: "credit",
            key: "credit",
            align: "right",
            render: (value) => value > 0 ? <span style={{ color: "red", fontWeight: "bold" }}>{value.toFixed(2)}</span> : "-",
        }
    ];

    return (
        <Card bordered style={{ height: "100vh" }} title="Trial Balance" extra={<Input
            placeholder="Search Account"
            style={{ width: "300px" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
        />}>


            {loading && <Spin size="large" />}
            {error && <Alert message="Error" description={error} type="error" showIcon />}

            <Table
                columns={columns}
                dataSource={filteredData || []}
                pagination={{ pageSize: 300 }}
                bordered
                rowKey={(record, index) => (record.name || "row") + index}
                // title={() => "Account Details"}
                scroll={{ y: 400 }}
                summary={() => {
                    if (!Array.isArray(filteredData) || filteredData.length === 0) return null;
                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row style={{ backgroundColor: "#f0f0f0", fontSize: "16px", fontWeight: "bold" }}>
                                <Table.Summary.Cell colSpan={3} style={{ textAlign: "left" }}>
                                    Total ({totalAccounts} Accounts)
                                </Table.Summary.Cell>

                                <Table.Summary.Cell align="right" style={{ color: "green" }}>
                                    {totalDebit.toFixed(2)}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell align="right" style={{ color: "red" }}>
                                    {totalCredit.toFixed(2)}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    );
                }}
                style={{ background: "#fff", height: "500px" }}
            />
        </Card>
    );
};

export default TrialBalance;
