import React, { useEffect, useState } from 'react';
import { Button, Modal, Typography, Input, Tag, Descriptions } from 'antd';
import { DataGrid } from '@mui/x-data-grid';
import { FetchTransferFile } from '../../App/Slice/transferSlice';
import { useDispatch, useSelector } from 'react-redux';
import TransferManage from '../../Components/Forms/TransferManage';

const { Text } = Typography;
const { Search } = Input;

const FileTransfer = ({ account }) => {
    const dispatch = useDispatch();
    const { transfer_file } = useSelector(state => state.transfer);
    const [visible, setVisible] = useState(false);
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [selected, setSelected] = useState(null);
    const [detailsRow, setDetailsRow] = useState(null);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        dispatch(FetchTransferFile());
    }, [dispatch]);

    const statusColorMap = {
        'Draft': 'default',
        'Submitted to Manager': 'blue',
        'Under Review by Accounts': 'orange',
        'Approved by Accounts': 'green',
        'Rejected by Accounts': 'red',
        'Under Review by Manager': 'orange',
        'Approved by Manager': 'green',
        'Rejected by Manager': 'red',
        'Under Final Review by Admin': 'orange',
        'Rejected by Admin': 'red',
        'Transfer Completed': 'green',
        'Closed': 'purple',
        'Open': 'gold',
    };

    const formattedData = (transfer_file ?? []).map(file => {
        const sellers = file.sellerName.map(seller => ({
            name: seller.owner.ownerName,
            cnic: seller.owner.cnic,
            share: seller.share,
        }));

        const purchasers = file.purchasers.map(purchaser => ({
            name: purchaser.owner.ownerName,
            cnic: purchaser.owner.cnic,
            share: purchaser.share,
        }));

        return {
            id: file.fileId._id,
            tNo: file.tNo,
            sellerName: sellers.map(s => s.name).join(', '),
            sellerCnic: sellers.map(s => s.cnic).join(', '),
            sellerShare: sellers.map(s => s.share).join(', '),
            purchaserName: purchasers.map(p => p.name).join(', '),
            purchaserCnic: purchasers.map(p => p.cnic).join(', '),
            purchaserShare: purchasers.map(p => p.share).join(', '),
            status: file.status,
        };
    }).reduce((unique, item) => {
        if (!unique.find(u => u.id === item.id)) {
            unique.push(item);
        }
        return unique;
    }, []);

    const filteredData = formattedData.filter(item =>
        item.sellerName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.purchaserName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.sellerCnic.includes(searchText.toLowerCase()) ||
        item.purchaserCnic.toLowerCase().includes(searchText.toLowerCase()) ||
        item.tNo.toLowerCase().includes(searchText.toLowerCase()) ||
        item.status.toLowerCase().includes(searchText.toLowerCase())
    );

    const columns = [
        {
            field: 'Sr.',
            headerName: 'Sr',
            width: 100,
            renderCell: (params) => {
                const rowIndex = filteredData.findIndex(row => row.id === params.id);
                return <>{rowIndex + 1}</>;
            },
        },
        { field: 'tNo', headerName: 'Transfer No', width: 150 },
        { field: 'sellerName', headerName: 'Seller Name', width: 400 },
        { field: 'sellerCnic', headerName: 'Seller CNIC', width: 200 },
        { field: 'sellerShare', headerName: 'Share', width: 100 },
        { field: 'purchaserName', headerName: 'Purchaser Name', width: 400 },
        { field: 'purchaserCnic', headerName: 'Purchaser CNIC', width: 200 },
        { field: 'purchaserShare', headerName: 'Share', width: 100 },
        {
            field: 'status',
            headerName: 'Status',
            width: 250,
            renderCell: (params) => {
                const status = params.row.status;
                const color = statusColorMap[status] || 'default';
                return <Tag color={color}>{status}</Tag>;
            },
        },
        {
            field: 'Manage',
            headerName: 'Manage',
            width: 150,
            renderCell: (params) => (
                <>
                    {params.row.status === 'Transfer Completed' ? (
                        <Button disabled>Manage</Button>
                    ) : (
                        <Button type="primary" onClick={() => handleManageClick(params.row)}>Manage</Button>
                    )}
                </>
            ),
        },
        {
            field: 'Details',
            headerName: 'Details',
            width: 150,
            renderCell: (params) => (
                <Button type="primary" danger onClick={() => handleDetailsClick(params.row)}>Details</Button>
            ),
        },
    ];

    const handleManageClick = (row) => {
        setSelected(row.id);
        setVisible(true);
    };

    const handleDetailsClick = (row) => {
        const rawData = transfer_file?.find(file => file.fileId._id === row.id);
        console.log('rawData', rawData)
        if (rawData) {
            setDetailsRow(rawData);
            setDetailsVisible(true);
        }
    };

    // Recursive function to render all details dynamically
    const renderDetails = (data) => {
        return (
            <Descriptions bordered column={2} size="small">
                <Descriptions.Item label="ID">{data?.fileId?.newMembershipId}</Descriptions.Item>
                <Descriptions.Item label="Status">{data.status}</Descriptions.Item>
                <Descriptions.Item label="Seller Name">
                    {data.sellerName?.map(seller => seller.owner.ownerName).join(' / ')}
                </Descriptions.Item>
                <Descriptions.Item label="Seller CNIC">
                    {data.sellerName?.map(seller => seller.owner.cnic).join(' / ')}
                </Descriptions.Item>
                <Descriptions.Item label="Purchaser Name">
                    {data.purchasers?.map(purchaser => purchaser.owner.ownerName).join(' / ')}
                </Descriptions.Item>
                <Descriptions.Item label="Purchaser CNIC">
                    {data.purchasers?.map(purchaser => purchaser.owner.cnic).join(' / ')}
                </Descriptions.Item>
                <Descriptions.Item label="Land Size">
                    {data?.fileId?.landSize} {data?.fileId?.landUnit}
                </Descriptions.Item>
                <Descriptions.Item label="Plot No">{data?.fileId?.plotNo}</Descriptions.Item>
            </Descriptions>
        );
    };

    return (
        <div style={{ height: 400, width: '100%' }}>
            <Input
                placeholder="Search by seller, purchaser, transfer no, or status"
                allowClear
                onChange={e => setSearchText(e.target.value)}
                style={{ marginBottom: '16px', width: '300px' }}
            />

            <DataGrid
                rows={filteredData}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
            />

            {visible && (
                <TransferManage
                    account={account}
                    visible={visible}
                    selectedMembership={selected}
                    onClose={() => setVisible(false)}
                />
            )}

            <Modal
                title="Transfer Details"
                visible={detailsVisible}
                onCancel={() => setDetailsVisible(false)}
                footer={null}
                width={800}
            >
                {detailsRow && renderDetails(detailsRow)}
            </Modal>

        </div>
    );
};

export default FileTransfer;
