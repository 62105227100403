import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getJournalEntries, postJournalEntry, getTrialBalance, removeJournalEntry } from "./journalService";

// Fetch journal entries
export const fetchJournalEntries = createAsyncThunk("journal/fetchJournalEntries", async () => {
    return await getJournalEntries();
});
export const fetchTrialBalance = createAsyncThunk("trialBalance/fetchJournalEntries", async () => {
    return await getTrialBalance();
});


// Create a journal entry
export const createJournalEntry = createAsyncThunk("journal/createJournalEntry", async (entryData) => {
    return await postJournalEntry(entryData);
});

// Delete a journal entry
export const deleteJournalEntry = createAsyncThunk("journal/deleteJournalEntry", async (id) => {
    return await removeJournalEntry(id);
});

const journalSlice = createSlice({
    name: "journal",
    initialState: {
        entries: [],
        trialBalance: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchJournalEntries.pending, (state) => { state.loading = true; })
            .addCase(fetchJournalEntries.fulfilled, (state, action) => {
                state.loading = false;
                state.entries = action.payload;
            })
            .addCase(fetchJournalEntries.rejected, (state, action) => {
                state.loading = false;
                state.entries = [];
                state.error = action.error.message;
            })
            .addCase(fetchTrialBalance.pending, (state) => { state.loading = true; })
            .addCase(fetchTrialBalance.fulfilled, (state, action) => {
                state.loading = false;
                state.trialBalance = action.payload;
            })
            .addCase(fetchTrialBalance.rejected, (state, action) => {
                state.loading = false;
                state.trialBalance = [];

                state.error = action.error.message;
            })
            .addCase(createJournalEntry.fulfilled, (state, action) => {
                state.entries.push(action.payload);
            })
            .addCase(deleteJournalEntry.fulfilled, (state, action) => {
                state.entries = state.entries.filter(entry => entry._id !== action.payload);
            });
    }
});

// ✅ Selectors
export const selectJournalEntries = (state) => state.journal.entries;
export const selectTrialBalance = (state) => state.journal.trialBalance;

export const selectJournalLoading = (state) => state.journal.loading;
export const selectJournalError = (state) => state.journal.error;

export default journalSlice.reducer;
