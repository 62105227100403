import api from "../api";

const API_URL = "/api/projects"; // Update with your backend URL

const getProjects = async () => {
    const response = await api.get(API_URL);
    return response.data;
};

const createProject = async (projectData) => {
    const response = await api.post(API_URL, projectData);
    return response.data;
};

const deleteProject = async (id) => {
    await api.delete(`${API_URL}/${id}`);
    return { id };
};

const projectService = {
    getProjects,
    createProject,
    deleteProject,
};

export default projectService;
