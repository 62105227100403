import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accountServices } from "./accountService";
export const importChargesData = createAsyncThunk(
    "account/import",
    async (data, { rejectWithValue }) => {
        try {
            return await accountServices.importCharges(data);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.error : error.message);
        }
    }
);

const accountSlice = createSlice({
    name: "account",
    initialState: {
        charges: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(importChargesData.pending, (state) => {
                state.loading = true;
            })
            .addCase(importChargesData.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(importChargesData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
    },
});


export const selectcharges = (state) => state.accountSlice.charges;
export const selectLoading = (state) => state.accountSlice.loading;
export const selectError = (state) => state.accountSlice.error;

export default accountSlice.reducer;
