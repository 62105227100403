// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fdfdfd;
}

.reset-password-box {
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.reset-password-title {
  text-align: center;
  margin-bottom: 24px;
}

.reset-password-button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/ResetPassword.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;EACE,oCAAoC;EACpC,aAAa;EACb,kBAAkB;EAClB,yCAAyC;EACzC,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".reset-password-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #fdfdfd;\n}\n\n.reset-password-box {\n  background: rgba(255, 255, 255, 0.9);\n  padding: 40px;\n  border-radius: 8px;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n  max-width: 400px;\n  width: 100%;\n}\n\n.reset-password-title {\n  text-align: center;\n  margin-bottom: 24px;\n}\n\n.reset-password-button {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
