import axios from "axios";
import api from '../api'

const API_URL = "/api/company";


export const companyService = {
    async getAllCompanies({ query = "", limit = 20, start = 0 }) {
        try {
            console.log('query, limit, start', query, limit, start);
            const response = await api.get(`${API_URL}?query=${query}&limit=${limit}&start=${start}`);
            return response.data; // This will be returned in case of success
        } catch (error) {
            // Log the error to inspect its structure
            console.error('Error fetching companies:', error);
            throw error; // Rethrow the error so it can be handled by the calling function
        }
    }
    ,
    async getCompaniesByName({ }) {
        try {
            const response = await api.get(`${API_URL}/user/id`);
            return response.data;
        } catch (error) {
            console.error('Error fetching companies:', error);
            throw error;
        }
    },
    async exportCompanies(query) {
        let format = query;

        try {
            const response = await api.get(`${API_URL}/export`, {
                params: { query: format }, 
            });
            if (format === 'pdf') {
                const pdfUrl = api.getUri() + "/api" + response.data.url;
                const link = document.createElement("a");
                link.href = pdfUrl;
                const timestamp = new Date();
                const fileName = `Companies_${timestamp}.pdf`;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else if (format === 'excel') {
                const pdfUrl = api.getUri() + "/api/uploads/" + response.data.url;
                const link = document.createElement("a");
                link.href = pdfUrl;
                const timestamp = new Date();
                const fileName = `Companies_${timestamp}.xlsx`;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error(`Error exporting companies (${format}):`, error);
            alert(`An error occurred while exporting the companies as ${format}.`);
        }

    },



    async importCompany(companyData) {
        const response = await api.post(API_URL + "/import", companyData);
        return response.data;
    },

    async createCompany(companyData) {
        const response = await api.post(API_URL, companyData);
        return response.data;
    },
    async updateCompany(id, updatedData) {
        const response = await api.put(`${API_URL}/${id}`, updatedData);
        return response.data;
    },
    async deleteCompany(id) {
        const response = await api.delete(`${API_URL}/${id}`);
        return response.data;
    },
    async handlePrint() {
        try {
            const response = await api.get("/api/company/print", {
                responseType: "blob", // Ensure binary data is correctly handled
                headers: {
                    "Content-Type": "application/pdf",
                },
            });

            const blob = new Blob([response.data], { type: "application/pdf" });
            const pdfUrl = URL.createObjectURL(blob); // Create a temporary URL

            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src = pdfUrl;
            document.body.appendChild(iframe);

            iframe.onload = () => {
                iframe.contentWindow.print(); // Trigger print
            };
        } catch (error) {
            console.error("Error fetching PDF:", error);
            alert("Failed to fetch or print PDF.");
        }
    }
};
