import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import supplierService from "./supplierService";

// Async thunk for fetching suppliers
export const fetchSuppliers = createAsyncThunk(
    "supplier/fetchSuppliers",
    async (_, thunkAPI) => {
        try {
            return await supplierService.getSuppliers();
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// Async thunk for adding a supplier
export const addSupplier = createAsyncThunk(
    "supplier/addSupplier",
    async (supplierData, thunkAPI) => {
        try {
            return await supplierService.addSupplier(supplierData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// Async thunk for deleting a supplier
export const deleteSupplier = createAsyncThunk(
    "supplier/deleteSupplier",
    async (supplierId, thunkAPI) => {
        try {
            return await supplierService.deleteSupplier(supplierId);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// Supplier slice
const supplierSlice = createSlice({
    name: "supplier",
    initialState: {
        suppliers: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSuppliers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSuppliers.fulfilled, (state, action) => {
                state.loading = false;
                state.suppliers = action.payload;
            })
            .addCase(fetchSuppliers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addSupplier.fulfilled, (state, action) => {
                state.suppliers.push(action.payload.supplier);
            })
            .addCase(deleteSupplier.fulfilled, (state, action) => {
                state.suppliers = state.suppliers.filter(supp => supp._id !== action.meta.arg);
            });
    },
});

// Selectors
export const selectSuppliers = (state) => state.supplier.suppliers;
export const selectSupplierLoading = (state) => state.supplier.loading;
export const selectSupplierError = (state) => state.supplier.error;

export default supplierSlice.reducer;
