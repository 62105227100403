// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fdfdfd;
  }
  
  .signup-box {
    background: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 480px;
    width: 100%;
  }
  
  .signup-title {
    text-align: center;
    margin-bottom: 24px;
  }
  
  .signup-button {
    width: 100%;
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/SignupPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,oCAAoC;IACpC,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb","sourcesContent":[".signup-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #fdfdfd;\n  }\n  \n  .signup-box {\n    background: rgba(255, 255, 255, 0.9);\n    padding: 40px;\n    border-radius: 8px;\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n    max-width: 480px;\n    width: 100%;\n  }\n  \n  .signup-title {\n    text-align: center;\n    margin-bottom: 24px;\n  }\n  \n  .signup-button {\n    width: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
