import { CloseOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, InputNumber, notification, Row, Select, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlocks, fetchCategories, fetchPhases, fetchProperties, fetchResidentials, fetchStatus, selectBlocks, selectCategories, selectPhases, selectProperties, selectResidentials, selectStatus } from '../app/Service/DataSlice';
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import ModalPhase from './ModalPhase';
import ModalCategory from './ModalCategory';
import ModalBlock from './ModalBlock';
import ModalProperty from './ModalProperty';
import ModalStatus from './ModalStatus';
import ModalResidential from './ModalResidential';
import { useForm } from 'antd/es/form/Form';
import { addplot } from '../app/Service/plotSlice';

function CreateFile({ key }) {
    const dispatch = useDispatch()
    const [form] = useForm()
    const [openPhase, setOpenPhase] = useState(false);
    const [OpenBlock, setOpenBlock] = useState(false);
    const [OpenResidential, setOpenResidential] = useState(false);
    const [OpenStatus, setOpenStatus] = useState(false);
    const [OpenProperty, setOpenProperty] = useState(false);
    const [OpenCategory, setOpenCategory] = useState(false);
    const company_name = useSelector(selectcompanies_name);
    const [company, setCompany] = useState(null);
    const Phases = useSelector(selectPhases)
    const Categories = useSelector(selectCategories)
    const Status = useSelector(selectStatus)
    const Residentials = useSelector(selectResidentials)
    const Properties = useSelector(selectProperties)
    const [block, setBlock] = useState()
    const Blocks = useSelector(selectBlocks)


    useEffect(() => {
        
            dispatch(getCompaniesByName({}));

        
    }, [dispatch]);


    useEffect(() => {
        if (company_name.length > 0 ) {
            const defaultCompanyId = company_name[0]?._id;
            form.setFieldsValue({ Company: defaultCompanyId })
            setCompany(defaultCompanyId);
            dispatch(fetchPhases({ modelName: "Phase", company_id: defaultCompanyId }));
            dispatch(fetchCategories({ modelName: "Category", company_id: defaultCompanyId }));
            dispatch(fetchResidentials({ modelName: "Residential", company_id: defaultCompanyId }));
            dispatch(fetchProperties({ modelName: "Property", company_id: defaultCompanyId }));
            dispatch(fetchStatus({ modelName: "Status", company_id: defaultCompanyId }));
            dispatch(fetchBlocks({ modelName: "Block", company_id: defaultCompanyId }));
        }
    }, [company_name, dispatch]);



    const handleCompanyChange = (value) => {
        setCompany(value);
        dispatch(fetchPhases({ modelName: "Phase", company_id: value }));
        dispatch(fetchCategories({ modelName: "Category", company_id: value }));
        dispatch(fetchResidentials({ modelName: "Residential", company_id: value }));
        dispatch(fetchProperties({ modelName: "Property", company_id: value }));
        dispatch(fetchStatus({ modelName: "Status", company_id: value }));
        dispatch(fetchBlocks({ modelName: "Block", company_id: value, Phase: fetchBlocks }));

    };


    const handlePhaseChange = (value) => {
        const block = Blocks?.data?.filter((dta) => dta.Phase_id?._id === value)
        setBlock(block)
        form.resetFields(['Block'])
    }
    const handleFinish = async (values) => {
        console.log(values)
        const res = await dispatch(addplot(values))
        if (res.meta.requestStatus === "fulfilled") {
            notification.success({
                message: "Success",
                description: res.payload?.message || "Plot added successfully!",
            });
            form.resetFields()
        } else {
            notification.error({
                message: "Error",
                description: res.payload?.error || "Failed to add plot.",
            });
        }
    }

    const calculateLandSize = (Unit) => {




        const values = form.getFieldsValue();
        const { Length, Width } = values;
        if (!Length || !Width || !Unit) return;

        const length = Length;
        const width = Width;
        let sizeInSquareFeet = length * width;
        switch (Unit) {
            case "Square Yards":
                sizeInSquareFeet = sizeInSquareFeet / 9; // 1 sq. yard = 9 sq. feet
                break;
            case "Marla":
                sizeInSquareFeet = sizeInSquareFeet / 272.25; // 1 Marla = 272.25 sq. feet
                break;
            case "Kanal":
                sizeInSquareFeet = sizeInSquareFeet / 5445; // 1 Kanal = 5445 sq. feet
                break;
            case "Acre":
                sizeInSquareFeet = sizeInSquareFeet / 43560; // 1 Acre = 43560 sq. feet
                break;
            case "Hectare":
                sizeInSquareFeet = sizeInSquareFeet / 107639; // 1 Hectare = 107639 sq. feet
                break;
            default:
                break; // Default is square feet, so no change needed
        }
        form.setFieldsValue({ Land_Size: sizeInSquareFeet.toFixed(2) + " " + Unit }); // Update Land Size
    };

    return (


        <>
            <ModalPhase openPhase={openPhase} setOpenPhase={setOpenPhase} />
            <ModalCategory OpenCategory={OpenCategory} setOpenCategory={setOpenCategory} />

            <ModalResidential OpenResidential={OpenResidential} setOpenResidential={setOpenResidential} />

            <ModalStatus OpenStatus={OpenStatus} setOpenStatus={setOpenStatus} />

            <ModalBlock OpenBlock={OpenBlock} setOpenBlock={setOpenBlock} />
            <ModalProperty OpenProperty={OpenProperty} setOpenProperty={setOpenProperty} />



            <Form layout='vertical'
                name="dynamic_form_complex"
                autoComplete="off"
                initialValues={{ items: [{}] }}
                form={form}
                onFinish={handleFinish}
            >
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Card title="Membership Details" extra={<><></></>}>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        label="Select Company"
                                        name="Company"
                                        rules={[{ required: true, message: 'Please select a phase!' }]}
                                    >
                                        <Select
                                            value={company}
                                            placeholder="Select Company"
                                            options={company_name?.map((dta) => ({ label: dta.companyName, value: dta._id }))}
                                            onChange={handleCompanyChange}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>

                                    <Space.Compact style={{ width: '100%' }}>
                                        <Form.Item
                                            label="Select Phase"
                                            name="Phase"
                                            rules={[{ required: true, message: 'Please select a phase!' }]}
                                            style={{ width: '100%' }}
                                        >
                                            <Select onChange={handlePhaseChange} placeholder="Select Phase" options={Phases?.data?.map((dta) => ({ label: dta.Phase, value: dta._id }))}>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label=" "
                                        >
                                            <Button type="primary" onClick={() => setOpenPhase(true)}>
                                                <PlusOutlined />
                                            </Button>
                                        </Form.Item>

                                    </Space.Compact>

                                </Col>
                                <Col span={8}>

                                    <Space.Compact style={{ width: '100%' }}>
                                        <Form.Item label="Select Block" name={"Block"} rules={[{ required: true, message: 'Please select a Block!' }]} style={{ width: '100%' }}>
                                            <Select disabled={!block?.length} placeholder="Select Block" options={block?.map((dta) => ({ label: dta.Block, value: dta._id }))}>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label=" " name={"Block"} >

                                            <Button type="primary" onClick={() => setOpenBlock(true)}>
                                                <PlusOutlined />
                                            </Button>
                                        </Form.Item>
                                    </Space.Compact>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Plot #" name={"Plot_No"} rules={[{ required: true, message: 'Please Enter a plot #' }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Select Purpose" name={"Purpose"}>
                                        <Select placeholder="Select Purpose">
                                            <Select.Option value="Sell">Sell </Select.Option>
                                            <Select.Option value="Rent">Rent</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>

                                    <Space.Compact style={{ width: '100%' }}>
                                        <Form.Item style={{ width: '100%' }} label="Residential Type" name={"Residential_Type"} >
                                            <Select placeholder="Residential Type" options={Residentials?.data?.map((dta) => ({ label: dta.Residential, value: dta._id }))}>

                                            </Select>
                                        </Form.Item>
                                        <Form.Item label=" "  >

                                            <Button type="primary" onClick={() => setOpenResidential(true)}>
                                                <PlusOutlined />
                                            </Button>
                                        </Form.Item>

                                    </Space.Compact>
                                </Col>
                                <Col span={12}>
                                    <Space.Compact style={{ width: '100%' }}>
                                        <Form.Item style={{ width: '100%' }} label="Select Property Type" name={"Property_Type"}>

                                            <Select placeholder="Select Property Type" options={Properties?.data?.map((dta) => ({ label: dta.Property, value: dta._id }))}>

                                            </Select>
                                        </Form.Item>
                                        <Form.Item label=" ">

                                            <Button type="primary" onClick={() => setOpenProperty(true)}>
                                                <PlusOutlined />
                                            </Button>
                                        </Form.Item>

                                    </Space.Compact>
                                </Col>
                                <Col span={12}>
                                    <Space.Compact style={{ width: '100%' }}>
                                        <Form.Item label="Category" name={"Category"} style={{ width: '100%' }}>

                                            <Select placeholder="Select Category" options={Categories?.data?.map((dta) => ({ label: dta.Category, value: dta._id }))}>

                                            </Select>
                                        </Form.Item>
                                        <Form.Item label=" "  >
                                            <Button type="primary" onClick={() => setOpenCategory(true)}>
                                                <PlusOutlined />
                                            </Button>
                                        </Form.Item>

                                    </Space.Compact>
                                </Col>
                                <Col span={12}>
                                    <Space.Compact style={{ width: '100%' }}>
                                        <Form.Item label="Status" name={"Status"} style={{ width: '100%' }}>

                                            <Select mode={"multiple"} placeholder="Select Status" options={Status?.data?.map((dta) => ({ label: dta.Status, value: dta._id }))}>

                                            </Select>
                                        </Form.Item>
                                        <Form.Item label=" " >

                                            <Button type="primary" onClick={() => setOpenStatus(true)}>
                                                <PlusOutlined />
                                            </Button>
                                        </Form.Item>
                                    </Space.Compact>

                                </Col>


                            </Row>
                        </Card></Col>
                    <Col span={24}>
                        <Card title="Land Location and Area">
                            <Row gutter={16}>
                                <Col span={4}>
                                    <Form.Item
                                        label="Covered Area"
                                        rules={[{ required: true, message: 'Covered Area is required' }]}
                                    >
                                        <Space.Compact style={{ width: "100%" }}>
                                            <Form.Item
                                                noStyle
                                                name={'Length'}
                                                rules={[{ required: true, message: 'Length is required' }]}
                                                style={{ marginBottom: 0 }}
                                            >
                                                <InputNumber min={0}
                                                    placeholder="Length"
                                                    onChange={() => {
                                                        form.resetFields(["Unit", "Land_Size"])
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                noStyle

                                                name={'Width'}
                                                rules={[{ required: true, message: 'Width is required' }]}
                                            >
                                                <InputNumber min={0}
                                                    placeholder="Width"
                                                    onChange={() => {
                                                        form.resetFields(["Unit", "Land_Size"])
                                                    }}
                                                />
                                            </Form.Item>
                                        </Space.Compact>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="Unit" name={"Unit"} rules={[{ required: true, message: 'Please Select Unit!' }]}>
                                        <Select placeholder="Select Unit" onChange={calculateLandSize}>
                                            <Select.Option value="Square Feet">Square Feet</Select.Option>
                                            <Select.Option value="Square Yards">Square Yards</Select.Option>
                                            <Select.Option value="Marla">Marla</Select.Option>
                                            <Select.Option value="Kanal">Kanal</Select.Option>
                                            <Select.Option value="Acre">Acre</Select.Option>
                                            <Select.Option value="Hectare">Hectare</Select.Option>
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Land Size" name={"Land_Size"} rules={[{ required: true, message: 'Please Enter Land Size!' }]}>
                                        <Input readOnly />
                                    </Form.Item>
                                </Col>





                                <Col span={12}>
                                    <Form.Item label="City" name={"City"} >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Street" name={"Street"}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Address" name={"Address"}>
                                        <TextArea rows={4} />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Card></Col>


                    <Col span={24}>
                        <Card>
                            <Space>
                                <Button color="default" variant="solid">
                                    Reset File
                                </Button>
                                <Button type='primary' htmlType='submit'>
                                    Save File
                                </Button>
                            </Space>
                        </Card>
                    </Col>
                </Row>
            </Form >

        </>

    )
}

export default CreateFile