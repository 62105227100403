import axios from "axios";
import api from "../api";

const API_URL = "/api/journal-entries";

// Fetch all journal entries
export const getJournalEntries = async () => {
    const response = await api.get(API_URL);
    return response.data;
};
export const getTrialBalance = async () => {
    const response = await api.get("/api/journal-entries/trial-balance");
    return response.data;
};


// Create a new journal entry
export const postJournalEntry = async (entryData) => {
    const response = await api.post(API_URL, entryData);
    return response.data;
};

// Delete a journal entry
export const removeJournalEntry = async (id) => {
    await api.delete(`${API_URL}/${id}`);
    return id;
};
