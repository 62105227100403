import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Col, Row, QRCode, Typography, Flex, Form, Input, Select, DatePicker, Button, Space, Divider, Card } from "antd";
import "./ReportDesign.css";
import AssignFile from "../Pages/AssignFile";
import SearchFile from "../Pages/SearchFile";
const { Title, Text } = Typography;
const { Option } = Select
const TransferReports = () => {
    const contentRef = useRef(null);

    const reactToPrintFn = useReactToPrint({
        contentRef,
        documentTitle: "Application Report",
        pageStyle: `
            @page { size: A4; margin: 2mm; }
            body { font-family: Arial, sans-serif; }
            .print-container { width: 100%; background: #fff; padding: 20px; }
            .center-text { text-align: center; }
            .info-section { border-bottom: padding: 10px 0; }
        `,
    });

    return (
        <>
            <Space >
                <Select style={{ width: "300px" }} placeholder={"Select Form"}
                    options={[{ label: "Application From", value: "Application Form" }]}>
                </Select>
                <Button type={"primary"} onClick={reactToPrintFn}>Print Report</Button>
            </Space>
            <Divider />
            <Row justify={"space-between"} gutter={[8, 8]}>
                <Col md={24} lg={12} >
                    {/* <SearchFile /> */}
                    <AssignFile />
                </Col>
                <Col md={24} lg={12} >

                    <div className="scale-contant">
                        <div className="" style={{ width: "750px" }} >
                            <div ref={contentRef}   >
                                <div className="print-container">

                                    <div className="Header_Section">
                                        <div className="header-logo">
                                            <img src="https://marketplace.canva.com/EAF6nmbUlhg/1/0/1600w/canva-black-and-gold-flat-illustrative-real-estate-logo-Jj0rP4nw9ug.jpg" alt="Company Logo" />
                                        </div>
                                        <div className="company-info">
                                            <Title level={3}>Islamabad Cooperative Housing Society</Title>
                                            <Text style={{ fontSize: "19px" }}>Application Form</Text>
                                            {/* <p>Apply for property ownership and membership benefits.</p> */}
                                        </div>
                                        <div className="contact-info">
                                            <p> 12-023-2323</p>
                                            <p> 456-789-123</p>
                                            <p>ICHS-F-1028237</p>
                                        </div>
                                    </div>

                                    {/* Owner Information */}
                                    <p style={{ margin: "10px 0px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Vestibulum auctor libero eu justo vulputate, nec efficitur erat efficitur. Curabitur ac turpis et lacus gravida malesuada at id eros. Vestibulum auctor libero eu justo vulputate, nec efficitur erat efficitur. Curabitur ac turpis et lacus gravida  alesuada at id eros. Vestibulum auctor libero eu justo vulputate, nec efficitur erat efficitur. Curabitur ac tu</p>
                                    <div className="info-section owner-details">
                                        <Title level={5} style={{ textAlign: "left" }} className="section-title">Owner Details</Title>
                                        <div className="details-grid">
                                            <div className="detail-item">
                                                <strong>Name:</strong>
                                                <span>Asad Tariq Saddiqui</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Father's / Husband's Name:</strong>
                                                <span>Tariq Saddiqui</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>CNIC:</strong>
                                                <span>82103-3245212-7</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Phone:</strong>
                                                <span>+92-300-1234567</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Address:</strong>
                                                <span>House #12, Street 5, Islamabad</span>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Plot Information */}
                                    <div className="info-section plot-details">
                                        <Title level={5} className="section-title" style={{ textAlign: "left" }}> Plot Information</Title>
                                        <div className="details-grid">
                                            <div className="detail-item">
                                                <strong>Plot Number:</strong>
                                                <span>45-A</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Plot Size:</strong>
                                                <span>1 Kanal</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Sector:</strong>
                                                <span>B-Block</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Price:</strong>
                                                <span>PKR 10,000,000</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Transfer Date:</strong>
                                                <span>01-20-2026</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Ownership Type:</strong>
                                                <span>Freehold</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Property Status:</strong>
                                                <span>Registered</span>
                                            </div>
                                            <div className="detail-item">
                                                <strong>Development Status:</strong>
                                                <span>Fully Developed</span>
                                            </div>
                                            {/* <div className="detail-item">
                                            <strong>Utilities Available:</strong>
                                            <span>Electricity, Gas, Water</span>
                                        </div> */}
                                        </div>
                                    </div>


                                    <div className="info-section witness-nominee">
                                        <div className="grid-container">
                                            {/* Witness Information */}
                                            <div className="grid-item">
                                                <Title level={5} style={{ textAlign: "left" }}>👨‍⚖️ Witness Information</Title>
                                                <div className="detail-item">
                                                    <strong> Name:</strong>
                                                    <span>Ayesha Tariq</span>
                                                </div>
                                                <div className="detail-item">
                                                    <strong>CNIC:</strong>
                                                    <span>83291-1212122-2</span>
                                                </div>
                                                <div className="detail-item">
                                                    <strong> Name:</strong>
                                                    <span>Ayesha Tariq</span>
                                                </div>
                                                <div className="detail-item">
                                                    <strong>CNIC:</strong>
                                                    <span>83291-1212122-2</span>
                                                </div>

                                                <div className="detail-item Sign">
                                                    <div>
                                                        Sign :
                                                    </div>
                                                    <div className="box">

                                                    </div>
                                                    <div className="box">

                                                    </div>

                                                </div>

                                            </div>

                                            {/* Nominee Information */}
                                            <div className="grid-item">
                                                <Title level={5}>👩‍👧 Nominee Details</Title>
                                                <div className="detail-item">
                                                    <strong> Name:</strong>
                                                    <span>Ayesha Tariq / Afaq Tariq </span>
                                                </div>
                                                <div className="detail-item">
                                                    <strong>Relation:</strong>
                                                    <span>Daughter / Son</span>
                                                </div>
                                                <div className="detail-item">
                                                    <strong>CNIC:</strong>
                                                    <span>82103-3145212-7/ 82103-3145212-7</span>
                                                </div>
                                                <div className="detail-item">
                                                    <strong>Phone:</strong>
                                                    <span>82103-3145212-7/82103-3145212-7</span>
                                                </div>
                                                <div className="detail-item Sign">
                                                    <div>
                                                        Sign :
                                                    </div>
                                                    <div className="box1">

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer-section">
                                        <Row gutter={16} align="middle" justify="space-between">

                                            <Col span={6} className="qr-code-container">
                                                <QRCode
                                                    value="https://ant.design/"
                                                    color={"black"}
                                                    bgColor={"white"}
                                                    bordered={false}
                                                    size={100}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <p>Address: Office #123, Main Boulevard, Islamabad</p></Col>

                                            <Col span={6} className="company-info">
                                                <p>Website: www.ichs.com</p>
                                                <p>Email: info@ichs.com</p>

                                            </Col>
                                        </Row>
                                    </div>

                                </div>

                            </div>

                        </div>
                        
                    </div>

                </Col>

            </Row >


        </>
    );
};

export default TransferReports;
