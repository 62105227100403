import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { plotServices } from "./plotService";
export const fetchplot = createAsyncThunk(
    "plot/fetchAll",
    async ({ id, query, limit, start }, { rejectWithValue }) => {
        try {
            return await plotServices.getAllplot({ id, query, limit, start });
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.error : error.message);
        }
    }
);
export const getplotByName = createAsyncThunk(
    "plot/name",
    async ({ }, { rejectWithValue }) => {
        try {
            return await plotServices.getplotByName({});
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.error : error.message);
        }
    }
);
export const handlePrint = createAsyncThunk(
    "plot/print",
    async (id, { rejectWithValue }) => {
        try {
            return await plotServices.handlePrint(id);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);
export const exportplot = createAsyncThunk(
    "plot/export",
    async (query, { rejectWithValue }) => {
        try {
            return await plotServices.exportplot(query);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);
export const addplot = createAsyncThunk(
    "plot/add",
    async (data, { rejectWithValue }) => {
        try {
            return await plotServices.createplot(data);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);
export const impotplot = createAsyncThunk(
    "plot/import",
    async (data, { rejectWithValue }) => {
        try {
            return await plotServices.importplot(data);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

export const updateplot = createAsyncThunk(
    "plot/update",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await plotServices.updateplot(id, data);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);
export const deleteplot = createAsyncThunk(
    "plot/delete",
    async (id, { rejectWithValue }) => {
        try {
            await plotServices.deleteplot(id);
            return id;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

const plotSlice = createSlice({
    name: "plot",
    initialState: {
        plot: [],
        loading: false,
        error: null,
        importLoading: false,
        importError: null,
        importSuccess: false,
        plot_name: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchplot.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchplot.fulfilled, (state, action) => {
                state.loading = false;
                state.plot = action.payload;
            })
            .addCase(fetchplot.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })

            .addCase(getplotByName.pending, (state) => {
                state.loading = true;
            })
            .addCase(getplotByName.fulfilled, (state, action) => {
                state.loading = false;
                state.plot_name = action.payload;
            })
            .addCase(getplotByName.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(addplot.fulfilled, (state, action) => {

            })
            .addCase(updateplot.fulfilled, (state, action) => {

            })
            .addCase(deleteplot.fulfilled, (state, action) => {

            })
            .addCase(impotplot.pending, (state) => {
                state.importLoading = true;
                state.importSuccess = false;
                state.importError = null;
            })
            .addCase(impotplot.fulfilled, (state, action) => {
                state.importLoading = false;
                state.importSuccess = true;

            })
            .addCase(impotplot.rejected, (state, action) => {
                state.importLoading = false;
                state.importError = action.error.message;
            });
    },
});

export const selectplot = (state) => state.plotSlice.plot;
export const selectplot_name = (state) => state.plotSlice.plot_name;
export const selectLoading = (state) => state.plotSlice.loading;
export const selectError = (state) => state.plotSlice.error;
export const selectImportLoading = (state) => state.plotSlice.importLoading;
export const selectImportError = (state) => state.plotSlice.importError;
export const selectImportSuccess = (state) => state.plotSlice.importSuccess;

export default plotSlice.reducer;
