import { Button, Card, Col, Form, Input, notification, Row, Select, Upload, Modal, DatePicker } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanies, getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { uploadSingleFile } from '../app/Service/fileSlice';
import { addUser, fetchusers, updateUser } from '../app/Service/userSlice';
import { fetchDepartments, selectDepartment } from '../app/Service/departmentSlice';
import Swal from 'sweetalert2';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';

function EmployeeModal({ visible, onClose, employeeData, SelectedCom }) {
    const [file, setFile] = useState(null);
    const [form] = useForm();
    const dispatch = useDispatch();

    const company_name = useSelector(selectcompanies_name);
    const Department = useSelector(selectDepartment);

    useEffect(() => {
        dispatch(getCompaniesByName({}));
        if (employeeData) {
            form.setFieldsValue({
                ...employeeData,
                dateOfBirth: employeeData?.dateOfBirth ? dayjs(employeeData?.dateOfBirth) : null
            });

            form.setFieldsValue({ dept_id: employeeData?.dept_id?._id });
            form.setFieldsValue({ role: employeeData?.employee_id?.role });
            form.setFieldsValue({ role: employeeData?.employee_id?.role });

            dispatch(fetchDepartments({ id: employeeData?.company_id }));
        }
    }, [employeeData, dispatch, form]);
    console.log('selectedEmployee', employeeData)

    const handleFinish = async (values) => {
        values.image = file;

        try {
            let res;
            if (employeeData) {
                res = await dispatch(updateUser({ id: employeeData?._id, updatedData: values }));
            } else {
                res = await dispatch(addUser(values));
            }

            if (res.meta.requestStatus === 'fulfilled') {
                Swal.fire({
                    title: 'Success!',
                    text: `User has been ${employeeData ? 'updated' : 'added'} successfully.`,
                    icon: 'success',
                    confirmButtonText: 'OK'
                });

                dispatch(fetchusers({ id: SelectedCom, query: "", limit: 9087654321, start: 0 }));
                form.resetFields()
                // onClose();
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: res?.payload?.error || 'There was an error processing the request.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Unexpected Error!',
                text: 'An unexpected error occurred.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };


    const handelUploadFile = async ({ file }) => {
        try {
            const res = await dispatch(uploadSingleFile(file));
            if (res.meta.requestStatus === "fulfilled") {
                setFile(res?.payload.file);
                notification.success({ message: 'File Uploaded Successfully' });
            } else {
                notification.error({ message: 'File Upload Failed' });
            }
        } catch (error) {
            notification.error({ message: 'File Upload Failed' });
        }
    };

    const handleCompanyChange = (e) => {
        const company = company_name?.find(item => item.companyName === e);
        if (company) {
            dispatch(fetchDepartments({ id: company?._id }));
        }
    };

    return (
        <Modal style={{ top: 20 }} destroyOnClose={true} maskClosable={false} title={employeeData ? "Update Employee" : "Add Employee"} open={visible} onCancel={() => {
            form.resetFields()
            onClose();
        }} footer={null} width={800}
        >
            <Form layout="vertical" form={form} onFinish={handleFinish}>
                <Row gutter={16}>
                    <Col span={12}><Form.Item label="Username" name="name"><Input /></Form.Item></Col>
                    <Col span={12}>
                        <Form.Item label="Email" name="user_email" rules={[{ required: true }]}>
                            <Input disabled={!!employeeData} readOnly={!!employeeData} />
                        </Form.Item>
                    </Col>
                    <Col span={12}><Form.Item label="First Name" name="firstName"><Input /></Form.Item></Col>
                    <Col span={12}><Form.Item label="Last Name" name="lastName"><Input /></Form.Item></Col>
                    <Col span={12}><Form.Item label="Phone" name="phone"><Input /></Form.Item></Col>
                    <Col span={12}><Form.Item label="Date Of Birth" name="dateOfBirth"><DatePicker style={{ width: "100%" }} /></Form.Item></Col>
                    <Col span={12}>
                        <Form.Item label="Company" name="company_name" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                allowClear
                                disabled={!!employeeData}
                                options={company_name?.map(com => ({ label: com.companyName, value: com.companyName }))}
                                onChange={handleCompanyChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} >
                        <Form.Item label="Department" name="dept_id" rules={[{ required: false }]}>
                            <Select showSearch allowClear options={Department?.department?.map(dept => ({ label: dept.department, value: dept._id }))} disabled={!Department?.department?.length} />
                        </Form.Item>
                    </Col>
                    <Col span={24}><Form.Item label="Address" name="address"><TextArea rows={2} /></Form.Item></Col>
                    <Col span={12}>
                        <Form.Item label="Gender" name="gender">
                            <Select>
                                <Select.Option value="Male">Male</Select.Option>
                                <Select.Option value="Female">Female</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Password" name="password">
                            <Input.Password disabled={!!employeeData} placeholder={employeeData ? "Password cannot be changed" : ""} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Role" name="role">
                            <Select showSearch mode={"multiple"} allowClear>
                                <Select.Option value="SUBADMIN">SUBADMIN</Select.Option>
                                <Select.Option value="HR">HR</Select.Option>
                                <Select.Option value="ACCOUNTANT">ACCOUNTANT</Select.Option>
                                <Select.Option value="AGENT">AGENT</Select.Option>
                                <Select.Option value="EMPLOYEE">EMPLOYEE</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Attached File (Optional)" name="attachment">
                            <Upload.Dragger showUploadList={false} accept=".png,.jpg,.jpeg" beforeUpload={() => false} onChange={handelUploadFile}>
                                Upload File
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Button onClick={onClose} style={{ margin: 20 }}>Cancel</Button>
                        <Button type="primary" htmlType="submit">{employeeData ? "Update Employee" : "Add Employee"}</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default EmployeeModal;
