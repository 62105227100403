import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import accountService from "./accountsService";

// Async thunk for fetching accounts
export const fetchAccounts = createAsyncThunk(
    "account/fetchAccounts",
    async (_, thunkAPI) => {
        try {
            const response = await accountService.getAccounts();
            if (response.error) {
                return thunkAPI.rejectWithValue(response.error);
            }
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Async thunk for adding an account
export const addAccount = createAsyncThunk(
    "account/addAccount",
    async (accountData, thunkAPI) => {
        try {
            const response = await accountService.addAccount(accountData);
            if (response.error) {
                return thunkAPI.rejectWithValue(response.error);
            }
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Async thunk for deleting an account
export const deleteAccount = createAsyncThunk(
    "account/deleteAccount",
    async (accountId, thunkAPI) => {
        try {
            const response = await accountService.deleteAccount(accountId);
            if (response.error) {
                return thunkAPI.rejectWithValue(response.error);
            }
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Account slice
const accountSlice = createSlice({
    name: "account",
    initialState: {
        accounts: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccounts.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAccounts.fulfilled, (state, action) => {
                state.loading = false;
                state.accounts = action.payload;
            })
            .addCase(fetchAccounts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addAccount.fulfilled, (state, action) => {
                // state.accounts.push(action.payload.account);
            })
            .addCase(deleteAccount.fulfilled, (state, action) => {
                // state.accounts = state.accounts.filter(acc => acc._id !== action.meta.arg);
            });
    },
});

// ✅ Selectors
export const selectAccounts = (state) => state.account.accounts;
export const selectAccountById = (id) => (state) =>
    state.account.accounts.find(account => account._id === id);
export const selectLoading = (state) => state.account.loading;
export const selectError = (state) => state.account.error;

export default accountSlice.reducer;
