import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dataService from "./DataService";
export const fetchStatus = createAsyncThunk("data/fetchStatus", async (modelName, thunkAPI) => {
    try {
        return await dataService.fetchData(modelName);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
});

export const fetchPhases = createAsyncThunk("data/fetchPhases", async (modelName, thunkAPI) => {
    try {
        return await dataService.fetchData(modelName);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
});

export const fetchBlocks = createAsyncThunk("data/fetchBlocks", async (modelName, thunkAPI) => {
    try {
        return await dataService.fetchData(modelName);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
});

export const fetchResidentials = createAsyncThunk("data/fetchResidentials", async (modelName, thunkAPI) => {
    try {
        return await dataService.fetchData(modelName);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
});

export const fetchProperties = createAsyncThunk("data/fetchProperties", async (modelName, thunkAPI) => {
    try {
        return await dataService.fetchData(modelName);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
});

export const fetchCategories = createAsyncThunk("data/fetchCategories", async (modelName, thunkAPI) => {
    try {
        return await dataService.fetchData(modelName);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
});
export const fetchCharges = createAsyncThunk("data/charges", async (modelName, thunkAPI) => {
    try {
        return await dataService.fetchData(modelName);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
});


export const createData = createAsyncThunk(
    "data/createData",
    async ({ modelName, data }, thunkAPI) => {
        try {
            return await dataService.createData(modelName, data);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Initial State
const initialState = {
    status: [],
    phases: [],
    blocks: [],
    residentials: [],
    properties: [],
    categories: [],
    charges: [],
    loading: false,
    error: null,
};

const dataSlice = createSlice({
    name: "data",
    initialState,
    reducers: {
        resetStatus: (state) => {
            state.status = [];
        },
        resetPhases: (state) => {
            state.phases = [];
        },
        resetBlocks: (state) => {
            state.blocks = [];
        },
        resetResidentials: (state) => {
            state.residentials = [];
        },
        resetProperties: (state) => {
            state.properties = [];
        },
        resetCategories: (state) => {
            state.categories = [];
        },
        resetCharges: (state) => {
            state.charges = [];
        },
        resetLoading: (state) => {
            state.loading = false;
        },
        resetError: (state) => {
            state.error = null;
        },
        resetAll: (state) => {
            return initialState; // Resets all state values
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch Data
            .addCase(fetchStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.status = action.payload;
            })
            .addCase(fetchStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Fetch Phases
            .addCase(fetchPhases.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPhases.fulfilled, (state, action) => {
                state.loading = false;
                state.phases = action.payload;
            })
            .addCase(fetchPhases.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Fetch Blocks
            .addCase(fetchBlocks.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchBlocks.fulfilled, (state, action) => {
                state.loading = false;
                state.blocks = action.payload;
            })
            .addCase(fetchBlocks.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Fetch Residentials
            .addCase(fetchResidentials.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchResidentials.fulfilled, (state, action) => {
                state.loading = false;
                state.residentials = action.payload;
            })
            .addCase(fetchResidentials.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Fetch Properties
            .addCase(fetchProperties.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProperties.fulfilled, (state, action) => {
                state.loading = false;
                state.properties = action.payload;
            })
            .addCase(fetchProperties.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Fetch Categories
            .addCase(fetchCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = action.payload;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchCharges.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCharges.fulfilled, (state, action) => {
                state.loading = false;
                state.charges = action.payload;
            })
            .addCase(fetchCharges.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createData.fulfilled, (state, action) => {
                const modelName = action.meta.arg.modelName;
                if (state[modelName]) {
                    state[modelName].push(action.payload.data);
                }
            })
            .addCase(createData.rejected, (state, action) => {
                state.error = action.payload;
            })
    },
});

// Selectors


export const {
    resetStatus,
    resetPhases,
    resetBlocks,
    resetResidentials,
    resetProperties,
    resetCategories,
    resetLoading,
    resetError,
    resetAll,
    resetCharges,
} = dataSlice.actions;

export const selectStatus = (state) => state.dataSlice.status;
export const selectPhases = (state) => state.dataSlice.phases;
export const selectBlocks = (state) => state.dataSlice.blocks;
export const selectResidentials = (state) => state.dataSlice.residentials;
export const selectProperties = (state) => state.dataSlice.properties;
export const selectCategories = (state) => state.dataSlice.categories;
export const selectCharges = (state) => state.dataSlice.charges;
export const selectLoading = (state) => state.dataSlice.loading;
export const selectError = (state) => state.dataSlice.error;

export default dataSlice.reducer;
