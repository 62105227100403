import { HomeOutlined } from '@ant-design/icons';
import { Avatar, Breadcrumb, Button, Card, Col, Descriptions, Divider, Row, Space, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { fetchOwnerMembership } from '../../App/Slice/membershipSlice';
import { baseurl } from '../../utils/baseUrl';
import dayjs from 'dayjs';
import MembershipGrid from '../../Components/Tabs/OwnerMembership';

function OwnerDetails() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { ownermembership, loading } = useSelector((state) => state.Membership);

    useEffect(() => {
        dispatch(fetchOwnerMembership(id));
    }, [id]);

    if (loading?.ownermembership) {
        return (
            <Spin
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            />
        );
    }

    if (!ownermembership) {
        return (
            <Typography.Text type="danger">
                Unable to load owner details. Please try again later.
            </Typography.Text>
        );
    }

    return (
        <>
            {/* Breadcrumb */}
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/">
                        <HomeOutlined /> Home
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/owners">Owner</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{id}</Breadcrumb.Item>
            </Breadcrumb>
            <Divider />

            {/* Grid Layout */}
            <Row gutter={[16, 16]}>

                <Col xs={24} sm={24} md={8}>
                    <Card>
                        <Row justify="space-between" align="middle">
                            <Col>
                                <Avatar
                                    size={15}
                                    style={{
                                        backgroundColor:
                                            ownermembership?.owner?.status === 'Active' ? 'green' : 'red',
                                    }}
                                />
                            </Col>
                            <Col>
                                <Button type="link" ghost>
                                    Edit
                                </Button>
                            </Col>
                        </Row>
                        <Divider />
                        <Row justify="center">
                            <Avatar
                                src={`${baseurl}/api/assets/${ownermembership?.owner?.profileImage?.filePath}`}
                                size={120}
                            />
                        </Row>
                        {/* <Divider /> */}
                        <Typography.Title level={5} style={{ textAlign: 'center' }}>
                            {ownermembership?.owner?.ownerName}
                        </Typography.Title>
                        <Descriptions column={1} bordered size="small">
                            <Descriptions.Item label="Father's Name">
                                {ownermembership?.owner?.familyName}
                            </Descriptions.Item>
                            <Descriptions.Item label="Email">
                                {ownermembership?.owner?.email}
                            </Descriptions.Item>
                            <Descriptions.Item label="CNIC">
                                {ownermembership?.owner?.cnic}
                            </Descriptions.Item>
                            <Descriptions.Item label="Phone">
                                {ownermembership?.owner?.phoneNumber}
                            </Descriptions.Item>
                            <Descriptions.Item label="Address">
                                {ownermembership?.owner?.permanentAddress}
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>


                <Col xs={24} sm={24} md={16}>



                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24}>
                            <Card>
                                <Descriptions title="Additional Information" column={2} bordered size="small">
                                    <Descriptions.Item label="WhatsApp">
                                        {ownermembership?.owner?.whatsappNumber}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Date of Birth">
                                        {dayjs(ownermembership?.owner?.dateOfBirth).format('DD MMM YYYY')}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Country">
                                        {ownermembership?.owner?.country}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="City">
                                        {ownermembership?.owner?.city}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>

                        </Col>
                        <Col xs={24} md={12}>
                            <Card title="CNIC Front Image">
                                <Avatar
                                    shape="square"
                                    style={{ width: '100%', height: '200px' }}
                                    src={`${baseurl}/api/assets/${ownermembership?.owner?.cnicFrontImage?.filePath}`}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} md={12}>
                            <Card title="CNIC Back Image">
                                <Avatar
                                    shape="square"
                                    style={{ width: '100%', height: '200px' }}
                                    src={`${baseurl}/api/assets/${ownermembership?.owner?.cnicBackImage?.filePath}`}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <MembershipGrid />
        </>
    );
}

export default OwnerDetails;
