import api from "../api";

const API_URL = "/api/inventory"; // Backend API endpoint

// Fetch all inventory records
export const getInventory = async () => {
    const response = await api.get(API_URL);
    return response.data;
};

// Add new inventory transaction
export const createInventory = async (inventoryData) => {
    const response = await api.post(API_URL, inventoryData);
    return response.data;
};

// Delete inventory record
export const deleteInventory = async (id) => {
    await api.delete(`${API_URL}/${id}`);
    return id;
};
