import { Modal, Form, Input, Button, Row, Col, Select, notification, Upload, DatePicker } from 'antd';
import React, { useEffect } from 'react';
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { useDispatch, useSelector } from 'react-redux';
import { addmember, fetchmember, updatemember } from '../app/Service/memberslice';
import TextArea from 'antd/es/input/TextArea';
import { uploadSingleFile } from '../app/Service/fileSlice';
import dayjs from 'dayjs';

function MemberShipModal({ open, onCancel, SelectedCom, limit, title, initialValues, onSubmit }) {
    const dispatch = useDispatch();
    const company_name = useSelector(selectcompanies_name);
    const [form] = Form.useForm();

    useEffect(() => {
        if (open === true) {
            dispatch(getCompaniesByName({}));


        }
    }, [open, dispatch]);

    useEffect(() => {
        if (open && initialValues) {
            form.setFieldsValue({
                ...initialValues,
                company_id: initialValues?.company_id?._id,
                dateOfBirth: initialValues.dateOfBirth ? dayjs(initialValues.dateOfBirth) : null,
                cnicExpire: initialValues.cnicExpire ? dayjs(initialValues.cnicExpire) : null
            });
        }
    }, [open, initialValues, form]);


    const handleFinish = async (values) => {
        console.log('values?.image', values?.image)
        if (values?.image?.fileList?.[0]?.originFileObj) {
            const res = await dispatch(uploadSingleFile(values?.image?.fileList?.[0]?.originFileObj));
            if (res.meta.requestStatus === "fulfilled") {
                values.image = res?.payload.file?.path
            } else {
                delete values.image
            }
        }
        let res;
        if (initialValues) {

            res = await dispatch(updatemember({ id: initialValues?._id, updatedData: values }));

        } else {
            res = await dispatch(addmember(values));
        }

        if (res.meta.requestStatus === 'fulfilled') {
            notification.success({
                message: "Success",
                description: initialValues ? "Updated Successfully" : "Added Successfully",
                placement: "topRight",
            });
            form.resetFields();
            if (SelectedCom) {
                dispatch(fetchmember({ id: SelectedCom, query: "", limit, start: 0 }));
            }
            onCancel();
        } else {
            notification.error({
                message: "Error",  // ✅ Use "message" instead of "type"
                description: res.payload?.message || "Something went wrong!",  // ✅ Prevent undefined errors
                placement: "topRight",
            });
        }
    };
    const countries = [
        "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia",
        "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
        "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde",
        "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo",
        "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic",
        "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland",
        "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau",
        "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
        "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon",
        "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives",
        "Mali", "Malta", "Mauritania", "Mauritius", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique",
        "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Korea",
        "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines",
        "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
        "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore",
        "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka",
        "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste",
        "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates",
        "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen",
        "Zambia", "Zimbabwe"
    ];


    return (
        <Modal
            open={open}
            onCancel={() => {
                form.resetFields()
                onCancel()
            }}
            title={title}
            footer={null}
            style={{ top: 20 }}
            width={900}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
            >
                <Row gutter={[8, 8]}>
                    {/* Owner Name */}
                    <Col span={8}>
                        <Form.Item
                            label="Owner Name"
                            name="owner_name"
                            rules={[{ required: true, message: 'Please enter owner name' }]}
                        >
                            <Input placeholder="Enter Owner Name" />
                        </Form.Item>
                    </Col>

                    {/* Father/Husband Name */}
                    <Col span={8}>
                        <Form.Item
                            label="Father's / Husband Name"
                            name="fatherName"
                            rules={[{ required: true, message: 'Please enter Father Name' }]}
                        >
                            <Input placeholder="Enter Father Name" />
                        </Form.Item>
                    </Col>

                    {/* Date of Birth */}
                    <Col span={8}>
                        <Form.Item label="Date Of Birth" name="dateOfBirth">
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>

                    {/* CNIC */}
                    <Col span={8}>
                        <Form.Item
                            label="CNIC"
                            name="cnic"
                            rules={[{ required: true, message: 'Please enter CNIC' }]}
                        >
                            <Input disabled={initialValues} placeholder="Enter CNIC" />
                        </Form.Item>
                    </Col>

                    {/* Passport Number */}
                    <Col span={8}>
                        <Form.Item
                            label="Passport Number"
                            name="passport_number"
                            rules={[{ required: false, message: 'Please enter Passport Number' }]}
                        >
                            <Input placeholder="Enter Passport Number" />
                        </Form.Item>
                    </Col>

                    {/* CNIC Expiry */}
                    <Col span={8}>
                        <Form.Item label="CNIC Expiry" name="cnicExpire">
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>

                    {/* Contact Number */}
                    <Col span={8}>
                        <Form.Item
                            label="Contact Number"
                            name="contact_number"
                            rules={[{ required: true, message: 'Please enter contact number' }]}
                        >
                            <Input placeholder="Enter Contact Number" />
                        </Form.Item>
                    </Col>

                    {/* Nationality / Country */}
                    <Col span={8}>
                        <Form.Item
                            label="Country"
                            name="nationality"
                            rules={[{ required: true, message: 'Please select a country' }]}
                        >
                            <Select allowClear showSearch placeholder="Select Country">
                                {countries.map((country, index) => (
                                    <Select.Option key={index} value={country}>
                                        {country}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* City */}
                    <Col span={8}>
                        <Form.Item
                            label="City"
                            name="city"
                            rules={[{ required: true, message: 'Please enter your city' }]}
                        >
                            <Input placeholder="City Name" />
                        </Form.Item>
                    </Col>

                    {/* Company Name */}
                    <Col span={12}>
                        <Form.Item
                            label="Company"
                            name="company_id"
                            rules={[{ required: true, message: 'Please select a company' }]}
                        >
                            <Select placeholder="Select Company">
                                {company_name?.map(company => (
                                    <Select.Option key={company._id} value={company._id}>
                                        {company.companyName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* NTN (Fixed Issue: Changed Select to Input) */}
                    <Col span={12}>
                        <Form.Item
                            label="NTN (Tax Number)"
                            name="ntn"
                            rules={[{ required: false, message: 'Please enter your NTN' }]}
                        >
                            <Input placeholder="Enter NTN" />
                        </Form.Item>
                    </Col>

                    {/* Email */}
                    <Col span={12}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ type: 'email', message: 'Please enter a valid email' }]}
                        >
                            <Input placeholder="Enter Email" />
                        </Form.Item>
                    </Col>
                    {/* Profession */}
                    <Col span={12}>
                        <Form.Item
                            label="Profession"
                            name="profession"
                            rules={[{ required: true, message: 'Please enter your profession' }]}
                        >
                            <Input placeholder="Profession" />
                        </Form.Item>
                    </Col>

                    {/* Current Address */}
                    <Col span={24}>
                        <Form.Item
                            label="Current Address"
                            name="address"
                            rules={[{ required: true, message: 'Please enter your current address' }]}
                        >
                            <Input.TextArea placeholder="Enter Current Address" />
                        </Form.Item>
                    </Col>

                    {/* Permanent Address */}
                    <Col span={24}>
                        <Form.Item
                            label="Permanent Address"
                            name="permanent_address"
                            rules={[{ required: true, message: 'Please enter your permanent address' }]}
                        >
                            <Input.TextArea placeholder="Enter Permanent Address" />
                        </Form.Item>
                    </Col>


                    {/* Image Upload */}
                    <Col span={24}>
                        <Form.Item
                            label="Upload Image"
                            name="image"

                            rules={[{ required: true, message: 'Please upload an image' }]}
                        >
                            <Upload.Dragger maxCount={1} accept='image/*' beforeUpload={() => false}>
                                Upload File
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                {/* Submit Button */}
                <Row justify="end">
                    <Col style={{ marginTop: "20px" }}>
                        <Button type="primary" htmlType="submit">
                            Save Member
                        </Button>
                    </Col>
                </Row>
            </Form>

        </Modal>
    );
}

export default MemberShipModal;
