import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productService from "./productService";

// ✅ Fetch all products
export const fetchProducts = createAsyncThunk("products/fetchAll", async (_, thunkAPI) => {
    try {
        return await productService.getAllProducts();
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

// ✅ Create new product
export const createProduct = createAsyncThunk("products/create", async (productData, thunkAPI) => {
    try {
        return await productService.createProduct(productData);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

// ✅ Delete product
export const deleteProduct = createAsyncThunk("products/delete", async (id, thunkAPI) => {
    try {
        await productService.deleteProduct(id);
        return id;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

const productSlice = createSlice({
    name: "products",
    initialState: {
        products: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder
            // Fetch products
            .addCase(fetchProducts.pending, (state) => { state.loading = true; })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            
            // Create product
            .addCase(createProduct.pending, (state) => { state.loading = true; })
            .addCase(createProduct.fulfilled, (state, action) => {
                state.loading = false;
                // state.products.push(action.payload);
            })
            .addCase(createProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // Delete product
            .addCase(deleteProduct.pending, (state) => { state.loading = true; })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.loading = false;
                // state.products = state.products.filter((p) => p._id !== action.payload);
            })
            .addCase(deleteProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default productSlice.reducer;
export const selectProducts = (state) => state.products.products;
export const selectProductLoading = (state) => state.products.loading;
export const selectProductError = (state) => state.products.error;
