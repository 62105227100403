import React, { useEffect, useState } from 'react'
import { Button, Card, Flex, Select, Space, Table } from 'antd'
import ModalCategory from './ModalCategory'
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, fetchCategories, selectCategories, selectCharges, fetchCharges } from '../app/Service/DataSlice';
import ModalChargesName from './ModalChargesName';

function ChargesName() {
    const [OpenCharge, setOpenCharge] = useState(false);
    const dispatch = useDispatch();
    const company_name = useSelector(selectcompanies_name);
    const Charges = useSelector(selectCharges);
    const [company, setCompany] = useState(null);
    useEffect(() => {
        if (company_name.length > 0) {
            const defaultCompanyId = company_name[0]?._id;
            setCompany(defaultCompanyId);
            dispatch(fetchCharges({ modelName: "ChargesName", company_id: defaultCompanyId }));
        }
    }, [company_name, dispatch]);
    const handleCompanyChange = (value) => {
        setCompany(value);
        dispatch(fetchCharges({ modelName: "ChargesName", company_id: value }));
    };
    return (
        <>
            <ModalChargesName OpenCharge={OpenCharge} setOpenCharge={setOpenCharge} />
            <Card
                title={"Charges Name"}
                extra={
                    <Select
                        style={{ width: "200px" }}
                        value={company}
                        placeholder="Select Company"
                        options={company_name?.map((dta) => ({ label: dta.companyName, value: dta._id }))}
                        onChange={handleCompanyChange}
                    />
                }
            >
                <Flex style={{ marginBottom: "10px" }}>
                    <Space>
                        <Button type="primary" onClick={() => setOpenCharge(true)}>+ Add Charge Name</Button>
                    </Space>
                </Flex>

                <Table
                    columns={[
                        {
                            key: "1",
                            title: "Charge Name",
                            dataIndex: "ChargeName",
                            width: "80%"
                        },
                        {
                            key: "2",
                            title: "Action",
                            dataIndex: "_id",
                            render: (id) => <Button type='text' danger>Delete</Button>
                        }
                    ]}
                    dataSource={Charges?.data?.map((charge) => ({ key: charge._id, ...charge }))}
                />
            </Card>
        </>
    );
}

export default ChargesName;
