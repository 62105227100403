import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Card } from 'antd';
import { selectInventory, fetchgetInventory } from '../app/Service/purchaseOrderSlice';

const Inventory = () => {
    const dispatch = useDispatch();
    const inventory = useSelector(selectInventory);

    useEffect(() => {
        dispatch(fetchgetInventory());
    }, [dispatch]);

    // Define main table columns
    const columns = [
        {
            title: 'Product Name',
            dataIndex: ['product', 'name'], // Access nested product name
            key: 'productName',
        },
        {
            title: 'Unit',
            dataIndex: ['product', 'sku'],
            key: 'sku',
        },
        {
            title: 'Total Quantity',
            dataIndex: 'batches',
            key: 'totalQuantity',
            render: (batches) => batches.reduce((sum, batch) => sum + batch.quantity, 0),
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastUpdated',
            key: 'lastUpdated',
            render: (date) => new Date(date).toLocaleDateString(),
        }
    ];

    // Function to render expanded row with batch details
    const expandedRowRender = (record) => {
        const batchColumns = [
            {
                title: 'Batch Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: 'Purchase Price',
                dataIndex: 'purchasePrice',
                key: 'purchasePrice',
                render: (price) => `${price.toFixed(2)}`,
            },
            {
                title: 'Received Date',
                dataIndex: 'receivedDate',
                key: 'receivedDate',
                render: (date) => new Date(date).toLocaleDateString(),
            }
        ];

        return <Table columns={batchColumns} dataSource={record.batches} rowKey="_id" pagination={false} />;
    };

    return (
        <Card title="Inventory List" style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
            <Table
                dataSource={inventory}
                columns={columns}
                rowKey="_id"
                expandable={{ expandedRowRender }} // Enable expandable rows
                pagination={{ pageSize: 5 }}
            />
        </Card>
    );
};

export default Inventory;
