import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Dropdown, Menu, Pagination, Card, Flex, Modal, Form, Space, Upload, notification, Typography, Row, Col, Tooltip, Avatar } from 'antd';
import { FilterOutlined, FileExcelOutlined, FilePdfOutlined, PrinterOutlined, ImportOutlined, PrinterFilled, EditFilled, SettingFilled } from '@ant-design/icons';
import { deleteCompany, exportCompanies, fetchCompanies, importCompany, selectCompanies, selectError, selectLoading, addCompany, updateCompany, handlePrint } from '../app/Service/companySlice';
import { useDispatch, useSelector } from 'react-redux'
import { uploadSingleFile } from '../app/Service/fileSlice';
import { companyService } from '../app/Service/companyService';
import { useForm } from 'antd/es/form/Form';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import api from '../app/api';

const CompanyList = () => {
    const [valuestext, setvaluestext] = useState('')
    const [isCompanyModalVisible, setIsCompanyModalVisible] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);
    const [form] = useForm();
    const dispatch = useDispatch()
    const Company = useSelector(selectCompanies);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const [Company_, setCompany] = useState([]);
    const [limit, setLimit] = useState(9876543210);
    const [start, setStart] = useState(0);
    const [btnloading, setbtnloading] = useState(false)
    const navigate = useNavigate();
    const [file, setFile] = useState()
    const handleDelete = (id) => {
        Modal.confirm({
            title: "Are you sure?",
            content: "This action cannot be undone.",
            okText: "Yes, Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk: async () => {
                await dispatch(deleteCompany(id))
                dispatch(fetchCompanies({}));

            },
        });
    };
    const columns = [
        {
            title: '#',
            dataIndex: 'rowNumber',
            render: (text, record, index) => index + 1,
            width: 50,
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (text, data) => {
                return <Space>
                    <Avatar src={api.getUri() + "/api/" + data?.logo} />
                    {data?.companyName}
                </Space>
            }
        },
        {
            title: 'User',
            dataIndex: 'user_email',
            key: 'user_email',
        },
        {
            title: 'Registration No',
            dataIndex: 'registrationNo',
            key: 'registrationNo',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: 100,

        },
        {
            title: 'Action',
            key: 'action',
            width: 80,
            render: (_, record) => (

                <Space>
                    <Button size={"small"} color={"gold"} onClick={() => handleEdit(record)} variant={"solid"}><EditFilled /></Button>

                </Space>
            ),
        },
    ];
    const handelUploadFile = async ({ file }) => {
        try {
            const res = await dispatch(uploadSingleFile(file));

            if (res.meta.requestStatus === "fulfilled") {
                setFile(res.payload.file)
            }
        } catch (error) {
            notification.error({
                message: 'File Upload Failed',
                description: 'There was an error uploading the file. Please try again.',
                placement: 'topRight',
            });

            console.error('Error handling file upload:', error);
        }
    };
    // const handelUploadFile = async ({ file }) => {
    //     try {
    //         const res = await dispatch(uploadSingleFile(file));

    //         if (res.meta.requestStatus === "fulfilled") {
    //             const file = res?.payload.file;

    //             await dispatch(importCompany(file));

    //             await dispatch(fetchCompanies({ query: "" }));
    //             notification.success({
    //                 message: 'File Uploaded Successfully',
    //                 description: 'The file was uploaded and companies imported successfully.',
    //                 placement: 'topRight',
    //             });
    //         }
    //     } catch (error) {
    //         notification.error({
    //             message: 'File Upload Failed',
    //             description: 'There was an error uploading the file. Please try again.',
    //             placement: 'topRight',
    //         });

    //         console.error('Error handling file upload:', error);
    //     }
    // };

    useEffect(() => {
        dispatch(fetchCompanies({ query: "", limit, start }));
    }, []);

    const handleSearch = (e) => {
        setvaluestext(e.target.value)
        dispatch(fetchCompanies({ query: e.target.value }));
    };

    const handleEdit = (record) => {
        setEditingRecord(record);
        form.setFieldsValue(record);
        setIsCompanyModalVisible(true);
    };


    const handleCompany = async (values) => {
        setbtnloading(true);
        console.log('file', file)
        if (file && file?.path) {
            values.logo = file?.path
        }
        try {
            let res;

            if (editingRecord) {
                res = await dispatch(updateCompany({ id: editingRecord?._id, updatedData: values }));
                console.log(res.payload)
                if (res.meta.requestStatus === 'rejected') {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.payload || "Something went wrong. Please try again.",
                    });
                } else {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Company updated successfully!",
                    });
                    dispatch(fetchCompanies({ query: "" }));
                }
            } else {
                res = await dispatch(addCompany(values));
                console.log(res.payload)

                if (res.meta.requestStatus === 'rejected') {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.payload?.error || "Something went wrong. Please try again.",
                    });
                } else {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Company added successfully!",
                    });
                    dispatch(fetchCompanies({ query: "" }));
                    form.resetFields();
                }
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "An unexpected error occurred.",
            });
        } finally {
            setbtnloading(false);
        }
    };

    const handleScroll = async (e) => {
        const isAtBottom =
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight;
        console.log('isAtBottom', isAtBottom, e.target.offsetHeight, e.target.scrollTop)
        // console.log(e)
        // // console.log('working  scroll')
        // const bottom = e.target.scrollHeight <= e.target.scrollTop + e.target.clientHeight;
        // console.log('bottom', bottom, e.target.scrollHeight <= (e.target.scrollTop + e.target.clientHeight + 50))
        // let array1 = (Company && Company.companies) ? Company.companies : []
        // let array2 = Company_ ? Company_ : []
        // let table = [...array1, ...array2]
        // console.log('bottom', e.target.scrollHeight, e.target.scrollTop + e.target.clientHeight)
        // if (bottom && table.length < Company.totalCount) {
        //     const data = await companyService.getAllCompanies({ query: valuestext, limit: limit, start: start + limit })
        //     console.log('scrolling------------', data)
        //     setStart(start + limit);
        //     let newdata = [...data?.companies]
        //     setCompany(newdata)
        // }
    };
    let array1 = (Company && Company.companies) ? Company.companies : []
    let array2 = Company_ ? Company_ : []
    let table = [...array1, ...array2]
    return (
        <Card title="Company List" extra={<Space >
            <Button type="primary" onClick={() => setIsCompanyModalVisible(true)}>+ Add Company</Button>
        </Space>}>
            <div>

                <Flex justify={"space-between"} align={"center"}>
                    <Input
                        placeholder="Search Company"
                        onChange={handleSearch}
                        value={valuestext}
                        style={{ width: 500, marginBottom: 16 }}
                    />
                    <div style={{ marginBottom: 16 }}>
                        <Typography.Text style={{ marginRight: "20px" }}>
                            Showing {table?.length} of {Company?.totalCount}
                        </Typography.Text>

                        {/* <Tooltip title="Import">
                            <Upload
                                accept=".xls,.xlsx"
                                onChange={handelUploadFile}
                                showUploadList={false}
                                beforeUpload={() => false}>
                                <Button variant={'outlined'} color={'danger'} icon={<ImportOutlined />} style={{ marginRight: 8 }}></Button>
                            </Upload>
                        </Tooltip> */}
                        <Tooltip title="Export in to Excel"> <Button variant={'outlined'} onClick={() => dispatch(exportCompanies("excel"))} color={"primary"} icon={<FileExcelOutlined />} style={{ marginRight: 8 }} /></Tooltip>
                        <Tooltip title="Export in to Pdf">
                            <Button variant={'outlined'} onClick={() => dispatch(exportCompanies("pdf"))} icon={<FilePdfOutlined />} style={{ marginRight: 8, borderColor: "green", color: "green" }} /> </Tooltip>
                        <Tooltip title="Print"><Button variant={'outlined'} onClick={() => dispatch(handlePrint())} style={{ borderColor: "orange", color: "orange" }} icon={<PrinterFilled />} /> </Tooltip>
                    </div>
                </Flex>
                <Table
                    columns={columns}
                    loading={loading}
                    dataSource={table}
                    pagination={false}
                    scroll={{ y: '70vh' }}
                    onScroll={handleScroll}
                />

                <Modal
                    title={editingRecord ? "Update Company Details" : "Add Company"}
                    open={isCompanyModalVisible}
                    onCancel={() => {
                        form.resetFields();
                        setIsCompanyModalVisible(false)
                    }}
                    footer={null}
                    style={{ top: 20 }}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleCompany}
                        autoComplete="off"
                    >
                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item
                                    name="companyName"
                                    label="Company Name"
                                    rules={[{ required: true, message: 'Company Name is required' }]}
                                >
                                    <Input disabled={editingRecord} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="registrationNo"
                                    label="Registration No"
                                    rules={[{ required: true, message: 'Registration No is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="address"
                                    label="Address"
                                    rules={[{ required: true, message: 'Address is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="user_email"
                                    label="User Email"

                                    rules={[
                                        { required: true, message: 'User Email is required' },
                                        { type: 'email', message: 'Enter a valid email' }
                                    ]}
                                >
                                    <Input disabled={editingRecord} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="phone"
                                    label="Phone"
                                    rules={[{ required: true, message: 'Phone is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="logo"
                                    label="Logo"
                                    rules={[{ required: true, message: 'Image is required' }]}
                                >
                                    <Upload.Dragger
                                        accept="image/*"
                                        beforeUpload={() => false} // Prevent auto-upload
                                        onChange={handelUploadFile}
                                        maxCount={1}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <i className="anticon anticon-upload" />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                            Support for a single upload. Only image files are allowed.
                                        </p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>


                            <Col span={24}>
                                {btnloading ? <Button type="primary" loading={btnloading}>Save</Button> : <Button type="primary" htmlType="submit">Save</Button>}

                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        </Card>
    );
};
export default CompanyList;
