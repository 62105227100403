import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getInventory, createInventory, deleteInventory } from "./inventoryService";

// Fetch inventory records
export const fetchInventory = createAsyncThunk("inventory/fetch", async () => {
    return await getInventory();
});

// Add new inventory transaction
export const addInventory = createAsyncThunk("inventory/add", async (inventoryData) => {
    return await createInventory(inventoryData);
});

// Delete inventory record
export const removeInventory = createAsyncThunk("inventory/delete", async (id) => {
    return await deleteInventory(id);
});

const inventorySlice = createSlice({
    name: "inventory",
    initialState: {
        items: [],
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInventory.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchInventory.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload;
            })
            .addCase(fetchInventory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(addInventory.fulfilled, (state, action) => {
                state.items.push(action.payload);
            })
            .addCase(removeInventory.fulfilled, (state, action) => {
                state.items = state.items.filter((item) => item._id !== action.payload);
            });
    }
});

export const selectInventory = (state) => state.inventory.items;
export const selectInventoryLoading = (state) => state.inventory.loading;
export const selectInventoryError = (state) => state.inventory.error;

export default inventorySlice.reducer;
