import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import salesOrderService from "./salesOrderService";

// 📌 Thunks for async actions

// Fetch all sales orders
export const fetchSalesOrders = createAsyncThunk(
    "salesOrders/fetchAll",
    async (_, thunkAPI) => {
        try {
            return await salesOrderService.getSalesOrders();
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

// Fetch single sales order
export const fetchSalesOrderById = createAsyncThunk(
    "salesOrders/fetchById",
    async (id, thunkAPI) => {
        try {
            return await salesOrderService.getSalesOrderById(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

// Create new sales order
export const createSalesOrder = createAsyncThunk(
    "salesOrders/create",
    async (orderData, thunkAPI) => {
        try {
            return await salesOrderService.createSalesOrder(orderData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

// Update sales order
export const updateSalesOrder = createAsyncThunk(
    "salesOrders/update",
    async ({ id, orderData }, thunkAPI) => {
        try {
            return await salesOrderService.updateSalesOrder(id, orderData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

// Delete sales order
export const deleteSalesOrder = createAsyncThunk(
    "salesOrders/delete",
    async (id, thunkAPI) => {
        try {
            return await salesOrderService.deleteSalesOrder(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

// 📌 Sales Order Slice
const salesOrderSlice = createSlice({
    name: "salesOrders",
    initialState: {
        orders: [],
        order: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch all sales orders
            .addCase(fetchSalesOrders.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSalesOrders.fulfilled, (state, action) => {
                state.loading = false;
                state.orders = action.payload;
            })
            .addCase(fetchSalesOrders.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Fetch single sales order
            .addCase(fetchSalesOrderById.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSalesOrderById.fulfilled, (state, action) => {
                state.loading = false;
                state.order = action.payload;
            })
            .addCase(fetchSalesOrderById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Create sales order
            .addCase(createSalesOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(createSalesOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.orders.push(action.payload);
            })
            .addCase(createSalesOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Update sales order
            .addCase(updateSalesOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateSalesOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.orders = state.orders.map(order =>
                    order._id === action.payload._id ? action.payload : order
                );
            })
            .addCase(updateSalesOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Delete sales order
            .addCase(deleteSalesOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteSalesOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.orders = state.orders.filter(order => order._id !== action.payload._id);
            })
            .addCase(deleteSalesOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default salesOrderSlice.reducer;

// 📌 Export Selectors
export const selectSalesOrders = (state) => state.salesOrders.orders;
export const selectSalesOrder = (state) => state.salesOrders.order;
export const selectSalesOrderLoading = (state) => state.salesOrders.loading;
export const selectSalesOrderError = (state) => state.salesOrders.error;
