import React from "react";
import { Form, Input, Button, Card, Select, message, Row, Col } from "antd";

const { Option } = Select;

const CompanySetting = () => {
    const [form] = Form.useForm();

    const handleSubmit = (values) => {
        console.log("Company Settings Saved:", values);
        message.success("Company settings updated successfully!");
    };

    return (
        <Card title="Company Settings" style={{ margin: "0 auto" }}>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Company Name"
                            name="companyName"
                            rules={[{ required: true, message: "Please enter company name" }]}
                        >
                            <Input variant={"outlined"} placeholder="Enter company name" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[{ required: true, message: "Please enter address" }]}
                        >
                            <Input placeholder="Enter company address" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Tax ID" name="taxId">
                            <Input placeholder="Enter tax ID" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Currency"
                            name="currency"
                            rules={[{ required: true, message: "Please select currency" }]}
                        >
                            <Select placeholder="Select currency">
                                <Option value="USD">USD - US Dollar</Option>
                                <Option value="EUR">EUR - Euro</Option>
                                <Option value="GBP">GBP - British Pound</Option>
                                <Option value="INR">INR - Indian Rupee</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save Settings
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default CompanySetting;
