import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import projectService from "./projectService";

// Async Thunks for API calls
export const fetchProjects = createAsyncThunk(
    "projects/fetchAll",
    async (_, { rejectWithValue }) => {
        try {
            return await projectService.getProjects();
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createProject = createAsyncThunk(
    "projects/create",
    async (projectData, { rejectWithValue }) => {
        try {
            return await projectService.createProject(projectData);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteProject = createAsyncThunk(
    "projects/delete",
    async (id, { rejectWithValue }) => {
        try {
            return await projectService.deleteProject(id);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const projectSlice = createSlice({
    name: "projects",
    initialState: {
        projects: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.loading = false;
                state.projects = action.payload;
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createProject.pending, (state) => {
                state.loading = true;
            })
            .addCase(createProject.fulfilled, (state, action) => {
                state.loading = false;
                state.projects.push(action.payload);
            })
            .addCase(createProject.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.projects = state.projects.filter((p) => p._id !== action.payload.id);
            });
    },
});

// ** Selectors **
export const selectProjects = (state) => state.projects.projects;
export const selectProjectById = (state, projectId) =>
    state.projects.projects.find((project) => project._id === projectId);
export const selectLoading = (state) => state.projects.loading;
export const selectError = (state) => state.projects.error;

export default projectSlice.reducer;
