import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { assignServices } from "./AssignService";
export const fetchAssignplot = createAsyncThunk(
    "assugn/fetchAll",
    async ({ id, query, limit, start }, { rejectWithValue }) => {
        try {
            return await assignServices.getAssignPlot({ id, query, limit, start });
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.error : error.message);
        }
    }
);
export const fetchScrollAssignplot = createAsyncThunk(
    "assugn/fetchscrollAll",
    async ({ id, query, limit, start }, { rejectWithValue }) => {
        try {
            return await assignServices.getAssignPlot({ id, query, limit, start });
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.error : error.message);
        }
    }
);
export const fetchRejectAndAccept = createAsyncThunk(
    "asas/reject-accept",
    async ({ id, status }, { rejectWithValue }) => {
        try {
            return await assignServices.rejectAndTransfer({ id, status });
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.message : error.message);
        }
    }
);
export const fetchExportassignplot = createAsyncThunk(
    "asas/export",
    async ({ id, type }, { rejectWithValue }) => {
        try {
            return await assignServices.exportassignplot({ id, type });
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.message : error.message);
        }
    }
);

// rejectAndTransfer
export const fetchTransferplot = createAsyncThunk(
    "transfer/fetchAll",
    async ({ id, query, limit, start, type }, { rejectWithValue }) => {
        try {
            return await assignServices.getTransferPlot({ id, query, limit, start, type });
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.error : error.message);
        }
    }
);
export const fetchScrollTransferplot = createAsyncThunk(
    "transfer/fetchScrollAll",
    async ({ id, query, limit, start, type }, { rejectWithValue }) => {
        try {
            return await assignServices.getTransferPlot({ id, query, limit, start, type });
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.error : error.message);
        }
    }
);
// getTransferPlot

// createTransferplot
// export const getplotByName = createAsyncThunk(
//     "plot/name",
//     async ({ }, { rejectWithValue }) => {
//         try {
//             return await plotServices.getplotByName({});
//         } catch (error) {
//             return rejectWithValue(error.response ? error.response.data.error : error.message);
//         }
//     }
// );
// export const handlePrint = createAsyncThunk(
//     "plot/print",
//     async (id, { rejectWithValue }) => {
//         try {
//             return await plotServices.handlePrint(id);
//         } catch (error) {
//             return rejectWithValue(error.response ? error.response.data : error.message);
//         }
//     }
// );
// export const exportplot = createAsyncThunk(
//     "plot/export",
//     async (query, { rejectWithValue }) => {
//         try {
//             return await plotServices.exportplot(query);
//         } catch (error) {
//             return rejectWithValue(error.response ? error.response.data : error.message);
//         }
//     }
// );

export const addAssigndplot = createAsyncThunk(
    "plot/add",
    async (data, { rejectWithValue }) => {
        try {
            return await assignServices.createAssignplot(data);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);
export const addTransferdplot = createAsyncThunk(
    "plot/transfer",
    async (data, { rejectWithValue }) => {
        try {
            return await assignServices.createTransferplot(data);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);
// export const impotplot = createAsyncThunk(
//     "plot/import",
//     async (data, { rejectWithValue }) => {
//         try {
//             return await plotServices.importplot(data);
//         } catch (error) {
//             return rejectWithValue(error.response ? error.response.data : error.message);
//         }
//     }
// );

// export const updateplot = createAsyncThunk(
//     "plot/update",
//     async ({ id, data }, { rejectWithValue }) => {
//         try {
//             return await plotServices.updateplot(id, data);
//         } catch (error) {
//             return rejectWithValue(error.response ? error.response.data : error.message);
//         }
//     }
// );
// export const deleteplot = createAsyncThunk(
//     "plot/delete",
//     async (id, { rejectWithValue }) => {
//         try {
//             await plotServices.deleteplot(id);
//             return id;
//         } catch (error) {
//             return rejectWithValue(error.response ? error.response.data : error.message);
//         }
//     }
// );

const assignplotSlice = createSlice({
    name: "Assign",
    initialState: {
        assignplot: { totalCount: 0, data: [] },
        transferPlot: { count: 0, data: [] },
        loading: false,
        error: null,
        importLoading: false,
        importError: null,
        importSuccess: false,
        plot_name: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchAssignplot.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAssignplot.fulfilled, (state, action) => {
                state.loading = false;
                state.assignplot = action.payload;
            })
            .addCase(fetchAssignplot.rejected, (state, action) => {
                state.loading = false;
                state.assignplot = []
                state.error = action.error;
            })
            .addCase(fetchScrollAssignplot.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchScrollAssignplot.fulfilled, (state, action) => {
                state.loading = false;
                state.assignplot.data = [...state.assignplot?.data, ...action.payload?.data];
            })
            .addCase(fetchScrollAssignplot.rejected, (state, action) => {
                state.loading = false;
                state.assignplot = []
                state.error = action.error;
            })
            .addCase(fetchTransferplot.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTransferplot.fulfilled, (state, action) => {
                state.loading = false;
                state.transferPlot = action.payload;
            })
            .addCase(fetchTransferplot.rejected, (state, action) => {
                state.loading = false;
                state.transferPlot = []
                state.error = action.error;



            }).addCase(fetchScrollTransferplot.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchScrollTransferplot.fulfilled, (state, action) => {
                state.loading = false;
                state.transferPlot.count = action.payload.count;
                state.transferPlot.data = [...state.transferPlot?.data, ...action.payload?.data];
            })
            .addCase(fetchScrollTransferplot.rejected, (state, action) => {
                state.loading = false;
                state.transferPlot = []
                state.error = action.error;
            })




            .addCase(addAssigndplot.pending, (state) => {
                state.loading = true;
            })
            .addCase(addAssigndplot.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addAssigndplot.rejected, (state, action) => {
                state.error = action.error;
                state.loading = false;

            })
        // .addCase(addAssigndplot.fulfilled, (state, action) => {

        // })
        // .addCase(updateplot.fulfilled, (state, action) => {

        // })
        // .addCase(deleteplot.fulfilled, (state, action) => {

        // })
        // .addCase(impotplot.pending, (state) => {
        //     state.importLoading = true;
        //     state.importSuccess = false;
        //     state.importError = null;
        // })
        // .addCase(impotplot.fulfilled, (state, action) => {
        //     state.importLoading = false;
        //     state.importSuccess = true;

        // })
        // .addCase(impotplot.rejected, (state, action) => {
        //     state.importLoading = false;
        //     state.importError = action.error.message;
        // });
    },
});

export const selectassignplot = (state) => state.assignplotSlice.assignplot;
export const selecttransferPlot = (state) => state.assignplotSlice.transferPlot;

export const selectassignplot_name = (state) => state.assignplotSlice.plot_name;
export const selectLoading = (state) => state.assignplotSlice.loading;
export const selectError = (state) => state.assignplotSlice.error;
export const selectImportLoading = (state) => state.assignplotSlice.importLoading;
export const selectImportError = (state) => state.assignplotSlice.importError;
export const selectImportSuccess = (state) => state.assignplotSlice.importSuccess;

export default assignplotSlice.reducer;
