// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 25px !important;
}

.ant-card .ant-card-body {
  padding: 5px 15px;
}

/* Apply border to all headers */
.ant-table-thead th {
  border-bottom: 2px solid black;
  /* Change color and thickness as needed */
}

/* Apply border to specific columns */
.ant-table-thead th:nth-child(1) {
  border-bottom: 2px solid red;
  /* Example for the first column */
}

.ant-table-thead th:nth-child(2) {
  border-bottom: 2px solid green;
  /* Example for the second column */
}

h4.ant-typography {
  margin-bottom: 0px !important;
  font-size: 18px !important;
}

.ant-card {
  border-radius: 0px;
}

.ant-space-gap-row-small {
  row-gap: 2px;
}

.ant-tree .ant-tree-treenode,
.ant-tree .ant-tree-node-content-wrapper {
  width: 100% !important;
}

.acc.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 15px !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,uBAAuB;EACvB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;AACnB;;AAEA,gCAAgC;AAChC;EACE,8BAA8B;EAC9B,yCAAyC;AAC3C;;AAEA,qCAAqC;AACrC;EACE,4BAA4B;EAC5B,iCAAiC;AACnC;;AAEA;EACE,8BAA8B;EAC9B,kCAAkC;AACpC;;AAEA;EACE,6BAA6B;EAC7B,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":["body {\n  margin: 0;\n  font-family: sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.ant-select-single .ant-select-selector .ant-select-selection-item {\n  line-height: 25px !important;\n}\n\n.ant-card .ant-card-body {\n  padding: 5px 15px;\n}\n\n/* Apply border to all headers */\n.ant-table-thead th {\n  border-bottom: 2px solid black;\n  /* Change color and thickness as needed */\n}\n\n/* Apply border to specific columns */\n.ant-table-thead th:nth-child(1) {\n  border-bottom: 2px solid red;\n  /* Example for the first column */\n}\n\n.ant-table-thead th:nth-child(2) {\n  border-bottom: 2px solid green;\n  /* Example for the second column */\n}\n\nh4.ant-typography {\n  margin-bottom: 0px !important;\n  font-size: 18px !important;\n}\n\n.ant-card {\n  border-radius: 0px;\n}\n\n.ant-space-gap-row-small {\n  row-gap: 2px;\n}\n\n.ant-tree .ant-tree-treenode,\n.ant-tree .ant-tree-node-content-wrapper {\n  width: 100% !important;\n}\n\n.acc.ant-select-single .ant-select-selector .ant-select-selection-item {\n  line-height: 15px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
