// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ForgotPassword.css */

.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5; /* Light background color */
  }
  
  .forgot-password-box {
    background: #fff; /* White background for the form container */
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px; /* Max width for the form container */
  }
  
  .forgot-password-title {
    margin-bottom: 24px;
    text-align: center;
  }
  
  .forgot-password-button {
    width: 100%;
    margin-top: 16px;
  }
  
  .resend-otp-text {
    display: block;
    text-align: center;
    margin: 16px 0;
  }
  
  .resend-otp-button {
    display: block;
    width: 100%;
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/ForgotPassword.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB,EAAE,2BAA2B;EACxD;;EAEA;IACE,gBAAgB,EAAE,4CAA4C;IAC9D,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,WAAW;IACX,gBAAgB,EAAE,qCAAqC;EACzD;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,WAAW;IACX,kBAAkB;EACpB","sourcesContent":["/* ForgotPassword.css */\n\n.forgot-password-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f0f2f5; /* Light background color */\n  }\n  \n  .forgot-password-box {\n    background: #fff; /* White background for the form container */\n    padding: 24px;\n    border-radius: 8px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n    width: 100%;\n    max-width: 400px; /* Max width for the form container */\n  }\n  \n  .forgot-password-title {\n    margin-bottom: 24px;\n    text-align: center;\n  }\n  \n  .forgot-password-button {\n    width: 100%;\n    margin-top: 16px;\n  }\n  \n  .resend-otp-text {\n    display: block;\n    text-align: center;\n    margin: 16px 0;\n  }\n  \n  .resend-otp-button {\n    display: block;\n    width: 100%;\n    text-align: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
