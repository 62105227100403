import { configureStore } from "@reduxjs/toolkit";
import companySlice from './Service/companySlice'
import fileSlice from './Service/fileSlice'
import userSlice from './Service/userSlice'
import departmentSlice from './Service/departmentSlice'
import memberSlice from './Service/memberslice'
import DataSlice from "./Service/DataSlice";
import plotSlice from "./Service/plotSlice";
import assignplotSlice from './Service/AssignSlice';
import witnessNomineeSlice from './Service/witNomineeSlice';
import ChargesSlice from './Service/ChargesSlice';

import accountSlice from './Service/accountSlice';
import account from './Service/accountsSlice';
import supplierReducer from './Service/supplierSlice';
import inventoryReducer from './Service/inventorySlice';
import productReducer from './Service/productSlice';
import journalReducer from './Service/journalSlice';
import projectReducer from './Service/projectSlice';
import purchaseOrders from './Service/purchaseOrderSlice';
import salesOrders from './Service/salesOrderSlice';









export const store = configureStore({
    reducer: {
        companySlice,
        fileSlice,
        userSlice,
        departmentSlice,
        memberSlice,
        dataSlice: DataSlice,
        plotSlice,
        assignplotSlice,
        witnessNomineeSlice,
        accountSlice,
        account,
        ChargesSlice,
        supplier: supplierReducer,
        products: productReducer,
        inventory: inventoryReducer,
        journal: journalReducer,
        projects: projectReducer,
        purchaseOrders,
        salesOrders,
    },
});
