import api from '../api'
const API_URL = "/api/plot";


export const plotServices = {
    async getAllplot({ id, query = "", limit = 20, start = 0 }) {
        try {
            const response = await api.get(`${API_URL}?query=${query}&limit=${limit}&start=${start}&id=${id}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching plot:', error);
            throw error;
        }
    }
    ,
    async getplotByName({ }) {
        try {
            const response = await api.get(`${API_URL}/user/id`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    async exportplot(query) {
        let format = query;
        try {
            const response = await api.get(`${API_URL}/export`, {
                params: { query: format },
                responseType: format.type === 'pdf' ? "" : "",
            });
            if (format.type === 'pdf') {
                const pdfUrl = api.getUri() + "/api" + response.data.url;
                const link = document.createElement("a");
                link.href = pdfUrl;
                const timestamp = new Date();
                const fileName = `plot_${timestamp}.pdf`;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else if (format.type === 'excel') {
                const pdfUrl = api.getUri() + "/api/uploads/" + response.data.url;
                const link = document.createElement("a");
                link.href = pdfUrl;
                const timestamp = new Date();
                const fileName = `File${timestamp}.xlsx`;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            
        } catch (error) {
            console.error(`Error exporting plot (${format}):`, error);
            alert(`An error occurred while exporting the Departments as ${format}.`);
        }

    },



    async importplot(data) {
        const response = await api.get(API_URL + "/import/data?id=" + data.id + "&fileId=" + data.fileId);
        if (!response.body) {
            throw new Error("ReadableStream not supported");
        }
        return response.data;
    },

    async createplot(data) {
        const response = await api.post(API_URL, data);
        return response.data;
    },
    async updateplot(id, data) {
        const response = await api.put(`${API_URL}/${id}`, data);
        return response.data;
    },
    async deleteplot(id) {
        const response = await api.delete(`${API_URL}/${id}`);
        return response.data;
    },
    async handlePrint(id) {
        try {
            const response = await api.get(API_URL + "/print/data?id=" + id, {
                responseType: "blob",
                headers: {
                    "Content-Type": "application/pdf",
                },
            });

            const blob = new Blob([response.data], { type: "application/pdf" });
            const pdfUrl = URL.createObjectURL(blob);

            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src = pdfUrl;
            document.body.appendChild(iframe);

            iframe.onload = () => {
                iframe.contentWindow.print();
            };
        } catch (error) {
            console.error("Error fetching PDF:", error);
            alert("Failed to fetch or print PDF.");
        }
    }
};
