import axios from "axios";
import api from "../api";

const API_URL = "/api/purchase-orders";

// 📌 Fetch All Purchase Orders
const getAllPurchaseOrders = async () => {
    const response = await api.get(API_URL);
    return response.data;
};
const getInventory = async () => {
    const response = await api.get(API_URL + "/inventory");
    return response.data;
};
const returnPurchaseOrder = async (data) => {

    const response = await api.post(`/api/purchase-orders/return`, { ...data });
    return response.data;

}
const getStockTransaction = async () => {

    const response = await api.get(`/api/purchase-orders/stock-checkout`);
    return response.data;

}


// 📌 Fetch Single Purchase Order by ID
const getPurchaseOrderById = async (id) => {
    const response = await api.get(`${API_URL}/${id}`);
    return response.data;
};

// 📌 Create a New Purchase Order
const createPurchaseOrder = async (orderData) => {
    const response = await api.post(API_URL, orderData);
    return response.data;
};

// 📌 Update Purchase Order
const updatePurchaseOrder = async (id, orderData) => {
    const response = await api.put(`${API_URL}/${id}`, orderData);
    return response.data;
};

// 📌 Delete Purchase Order
const deletePurchaseOrder = async (id) => {
    const response = await api.delete(`${API_URL}/${id}`);
    return response.data;
};

const purchaseOrderService = {
    getAllPurchaseOrders,
    getPurchaseOrderById,
    createPurchaseOrder,
    updatePurchaseOrder,
    deletePurchaseOrder,
    getInventory,
    returnPurchaseOrder,
    getStockTransaction
};

export default purchaseOrderService;
