import React, { useState } from "react";
import { Button, Modal, Input, Form, Select, Switch, Table, DatePicker, Row, Col, InputNumber } from "antd";
import dayjs from "dayjs";
import { fetchCategories, fetchCharges, selectCharges } from "../app/Service/DataSlice";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { addChargesApplied } from "../app/Service/ChargesSlice";
function ModalCharges({ selectedRowKeys, defaultCompanyId }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isInstallment, setIsInstallment] = useState(false);
    const [generatedSchedule, setGeneratedSchedule] = useState(null);
    const [form] = Form.useForm();
    const Charges = useSelector(selectCharges);
    const dispatch = useDispatch()
    const generateCharges = async () => {
        try {
            let values = await form.validateFields()
            const { numPayments, startDate, installmentType, finalAmount, downPayment, lastDate } = values;
            let schedule = [];
            let lastPaymentDate = dayjs(lastDate);
            let installmentAmount = (finalAmount - (downPayment || 0)) / numPayments;
            if (installmentAmount > 0) {
                for (let i = 1; i <= numPayments; i++) {
                    schedule.push({
                        key: i,
                        installmentNumber: i,
                        per_day_fine: values.fine,
                        lastDate: lastPaymentDate.format("YYYY-MM-DD"),
                        amount: parseFloat(installmentAmount.toFixed(2)),
                    });

                    if (installmentType === "monthly") {
                        lastPaymentDate = lastPaymentDate.add(1, "month");
                    } else if (installmentType === "quarterly") {
                        lastPaymentDate = lastPaymentDate.add(3, "month");
                    } else if (installmentType === "yearly") {
                        lastPaymentDate = lastPaymentDate.add(1, "year");
                    }
                }
            }
            setGeneratedSchedule({ ...values, schedule });

        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Invalid",
                text: "Fill All Required Fields.",
            });
        }
    };


    const handleAddInstallment = async (values) => {
        if (values?.finalAmount < 1) {
            Swal.fire({
                icon: "error",
                title: "Invalid Payment",
                text: "Final amount must be greater than zero after applying down payment.",
            });
            return;
        }

        console.log("values", {
            ...values,
            finalAmount: values?.finalAmount - values?.downPayment,
            generatedSchedule,
            company_id: defaultCompanyId,
            fileIds: selectedRowKeys,
        });

        const res = await dispatch(
            addChargesApplied({
                ...values,
                schedule: generatedSchedule?.schedule,
                company_id: defaultCompanyId,
                fileIds: selectedRowKeys,
                type: "File",
            })
        );

        console.log("res", res);
        if (res?.meta?.requestStatus === "fulfilled") {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: res.payload.message || "Installment added successfully!",
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.error?.message || "Something went wrong. Please try again.",
            });
        }
    };



    const handleFieldChange = () => {
        setGeneratedSchedule(null);
    };

    const handleDiscountChange = (changedValues) => {
        let allValues = form.getFieldsValue();
        let { amount, discountPrice, discountPercentage } = allValues;

        if (!amount || amount <= 0) {
            form.setFieldsValue({ finalAmount: 0, discountPrice: 0, discountPercentage: 0 });
            return;
        }

        let finalAmount = amount;
        let newDiscountPrice = discountPrice ? Math.max(0, discountPrice) : 0;
        let newDiscountPercentage = discountPercentage ? Math.max(0, discountPercentage) : 0;

        if (changedValues.hasOwnProperty("discountPercentage")) {
            if (newDiscountPercentage > 100) newDiscountPercentage = 100;
            newDiscountPrice = parseFloat(((amount * newDiscountPercentage) / 100).toFixed(2));
        } else if (changedValues.hasOwnProperty("discountPrice")) {
            if (newDiscountPrice > amount) newDiscountPrice = amount;
            newDiscountPercentage = parseFloat(((newDiscountPrice / amount) * 100).toFixed(2));
        }

        finalAmount = parseFloat((amount - newDiscountPrice).toFixed(2));

        form.setFieldsValue({
            discountPrice: newDiscountPrice,
            discountPercentage: newDiscountPercentage,
            finalAmount: finalAmount,
        });

        setGeneratedSchedule(null);
    };

    const scheduleColumns = [
        { title: "Installment #", dataIndex: "installmentNumber", key: "installmentNumber" },
        { title: "Last Date", dataIndex: "lastDate", key: "lastDate" },
        { title: "Fine After Date", dataIndex: "per_day_fine", key: "fine" },
        { title: "Amount", dataIndex: "amount", key: "amount" },
    ];

    return (
        <div className="flex justify-center items-center h-screen">
            <Button type="primary" onClick={() => {
                dispatch(fetchCharges({ modelName: "ChargesName", company_id: defaultCompanyId }));
                setIsOpen(true)
            }} disabled={selectedRowKeys?.length < 1}>Add Charges</Button>
            <Modal
                title="Add Installment Charges"
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                footer={null}
                width={900}
                style={{ top: 20 }}
            >
                <Form
                    form={form}
                    onFinish={handleAddInstallment}
                    onValuesChange={handleDiscountChange}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Charge Name" name="chargeName" rules={[{ required: true, message: 'Please enter charge name' }]}>
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    placeholder={"Select Charge Name"}
                                    onSelect={handleFieldChange}
                                    options={Charges?.data?.map((item, i) => ({
                                        key: i,
                                        label: item?.ChargeName,
                                        value: item?._id,
                                    })) || []}
                                />
                                {/* <Input placeholder="Enter charge name" /> */}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Amount" name="amount" rules={[{ required: true, message: 'Please enter amount' }]}>
                                <InputNumber type="number" style={{ width: "100%" }} min={0} placeholder="Enter amount" onChange={handleFieldChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Discount in Price" name="discountPrice">
                                <Input type="number" min={0} placeholder="Enter discount amount" onChange={handleDiscountChange} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Discount in Percentage" name="discountPercentage">
                                <Input type="number" min={0} placeholder="Enter discount percentage" onChange={handleDiscountChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Final Amount" name="finalAmount">
                                <Input type="number" min={0} disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Installment" name="installment">
                                <Switch checked={isInstallment} onChange={(checked) => {
                                    setIsInstallment(checked);
                                    setGeneratedSchedule(null);
                                }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {isInstallment && (
                        <>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Down Payment" name="downPayment">
                                        <InputNumber min={0} style={{ width: "100%" }} placeholder="Enter down payment" onChange={handleFieldChange} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Number of Payments" name="numPayments" rules={[{ required: true, message: 'Please enter number of payments' }]}>
                                        <InputNumber min={1} style={{ width: "100%" }} placeholder="Enter number of payments" onChange={handleFieldChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>

                                <Col span={12}>
                                    <Form.Item label="Last Date" name="lastDate" rules={[{ required: true, message: 'Please enter last date' }]}>
                                        <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" onChange={handleFieldChange} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Fine" name="fine" rules={[{ required: true, message: 'Please enter Fine' }]}>
                                        <InputNumber min={0} style={{ width: "100%" }} placeholder="Set fine after late date." onChange={handleFieldChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Installment Type" name="installmentType" rules={[{ required: true, message: 'Please select installment type' }]}>
                                        <Select onChange={handleFieldChange}>
                                            <Select.Option value="monthly">Monthly</Select.Option>
                                            <Select.Option value="quarterly">Quarterly</Select.Option>
                                            <Select.Option value="yearly">Yearly</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="mt-4 flex justify-end" style={{ marginTop: "20px" }}>
                                <Button type="primary" onClick={generateCharges}>Create Schedule</Button>
                            </div>
                        </>
                    )}

                    {(generatedSchedule) &&
                        <Table dataSource={generatedSchedule.schedule} columns={scheduleColumns} rowKey="key" />
                    }
                    {(generatedSchedule || !isInstallment) &&
                        <Form.Item style={{ marginTop: "20px" }}>
                            <Button type={"primary"} htmlType="submit">Apply Chares</Button>
                        </Form.Item>
                    }
                </Form>

            </Modal>
        </div>
    );
}

export default ModalCharges;
