

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './Client/Layout'
import Login from './Client/Pages/Login';
import Dashboard from './Client/Pages/Dashboard'
import { ConfigProvider } from 'antd';
import User from './Client/Pages/User'
import File from './Client/Pages/File'
import Company from './Client/Pages/Company'
import Departments from './Client/Pages/Departments'
import Bank from './Client/Pages/Bank'
import FileList from './Client/Pages/FileList'
import BankList from './Client/Pages/BankList'
import PaymentVocher from './Client/Pages/PaymentVocher'
import PaymentListVoucher from './Client/Pages/PaymentListVoucher'
import PaymentInvoice from './Client/Pages/PaymentInvoice'
import PaymentListInvoice from './Client/Pages/PaymentListInvoice'
import Transaction from './Client/Reports/Transaction';
import AccountBalance from './Client/Reports/AccountBalance';
import Income from './Client/Reports/Income';
import Expense from './Client/Reports/Expense';
import TrialBalance from './Client/Reports/TrialBalance';
import BalanceSheet from './Client/Reports/BalanceSheet';
import Signup from './Client/Pages/Signup';
import PrivateRoutes from './Client/private/privateRoutes';
import UserList from './Client/Pages/UserList';
import { Provider } from 'react-redux';
import { store } from './Client/app/store';
import EmployeeList from './Client/Pages/EmployeeList';
import Employee from './Client/Pages/Employee';
import Member from './Client/Pages/Member';
import AssignFileList from './Client/Pages/AssignFileList';
import Forms from './Client/Pages/Forms';
import TransferList from './Client/Pages/TransferList';
import Charges from './Client/Pages/Charges';
import MemberDetails from './Client/Pages/MemberDetails';
import ChartOfAccounts from './Client/Pages/CharOfAccounts';
import Supplier from './Client/Pages/Supplier';
import Inventory from './Client/Pages/Inventory';
import ProductList from './Client/Pages/ProductList';
import JournalEntries from './Client/Pages/JournalEntries';
import ProjectList from './Client/Pages/Project';
import CreateSchedule from './Client/Pages/CreateSchedule';
import CompanySetting from './Client/Pages/CompanySetting';
import PurchaseOrder from './Client/Pages/PurchaseOrder';
import PurchaseOrderList from './Client/Pages/PurchaseOrderList';
import EditPurchaseOrder from './Client/Pages/EditPurchaseOrder';
import PoConfirmed from './Client/Pages/PoConfirmed';
import Order from './Client/Pages/order';
import StockTransaction from './Client/Pages/StockTransaction';
import SaleOrder from './Client/Pages/SaleOrder';
import SaleOrderList from './Client/Pages/SaleOrderList';
function App() {
  return (
    <Provider store={store}>
      <ConfigProvider theme={{
        "token": {
          "controlHeight": 38
        },
        "components": {
          "Input": {
            "borderRadius": 2
          },
          "Menu": {
            "activeBarBorderWidth": 0
          },
          "Select": {
            "borderRadius": 2,
            "borderRadiusLG": 2
          },
          "Button": {
            "borderRadius": 2
          },
          "Table": {
            "fontSize": 12,
            "padding": 8,
            "headerBorderRadius": 0,
            "borderRadius": 0,
            "fontWeightStrong": 600
          },
          "DatePicker": {
            "borderRadius": 2,
            "borderRadiusLG": 2
          }
        }
      }}>
        <BrowserRouter>
          <Routes>

            <Route path='/signup' element={<Signup />} />
            <Route path='/login' element={<Login />} />
            <Route path='/' element={<PrivateRoutes />}>
              <Route index element={<Dashboard />} />
              <Route path="user" element={<User />} />
              <Route path="user-list" element={<UserList />} />
              <Route path="company" element={<Company />} />
              <Route path="settings" element={<CompanySetting />} />

              <Route path="project" element={<ProjectList />} />
              <Route path="employee" element={<Employee />} />
              <Route path="employee-list" element={<EmployeeList />} />
              <Route path="member" element={<Member />} />
              <Route path="member/detail/:id" element={<MemberDetails />} />
              <Route path="department" element={<Departments />} />
              <Route path="Bank" element={<Bank />} />
              <Route path="bank-list" element={<BankList />} />
              <Route path="payment-Voucher" element={<PaymentVocher />} />
              <Route path="payment-list-Voucher" element={<PaymentListVoucher />} />
              <Route path="payment-Invoice" element={<PaymentInvoice />} />
              <Route path="payment-list-Invoice" element={<PaymentListInvoice />} />
              <Route path="file" element={<File />} />
              <Route path="file-list" element={<FileList />} />
              <Route path="assign-file-list" element={<AssignFileList />} />
              <Route exact path="transfer-file-list" element={<TransferList />} />
              <Route exact path="charges" element={<Charges />} />
              <Route exact path="Create-Schedule" element={<CreateSchedule />} />



              <Route path="forms" element={<Forms />} />
              <Route path="Transaction" element={<Transaction />} />
              <Route path="Account Balance" element={<AccountBalance />} />
              <Route path="Chart-Of-Accounts" element={<ChartOfAccounts />} />
              <Route path="Supplier" element={<Supplier />} />
              <Route path="Products" element={<ProductList />} />
              <Route path="Purchase-Order" element={<PurchaseOrder />} />
              <Route path="Purchase-Order-list" element={<PurchaseOrderList />} />
              <Route path="/purchase-orders/edit/:id" element={<EditPurchaseOrder />} />
              <Route path="/po-confirmed" element={<PoConfirmed />} />
              <Route path="/order" element={<Order />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/stock-transaction" element={<StockTransaction />} />
              <Route path="/Sale/Order" element={<SaleOrder />} />
              <Route path="/Sale/Order/list" element={<SaleOrderList />} />









              <Route path="Inventory" element={<Inventory />} />
              <Route path="Journal Entries" element={<JournalEntries />} />






              <Route path="Income" element={<Income />} />
              <Route path="Expense" element={<Expense />} />
              <Route path="Trial-Balance" element={<TrialBalance />} />
              <Route path="Balance Sheet" element={<BalanceSheet />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}
export default App;