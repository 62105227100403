import { Button, Form, Input, Modal, Select, notification } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createData, fetchCategories, fetchCharges } from '../app/Service/DataSlice';
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';

function ModalChargesName({ OpenCharge, setOpenCharge }) {
    const [form] = Form.useForm(); // Use Ant Design Form instance
    const dispatch = useDispatch();
    const company_name = useSelector(selectcompanies_name);

    // Notification function
    const openNotification = (type, message, description) => {
        notification[type]({
            message,
            description,
            placement: 'topRight',
        });
    };

    const handleFinish = async (values) => {
        const res = await dispatch(createData({ modelName: "ChargesName", data: values }));

        if (res.meta.requestStatus === "fulfilled") {
            openNotification('success', 'Success', 'Charge Name created successfully');
            form.resetFields(); // Reset form fields
            dispatch(fetchCharges({ modelName: "ChargesName", company_id: values.company_id }));

            setOpenCharge(false); // Close modal

        } else {
            openNotification('error', 'Error', res.payload?.error || 'Something went wrong');
        }
    };

    useEffect(() => {
        dispatch(getCompaniesByName({}));
    }, [dispatch]);

    return (
        <Modal
            open={OpenCharge}
            title="Charge  Name"
            onCancel={() => {
                form.resetFields(); // Reset form when closing modal
                setOpenCharge(false);
            }}
            footer={null}
        >
            <Form form={form} onFinish={handleFinish} layout="vertical">
                <Form.Item label="Company" name="company_id" rules={[{ required: true, message: "Please select a company" }]}>
                    <Select placeholder="Select Company" options={company_name?.map((dta) => ({ label: dta.companyName, value: dta._id }))} />
                </Form.Item>

                <Form.Item label="Charge Name" name="ChargeName" rules={[{ required: true, message: "Please enter a Charge name" }]}>
                    <Input placeholder="Charge Name" />
                </Form.Item>

                <br />
                <Button type="primary" htmlType="submit">Ok</Button>
            </Form>
        </Modal>
    );
}


export default ModalChargesName