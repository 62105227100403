import React, { useEffect } from "react";
import { Table, Button, message, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchPurchaseOrders, deletePurchaseOrder } from "../app/Service/purchaseOrderSlice";
import { selectPurchaseOrders, selectPurchaseOrderLoading } from "../app/Service/purchaseOrderSlice";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function PurchaseOrderList() {
    const dispatch = useDispatch();
    const purchaseOrders = useSelector(selectPurchaseOrders);
    const loading = useSelector(selectPurchaseOrderLoading);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchPurchaseOrders());
    }, [dispatch]);

    // Handle order deletion
    const handleDelete = async (id) => {
        try {
            await dispatch(deletePurchaseOrder(id)).unwrap();
            message.success("Purchase order deleted successfully!");
        } catch (error) {
            message.error("Failed to delete purchase order.");
        }
    };

    console.log("purchaseOrders", purchaseOrders);

    // Define table columns
    const columns = [
        {
            title: "Supplier",
            dataIndex: "supplier",
            key: "supplier",
            render: (supplier) => supplier?.name || "Unknown",
        },
        {
            title: "Order Date",
            dataIndex: "orderDate",
            key: "orderDate",
            render: (date) => new Date(date).toLocaleDateString(),
        },
        {
            title: "Grand Total",
            dataIndex: "grandTotal",
            key: "grandTotal",
            render: (total) => (total ? `${total.toFixed(2)}` : "0.00"),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            if (record.status === "Completed") {
                                message.warning("Order is confirmed. You cannot change the order.");
                            } else {
                                navigate(`/purchase-orders/edit/${record._id}`);
                            }
                        }}
                    >
                        ✏️ Edit
                    </Button>
                    <Button type="link" danger onClick={() => handleDelete(record._id)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Card title="Purchase Orders List" style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
            <Table
                dataSource={purchaseOrders?.filter(
                    (item) => item.status !== "Completed" && item.status !== "Returned"
                )}
                columns={columns}
                loading={loading}
                rowKey="_id"
                pagination={{ pageSize: 10 }}
            />
        </Card>
    );
}

export default PurchaseOrderList;
