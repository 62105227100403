import React, { useEffect, useState } from "react";
import { Button, Table, Card, Select, Avatar, Space, Image } from "antd";
import WitnessNomineeModal from "./WitnessNomineeModal";
import { useDispatch, useSelector } from "react-redux";
import { selectcompanies_name } from "../app/Service/companySlice";
import { selectWitNominees, fetchWitNominees } from "../app/Service/witNomineeSlice";
import api from "../app/api";

function WitnessNomineeList() {
    const [modalVisible, setModalVisible] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);
    const [company, setCompany] = useState(null);

    const company_name = useSelector(selectcompanies_name);
    const witNominee = useSelector(selectWitNominees);
    const dispatch = useDispatch();

    useEffect(() => {
        if (company_name.length > 0) {
            const defaultCompanyId = company_name[0]?._id;
            setCompany(defaultCompanyId);
            // dispatch(fetchWitNominees({ company_id: defaultCompanyId }));
        }
    }, [company_name, dispatch]);

    const handleCompanyChange = (companyId) => {
        setCompany(companyId);
        // dispatch(fetchWitNominees({ company_id: companyId }));
    };

    const handleEdit = (record) => {
        setEditingRecord(record);
        setModalVisible(true);
    };
    console.log('witNominee', witNominee)

    return (
        <Card
            title="Witness & Nominee List"
            extra={
                <Select
                    style={{ width: "200px" }}
                    value={company}
                    placeholder="Select Company"
                    options={company_name?.map((dta) => ({
                        label: dta.companyName,
                        value: dta._id,
                    }))}
                    onChange={handleCompanyChange}
                />
            }
            bordered={false}
            style={{ boxShadow: "0px 4px 12px rgba(0,0,0,0.1)", borderRadius: "10px" }}
        >
            <Button
                type="primary"
                onClick={() => {
                    setEditingRecord(null);
                    setModalVisible(true);
                }}
                style={{ marginBottom: "15px" }}
            >
                Add Witness/Nominee/Agent
            </Button>

            <Table
                dataSource={witNominee}
                columns={[
                    {
                        title: "Name", dataIndex: "name", key: "name",
                        render: (text, data) => <>
                            <Space>
                                <Avatar src={api.getUri() + "/api/" + data?.profile_image} />
                                {text}
                            </Space>
                        </>



                    },
                    { title: "Role", dataIndex: "role", key: "role" },
                    { title: "CNIC", dataIndex: "cnic", key: "cnic" },

                    {
                        title: "CNIC Front",
                        dataIndex: ["cnic_images", "front"],
                        key: "front",
                        render: (text) =>
                            text ? (
                                <Image
                                    src={api.getUri() + "/api/" + text}
                                    alt="CNIC Front"
                                    width={40}
                                    height={40}
                                    preview={{ mask: "Preview CNIC Front", height: 200 }}
                                />
                            ) : (
                                "N/A"
                            ),
                    },
                    {
                        title: "CNIC Back",
                        dataIndex: ["cnic_images", "back"],
                        key: "back",
                        render: (text) =>
                            text ? (
                                <Image
                                    src={api.getUri() + "/api/" + text}
                                    alt="CNIC Back"
                                    width={40}
                                    height={40}
                                    preview={{ mask: "Preview CNIC Back", height: 200 }}
                                />
                            ) : (
                                "N/A"
                            ),
                    },
                    { title: "Contact", dataIndex: "contact", key: "contact" },

                    { title: "Nationality", dataIndex: ["address", "nationality"], key: "nationality" },
                    { title: "City", dataIndex: ["address", "city"], key: "city" },
                    { title: "Street", dataIndex: ["address", "street"], key: "street" },
                    { title: "Occupation", dataIndex: "occupation", key: "occupation" },







                    {
                        title: "Actions",
                        key: "actions",
                        render: (_, record) => (
                            <Button type="link" onClick={() => handleEdit(record)}>
                                Edit
                            </Button>
                        ),
                    },
                ]}
                rowKey="_id"
                pagination={{ pageSize: 5 }}
            />

            <WitnessNomineeModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                record={editingRecord}
            />
        </Card>
    );
}

export default WitnessNomineeList;
