import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Dropdown, Menu, Pagination, Card, Flex, Modal, Form, Space, Upload, notification, Typography, Row, Col, Tooltip, Select, Avatar, Spin } from 'antd';
import { FilterOutlined, FileExcelOutlined, FilePdfOutlined, PrinterOutlined, ImportOutlined, PrinterFilled } from '@ant-design/icons';
import {
    deleteUser, exportuser, importuser, selectError, selectLoading, addCompany, updateUser, handlePrint,
    fetchusers,
    selectUsers,
    addUser
} from '../app/Service/userSlice';
import {
    fetchCompanies,
    selectCompanies,
    selectcompanies_name,
    getCompaniesByName
} from '../app/Service/companySlice';
import { useDispatch, useSelector } from 'react-redux'
import { uploadSingleFile } from '../app/Service/fileSlice';
import { userService } from '../app/Service/userService';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom'
import EmployeeModal from './UpdateEmployee';
import api from '../app/api';
import dayjs from 'dayjs';
const EmployeeList = () => {
    const [valuestext, setvaluestext] = useState('')
    const [Company_id, setcompany_id] = useState('')
    const [isLoading, setIsloading] = useState(false)
    const [form] = useForm();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const Users = useSelector(selectUsers);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);
    const company_name = useSelector(selectcompanies_name)
    const [SelectedCom, setSelectedCom] = useState();
    const [isModalVisible, setModalVisible] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const handleOpenAdd = () => {
        setSelectedEmployee(null);
        setModalVisible(true);
    };

    const handleOpenEdit = (employee) => {
        setSelectedEmployee(employee);
        setModalVisible(true);
    };


    const [Users_, setUser_] = useState([]);
    const [limit, setLimit] = useState(9876543210);
    const [start, setStart] = useState(0);
    const handleDelete = (id) => {
        Modal.confirm({
            title: "Are you sure?",
            content: "This action cannot be undone.",
            okText: "Yes, Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk: async () => {
                await dispatch(deleteUser(id))
                dispatch(fetchusers({}));

            },
        });
    };
    const columns = [
        {
            title: '#',
            dataIndex: 'rowNumber',
            render: (text, record, index) => index + 1,
            width: 50,
        },
        {

            title: 'Full Name',
            dataIndex: 'firstName',
            render: (text, data) => <><Space>
                <Avatar icon={data?.firstName?.[0] || "" + data?.lastName?.[0] || ""} style={{ background: "blue" }} src={api.getUri() + "/api/" + data.profilePicture} /> <span style={{ textTransform: "capitalize" }}>{data?.firstName + " - " + data?.lastName}</span></Space> </>
        },
        {
            title: 'Email',
            dataIndex: 'user_email',
            key: 'user_email',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Department',
            dataIndex: ["dept_id", "department"],
            key: 'address',
            width: 100,

        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: 100,

        },
        {
            title: 'Date Of Birth',
            dataIndex: 'dateOfBirth',
            key: 'phone',
            width: 100,

            render: (date) => date ? dayjs(date).format('DD-MM-YYYY') : ""
        },

        {
            title: 'Roles',
            dataIndex: ["employee_id", "role"], // Accessing nested data
            render: (roles) => roles?.length ? roles.join(', ') : 'No roles' // Join array elements
        },

        {
            title: 'Action',
            key: 'action',
            width: 80,

            render: (_, record) => (
                <Button type='link' onClick={() => handleOpenEdit(record)} style={{ marginLeft: 10 }}>
                    Edit
                </Button>
            ),
        },
    ];
    const handelUploadFile = async ({ file }) => {
        try {
            if (Company_id) {

                const res = await dispatch(uploadSingleFile(file));

                if (res.meta.requestStatus === "fulfilled") {
                    const file = res?.payload.file;

                    await dispatch(importuser(file));

                    await dispatch(fetchusers({ id: Company_id }));
                    notification.success({
                        message: 'File Uploaded Successfully',
                        description: 'The file was uploaded and companies imported successfully.',
                        placement: 'topRight',
                    });
                }
            } else {
                notification.error({
                    message: 'Please Select Company',
                    description: 'There was an error uploading the file. Please try again.',
                    placement: 'topRight',
                });
            }

        } catch (error) {
            notification.error({
                message: 'File Upload Failed',
                description: 'There was an error uploading the file. Please try again.',
                placement: 'topRight',
            });

            console.error('Error handling file upload:', error);
        }
    };

    useEffect(() => {
        dispatch(getCompaniesByName({}));
    }, []);
    useEffect(() => {
        if (company_name?.length > 0) {
            setSelectedCom(company_name[0]?.companyName)
            dispatch(fetchusers({ id: company_name[0]?.companyName, query: "", limit, start }));
            // dispatch(fetchCompanies({}));
        }
    }, [company_name])
    useEffect(() => {

        if (SelectedCom) {
            dispatch(fetchusers({ id: SelectedCom, query: valuestext, limit, start }));
        }
    }, [SelectedCom])
    const handleSearch = (e) => {
        if (SelectedCom) {
            setvaluestext(e.target.value)
            dispatch(fetchusers({ id: SelectedCom, query: e.target.value }));

        }

    };
    const handleEdit = (employee) => {
        setSelectedEmployee(employee);
        setModalVisible(true);
    };

    const handleUpdate = (updatedData) => {
        console.log("Updated Employee Data:", updatedData);
        setModalVisible(false);
    };
    const handleScroll = async (e) => {
        if (SelectedCom) {

            const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
            let array1 = (Users && Users.employee) ? Users.employee : []
            let array2 = Users_ ? Users_ : []
            let table = [...array1, ...array2]
            if (bottom && !loading && table.length < Users.totalCount) {
                const data = await userService.getAlluses({ id: SelectedCom, query: valuestext, limit: limit, start: start + limit })
                setStart(start + limit);
                let newdata = [...data?.employee]
                setUser_(newdata)
            }
        }
    };
    let array1 = (Users && Users.employee) ? Users.employee : []
    let array2 = Users_ ? Users_ : []
    let table = [...array1, ...array2]
    return (
        <Spin spinning={isLoading}>

            <Card title="Employee List" style={{ minHeight: "500px" }} extra={<> <Space >
                <Button type="primary" onClick={handleOpenAdd}>+ Add Employee</Button>

            </Space></>}>
                <EmployeeModal
                    visible={isModalVisible}
                    onClose={() => {
                        setSelectedEmployee(null)
                        setModalVisible(false)
                    }}
                    employeeData={selectedEmployee}
                    SelectedCom={SelectedCom}
                />
                <div>
                    <Flex justify={"space-between"} align={"center"}>
                        <div>
                            <Select
                                value={SelectedCom}  // Set the first company's _id as default value
                                options={company_name?.map(company => ({
                                    label: company?.companyName,
                                    value: company?.companyName,
                                }))}
                                style={{ width: 300, marginBottom: 16, marginRight: 20 }}
                                onChange={(e) => setSelectedCom(e)}
                            />
                            <Input
                                placeholder="Search Employee"
                                onChange={handleSearch}
                                value={valuestext}
                                style={{ width: 250, marginBottom: 16 }}
                            />
                        </div>
                        <div style={{ marginBottom: 16 }}>
                            <Typography.Text style={{ marginRight: "20px" }}>
                                Showing {table?.length} of {Users?.totalCount}
                            </Typography.Text>

                            {/* <Tooltip title="Import">
                            <Upload
                                accept=".xls,.xlsx"
                                onChange={handelUploadFile}
                                showUploadList={false}
                                beforeUpload={() => false}>
                                <Button variant={'outlined'} color={'danger'} icon={<ImportOutlined />} style={{ marginRight: 8 }}></Button>
                            </Upload>
                        </Tooltip> */}
                            <Tooltip title="Export in to Excel"> <Button variant={'outlined'} onClick={async () => {
                                setIsloading(true)
                                await dispatch(exportuser("excel"))
                                setIsloading(false)
                            }} color={"primary"} icon={<FileExcelOutlined />} style={{ marginRight: 8 }} /></Tooltip>
                            <Tooltip title="Export in to Pdf">
                                <Button variant={'outlined'} onClick={async () => {
                                    setIsloading(true)
                                    await dispatch(exportuser("pdf"))
                                    setIsloading(false)

                                }} icon={<FilePdfOutlined />} style={{ marginRight: 8, borderColor: "green", color: "green" }} /> </Tooltip>
                            <Tooltip title="Print"><Button variant={'outlined'} onClick={async () => {
                                setIsloading(true)
                                await dispatch(handlePrint())
                                setIsloading(false)
                            }} style={{ borderColor: "orange", color: "orange" }
                            } icon={<PrinterFilled />} /> </Tooltip>
                        </div>
                    </Flex>
                    <Table
                        columns={columns}
                        loading={loading}
                        dataSource={table}
                        pagination={false}
                        scroll={{ y: 'calc(100vh - 300px)' }}
                        onScroll={handleScroll}
                    />


                </div>
            </Card>
        </Spin>

    );
};

export default EmployeeList;
