import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form, Input, Select, Card, Flex, Tree, Space } from "antd";
import { addAccount, deleteAccount, fetchAccounts, selectAccounts } from "../app/Service/accountsSlice";
import Swal from "sweetalert2";
import { CarryOutOutlined, EditFilled, SearchOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";

const { Search } = Input;

const ChartOfAccounts = () => {
    const dispatch = useDispatch();
    const accounts = useSelector(selectAccounts);
    const [stype, setStype] = useState([]);
    const [nstype, setNstype] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredTreeData, setFilteredTreeData] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(fetchAccounts());
    }, [dispatch]);

    useEffect(() => {
        setFilteredTreeData(transformToTreeData(accounts));
    }, [accounts]);

    const handleAdd = () => {
        form.validateFields()
            .then((values) => {
                dispatch(addAccount(values))
                    .unwrap()
                    .then(() => {
                        Swal.fire({ title: "Success!", text: "Account added successfully", icon: "success" });
                        dispatch(fetchAccounts());
                        // setIsModalOpen(false);
                        // form.resetFields();
                    })
                    .catch(() => {
                        Swal.fire({ title: "Error!", text: "Failed to add account", icon: "error" });
                    });
            })
            .catch((info) => console.log("Validation Failed:", info));
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "This will permanently delete the account!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteAccount(id));
                Swal.fire("Deleted!", "The account has been removed.", "success");
            }
        });
    };

    const handleSelect = (e) => {
        const subtype = accounts?.find((item) => item._id === e);
        setStype(subtype?.children || []);
    };

    const handleNestredSelect = (e) => {
        const subtype = stype?.find((item) => item._id === e);
        setNstype(subtype?.children || []);
    };
    const transformToTreeData = (accounts, parentKey = "0") => {
        return accounts.map((account, index) => {
            const key = `${parentKey}-${index}`;
            return {
                title: (
                    <Flex style={{ width: "100%" }} justify="space-between">
                        {account.srNo} - {account.name}
                        <Space>
                            {/* <EditFilled style={{ color: "#1890ff", cursor: "pointer" }} /> */}
                            <DeleteOutlined style={{ color: "red", cursor: "pointer" }} onClick={() => handleDelete(account._id)} />
                        </Space>
                    </Flex>
                ),
                key,
                icon: <CarryOutOutlined style={{ color: "#52c41a" }} />,
                children: account.children && account.children.length > 0
                    ? transformToTreeData(account.children, key)
                    : [],
            };
        });
    };

    const filterTreeData = (data, searchText) => {
        if (!searchText) return transformToTreeData(accounts);

        const filterNodes = (nodes) => {
            return nodes
                .map(node => {
                    const match = node.title.props.children[0].toLowerCase().includes(searchText.toLowerCase());
                    if (match || (node.children && node.children.length)) {
                        return { ...node, children: filterNodes(node.children) };
                    }
                    return null;
                })
                .filter(node => node !== null);
        };

        return filterNodes(transformToTreeData(accounts));
    };

    const handleSearch = (value) => {
        setSearchQuery(value);
        setFilteredTreeData(filterTreeData(accounts, value));
    };

    return (
        <>
            {/* ADD ACCOUNT MODAL */}
            <Modal
                title="Add New Account"
                open={isModalOpen}
                onOk={handleAdd}
                onCancel={() => setIsModalOpen(false)}
                okText="Add"
                cancelText="Cancel"
                centered
                width={500}
            >
                <Form form={form} layout="vertical">
                    <Form.Item label="Parent Account" name="Parent">
                        <Select
                            showSearch
                            allowClear
                            onSelect={handleSelect}
                            placeholder="Select parent account (optional)"
                            options={accounts?.map((item) => ({
                                label: item?.name,
                                value: item?._id,
                            }))}
                        />
                    </Form.Item>

                    <Form.Item label="Sub Parent Account" name="Child">
                        <Select
                            showSearch
                            allowClear
                            placeholder="Select child account (optional)"
                            onSelect={handleNestredSelect}
                            options={stype?.map((item_) => ({
                                label: item_.name,
                                value: item_._id,
                            }))}
                        />
                    </Form.Item>
                    <Form.Item label="Nested Account" name="Nested">
                        <Select
                            showSearch
                            allowClear
                            placeholder="Select child account (optional)"
                            options={nstype?.map((item_) => ({
                                label: item_.name,
                                value: item_._id,
                            }))}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Account Name"
                        name="name"
                        rules={[{ required: true, message: "Please enter an account name!" }]}
                    >
                        <Input placeholder="Enter account name" />
                    </Form.Item>
                </Form>
            </Modal>

            {/* CHART OF ACCOUNTS CARD */}
            <Card
                title="Chart of Accounts"
                extra={
                    <Space>
                        <Search
                            placeholder="Search accounts..."
                            allowClear
                            onSearch={handleSearch}
                            style={{ width: 250, borderRadius: "8px" }}
                            prefix={<SearchOutlined />}
                        />
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalOpen(true)}>
                            Add Account
                        </Button>
                    </Space>
                }
                // style={{ borderRadius: "10px", boxShadow: "0px 2px 10px rgba(0,0,0,0.1)", padding: "15px" }}
            >
                <Tree
                    showLine={{ showLeafIcon: false }}
                    showIcon={false}
                    defaultExpandedKeys={['0-0']}
                    treeData={filteredTreeData}
                    style={{ fontSize: "16px" }}
                />
            </Card>
        </>
    );
};

export default ChartOfAccounts;
