import React, { useEffect, useState } from "react";
import { Card, Table, InputNumber, Select, Button, DatePicker, message, Flex, Row, Col } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts, selectProducts, selectProductLoading } from "../app/Service/productSlice";
import { fetchSuppliers, selectSuppliers, selectSupplierLoading } from "../app/Service/supplierSlice";
import { createPurchaseOrder } from "../app/Service/purchaseOrderSlice";
import { fetchAccounts, selectAccounts } from "../app/Service/accountsSlice";

const { Option } = Select;
const initialData = [{ key: 1, product: null, quantity: 1, rate: 0, totalPrice: 0 }];

function Order() {
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [orderDate, setOrderDate] = useState(null);
    const [data, setData] = useState(initialData);
    const accounts = useSelector(selectAccounts);
    const [debitAcc, setDebitAcc] = useState(null);
    const [creditAcc, setCreditAcc] = useState(null);
    const dispatch = useDispatch();
    const products = useSelector(selectProducts);
    const loading = useSelector(selectProductLoading);
    const suppliers = useSelector(selectSuppliers);
    const supplierLoading = useSelector(selectSupplierLoading);

    useEffect(() => {
        dispatch(fetchProducts());
        dispatch(fetchSuppliers());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchAccounts());
    }, [dispatch]);
    const handleProductChange = (value, key) => {
        const selectedProduct = products.find((product) => product._id === value);
        if (selectedProduct) {
            const newData = data.map((item) =>
                item.key === key
                    ? { ...item, product: value, rate: selectedProduct.purchasePrice, totalPrice: item.quantity * selectedProduct.purchasePrice }
                    : item
            );
            setData(newData);
        }
    };

    const handleInputChange = (value, key, column) => {
        if (value < 0) {
            message.error("Value cannot be negative");
            return;
        }
        const newData = data.map((item) => {
            if (item.key === key) {
                const updatedItem = { ...item, [column]: value };
                if (column === "quantity" || column === "rate") {
                    updatedItem.totalPrice = (updatedItem.quantity || 0) * (updatedItem.rate || 0);
                }
                return updatedItem;
            }
            return item;
        });
        setData(newData);
    };

    const addRow = () => {
        const newKey = data.length ? Math.max(...data.map(item => item.key)) + 1 : 1;
        setData([...data, { key: newKey, product: null, quantity: 1, rate: 0, totalPrice: 0 }]);
    };

    const deleteRow = (key) => {
        setData(data.filter((item) => item.key !== key));
    };

    const saveOrder = () => {
        if (!selectedSupplier || !orderDate) {
            message.error("Please select a supplier and order date");
            return;
        }

        if (data.length === 0 || data.some(item => !item.product || item.quantity <= 0 || item.rate <= 0)) {
            message.error("Please ensure all product fields are filled correctly.");
            return;
        }
        if (!debitAcc || !creditAcc) {
            message.error("For completed orders, Debit and Credit accounts must be selected.");
            return;
        }

        const purchaseOrderData = {
            supplier: selectedSupplier,
            orderDate,
            status: "Completed",
            inventoryAccountId: debitAcc,
            accountsPayableAccountId: creditAcc,
            products: data.map(item => ({
                product: item.product,
                quantity: item.quantity,
                rate: item.rate,
                totalPrice: item.totalPrice,
            })),
            grandTotal: data.reduce((sum, item) => sum + item.totalPrice, 0),
        };

        dispatch(createPurchaseOrder(purchaseOrderData))
            .unwrap()
            .then(() => {
                message.success("Purchase order created successfully!");
                setSelectedSupplier(null);
                setOrderDate(null);
                setData(initialData);
            })
            .catch((error) => {
                message.error(error.message || "Failed to create purchase order");
            });
    };

    const flattenHierarchy = (nodes, parentPath = "") => {
        const result = [];

        const traverse = (items, parentPath) => {
            items.forEach(item => {
                const currentPath = parentPath ? `${parentPath} > ${item.name}` : item.name;
                if (item.children && item.children.length) {
                    traverse(item.children, currentPath);
                } else {
                    result.push({ ...item, hierarchy: currentPath });
                }
            });
        };

        traverse(nodes, parentPath);
        return result;
    };

    const acc_ = flattenHierarchy(accounts)
    const columns = [
        {
            title: "Product",
            dataIndex: "product",
            render: (_, record) => (
                <Select
                    placeholder="Select Product"
                    value={record.product}
                    style={{ width: 180 }}
                    onChange={(value) => handleProductChange(value, record.key)}
                    loading={loading}
                >
                    {products.map((product) => (
                        <Option key={product._id} value={product._id}>{product.name}</Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            render: (_, record) => (
                <InputNumber min={1} value={record.quantity} onChange={(value) => handleInputChange(value, record.key, "quantity")} />
            ),
        },
        {
            title: "Rate",
            dataIndex: "rate",
            render: (_, record) => (
                <InputNumber min={0} value={record.rate} onChange={(value) => handleInputChange(value, record.key, "rate")} />
            ),
        },
        {
            title: "Total Price",
            dataIndex: "totalPrice",
            render: (_, record) => <span>{record.totalPrice.toFixed(2)}</span>,
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => (
                <Button type="link" onClick={() => deleteRow(record.key)}>
                    <DeleteOutlined style={{ color: "red" }} />
                </Button>
            ),
        },
    ];
    // Handlers for selection changes
    const handleDebitSelect = (value) => {
        setDebitAcc(value);
    };

    const handleCreditSelect = (value) => {
        setCreditAcc(value);
    };
    // Function to generate options
    const renderOptions = (accounts) =>
        accounts?.map((item) => ({
            value: item?.srNo,
            label: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bold", color: "#333" }}>{item?.srNo}</span>
                    <span>{item?.hierarchy}</span>
                </div>
            ),
        }));

    // Function for search filtering
    const filterOption = (input, option) =>
        (option?.label?.props?.children[1]?.props?.children ?? "")
            .toLowerCase()
            .includes(input.toLowerCase());

    return (
        <Card title="Order" style={{ padding: 20, borderRadius: 10, boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
            <Row gutter={[16, 16]} align="middle" justify="space-between">
                <Col span={8}>
                    <Select
                        placeholder="Select Supplier"
                        style={{ width: "100%" }}

                        onChange={(value) => setSelectedSupplier(value)}
                        loading={supplierLoading}
                    >
                        {suppliers.map((supplier) => (
                            <Option key={supplier.id} value={supplier._id}>{supplier.name}</Option>
                        ))}
                    </Select>
                </Col>

                <Col span={8}>
                    <Select
                        style={{ width: "100%" }}
                        value={debitAcc}  // Separate state for debit account
                        allowClear
                        showSearch
                        className="acc"
                        placeholder="Select Debit Account"
                        onChange={handleDebitSelect}
                        options={renderOptions(acc_)}
                        filterOption={filterOption}
                    />
                </Col>
                <Col span={8}>
                    <Select
                        style={{ width: "100%" }}
                        value={creditAcc}  // Separate state for credit account
                        allowClear
                        showSearch
                        className="acc"
                        placeholder="Select Credit Account"
                        onChange={handleCreditSelect}
                        options={renderOptions(acc_)}
                        filterOption={filterOption}
                    />
                </Col>
                <Col span={8}>
                    <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Select Order Date" onChange={(date, dateString) => setOrderDate(dateString)} />
                </Col>
                <Col span={8}>
                    <Button type="dashed" block onClick={addRow} icon={<PlusOutlined />}>
                        Add Product
                    </Button>
                </Col>
            </Row>
            <Table dataSource={data} columns={columns} pagination={{ pageSize: 5 }} rowKey="key" style={{ marginTop: 20 }} />
            <h3 style={{ textAlign: "right", marginTop: 20 }}>Grand Total: {data.reduce((sum, item) => sum + item.totalPrice, 0).toFixed(2)}</h3>
            <Flex justify="end">
                <Button type="primary" onClick={saveOrder} style={{ marginTop: 10 }}>Save Order</Button>
            </Flex>
        </Card>
    );
}

export default Order;