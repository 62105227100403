// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* otp.css */

.otp-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5; /* Light background color */
  }
  
  .otp-container {
    background: #fff; /* White background for the form container */
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 480px; /* Max width for the form container */
  }
  
  .otp-title {
    margin-bottom: 24px;
    text-align: center;
  }
  
  .otp-form {
    display: flex;
    flex-direction: column;
  }
  
  .otp-mask {
    font-size: 1.2em;
    color: rgba(0, 0, 0, 0.5);
  }
  
  .otp-submit-button {
    width: 100%;
    margin-top: 16px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/otp.css"],"names":[],"mappings":"AAAA,YAAY;;AAEZ;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB,EAAE,2BAA2B;EACxD;;EAEA;IACE,gBAAgB,EAAE,4CAA4C;IAC9D,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,WAAW;IACX,gBAAgB,EAAE,qCAAqC;EACzD;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;IAChB,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB","sourcesContent":["/* otp.css */\n\n.otp-screen {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f0f2f5; /* Light background color */\n  }\n  \n  .otp-container {\n    background: #fff; /* White background for the form container */\n    padding: 24px;\n    border-radius: 8px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n    width: 100%;\n    max-width: 480px; /* Max width for the form container */\n  }\n  \n  .otp-title {\n    margin-bottom: 24px;\n    text-align: center;\n  }\n  \n  .otp-form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .otp-mask {\n    font-size: 1.2em;\n    color: rgba(0, 0, 0, 0.5);\n  }\n  \n  .otp-submit-button {\n    width: 100%;\n    margin-top: 16px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
