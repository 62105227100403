import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWitNomineeApi, addWitNomineeApi, updateWitnessApi } from './WitNomineeService';

export const fetchWitNominee = createAsyncThunk(
    'witnessNominee/fetchWitNominee',
    async (id, { rejectWithValue }) => {
        try {
            return await fetchWitNomineeApi(id);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addWitNominee = createAsyncThunk(
    'witnessNominee/add',
    async (data, { rejectWithValue }) => {
        try {
            return await addWitNomineeApi(data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const updateWitness = createAsyncThunk(
    'witnessNominee/update',
    async (data, { rejectWithValue }) => {
        try {
            return await updateWitnessApi(data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

// 


const witnessNomineeSlice = createSlice({
    name: 'witnessNominee',
    initialState: {
        witnominees: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWitNominee.pending, (state) => {
                state.loading = true;
                state.error = null;
            }).addCase(fetchWitNominee.fulfilled, (state, action) => {
                state.loading = false;
                state.witnominees = action.payload;
            }).addCase(fetchWitNominee.rejected, (state, action) => {
                state.loading = false;
                state.witnominees = []
                state.error = action.payload;
            });
    },
});

export const selectWitNominees = (state) => state.witnessNomineeSlice.witnominees;
export const selectWitNomineeLoading = (state) => state.witnessNomineeSlice.loading;
export const selectWitNomineeError = (state) => state.witnessNomineeSlice.error;

export default witnessNomineeSlice.reducer;
