import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import purchaseOrderService from "./purchaseOrderService";

// 📌 Fetch All Purchase Orders
export const fetchPurchaseOrders = createAsyncThunk(
    "purchaseOrders/fetchAll",
    async (_, thunkAPI) => {
        try {
            return await purchaseOrderService.getAllPurchaseOrders();
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
export const fetchgetInventory = createAsyncThunk(
    "getInventory/fetchAll",
    async (_, thunkAPI) => {
        try {
            return await purchaseOrderService.getInventory();
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
export const returnPurchaseOrder = createAsyncThunk(
    "returnPurchaseOrder/fetchAll",
    async (data, thunkAPI) => {
        try {
            return await purchaseOrderService.returnPurchaseOrder(data);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
export const fetchStockTransaction = createAsyncThunk(
    "fetchStockTransaction/fetchAll",
    async (data, thunkAPI) => {
        try {
            return await purchaseOrderService.getStockTransaction();
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// 📌 Fetch Single Purchase Order
export const fetchPurchaseOrderById = createAsyncThunk(
    "purchaseOrders/fetchById",
    async (id, thunkAPI) => {
        try {
            return await purchaseOrderService.getPurchaseOrderById(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// 📌 Create New Purchase Order
export const createPurchaseOrder = createAsyncThunk(
    "purchaseOrders/create",
    async (orderData, thunkAPI) => {
        try {
            return await purchaseOrderService.createPurchaseOrder(orderData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// 📌 Update Purchase Order
export const updatePurchaseOrder = createAsyncThunk(
    "purchaseOrders/update",
    async ({ id, orderData }, thunkAPI) => {
        try {
            return await purchaseOrderService.updatePurchaseOrder(id, orderData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// 📌 Delete Purchase Order
export const deletePurchaseOrder = createAsyncThunk(
    "purchaseOrders/delete",
    async (id, thunkAPI) => {
        try {
            return await purchaseOrderService.deletePurchaseOrder(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// 📌 Slice
const purchaseOrderSlice = createSlice({
    name: "purchaseOrders",
    initialState: {
        purchaseOrders: [],
        purchaseOrder: null,
        loading: false,
        error: null,
        inventory: null,
        stockCheckout: null
    },
    reducers: {},

    extraReducers: (builder) => {
        builder

            .addCase(fetchStockTransaction.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchStockTransaction.fulfilled, (state, action) => {
                state.loading = false;
                state.stockCheckout = action.payload;;
            })
            .addCase(fetchStockTransaction.rejected, (state, action) => {
                state.loading = false;
                state.stockCheckout = null
                state.error = action.payload;
            })
            .addCase(returnPurchaseOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(returnPurchaseOrder.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(returnPurchaseOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchgetInventory.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchgetInventory.fulfilled, (state, action) => {
                state.loading = false;
                state.inventory = action.payload;
            })
            .addCase(fetchgetInventory.rejected, (state, action) => {
                state.loading = false;
                state.inventory = null;
                state.error = action.payload;
            })
            .addCase(fetchPurchaseOrders.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchPurchaseOrders.fulfilled, (state, action) => {
                state.loading = false;
                state.purchaseOrders = action.payload;
            })
            .addCase(fetchPurchaseOrders.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchPurchaseOrderById.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchPurchaseOrderById.fulfilled, (state, action) => {
                state.loading = false;
                state.purchaseOrder = action.payload;
            })
            .addCase(fetchPurchaseOrderById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createPurchaseOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(createPurchaseOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.purchaseOrders.push(action.payload);
            })
            .addCase(createPurchaseOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updatePurchaseOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(updatePurchaseOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.purchaseOrders = state.purchaseOrders.map((order) =>
                    order._id === action.payload._id ? action.payload : order
                );
            })
            .addCase(updatePurchaseOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deletePurchaseOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(deletePurchaseOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.purchaseOrders = state.purchaseOrders.filter(
                    (order) => order._id !== action.meta.arg
                );
            })
            .addCase(deletePurchaseOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});
export const selectPurchaseOrders = (state) => state.purchaseOrders.purchaseOrders;
export const selectInventory = (state) => state.purchaseOrders.inventory;
export const selectstockCheckout = (state) => state.purchaseOrders.stockCheckout;


// 📌 Select a single purchase order
export const selectSinglePurchaseOrder = (state) => state.purchaseOrders.purchaseOrder;

// 📌 Select loading state
export const selectPurchaseOrderLoading = (state) => state.purchaseOrders.loading;

// 📌 Select error state
export const selectPurchaseOrderError = (state) => state.purchaseOrders.error;

export default purchaseOrderSlice.reducer;
