import { Button, Form, Input, Modal, Select, notification } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createData, fetchResidentials } from '../app/Service/DataSlice';
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';

function ModalResidential({ OpenResidential, setOpenResidential }) {
  const [form] = Form.useForm(); // Use Ant Design Form instance
  const dispatch = useDispatch();
  const company_name = useSelector(selectcompanies_name);

  // Notification function
  const openNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      placement: 'topRight',
    });
  };

  const handleFinish = async (values) => {
    const res = await dispatch(createData({ modelName: "Residential", data: values }));

    if (res.meta.requestStatus === "fulfilled") {
      openNotification('success', 'Success', res?.payload.message);
      form.resetFields(); // Reset form fields
      dispatch(fetchResidentials({ modelName: "Residential", company_id: values.company_id }));

      setOpenResidential(false); // Close modal
    } else {
      openNotification('error', 'Error', res.payload?.error || 'Something went wrong');
    }
  };

  useEffect(() => {
    dispatch(getCompaniesByName({}));
  }, [dispatch]);

  return (
    <Modal
      open={OpenResidential}
      title="Residental"
      onCancel={() => {
        form.resetFields(); // Reset form when closing modal
        setOpenResidential(false);
      }}
      footer={null}
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        {/* Company Name */}
        <Form.Item label="Company" name="company_id" rules={[{ required: true, message: "Please select a company" }]}>
          <Select placeholder="Select Company" options={company_name?.map((dta) => ({ label: dta.companyName, value: dta._id }))} />
        </Form.Item>

        {/* Residential Name */}
        <Form.Item label="Residential" name="Residential" rules={[{ required: true, message: "Please enter a Residential name" }]}>
          <Input placeholder="Residential Name" />
        </Form.Item>

        <br />
        <Button type="primary" htmlType="submit">Ok</Button>
      </Form>
    </Modal>
  );
}

export default ModalResidential;
