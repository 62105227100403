import api from '../api'
const API_URL = "/api/member";

export const memberService = {
    async getAllmember({ id = "", query = "", limit = 20, start = 0 }) {
        const response = await api.get(`${API_URL}?id=${id}&query=${query}&limit=${limit}&start=${start}`);
        return response.data;
    }
    ,
    async exportmember(query) {
        let format = query;

        try {
            const response = await api.get(`${API_URL}/export/data`, {
                params: { query: format },
            });
            if (format.type === 'pdf') {
                console.log('response', response)
                const pdfUrl = api.getUri() + "/api" + response.data.url;
                const link = document.createElement("a");
                link.href = pdfUrl;
                const timestamp = new Date();
                const fileName = `Departments_${timestamp}.pdf`;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else if (format.type === 'excel') {
                const pdfUrl = api.getUri() + "/api/uploads/" + response.data.url;
                const link = document.createElement("a");
                link.href = pdfUrl;
                const timestamp = new Date();
                const fileName = `Members_${timestamp}.xlsx`;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error(`Error exporting member (${format}):`, error);
            alert(`An error occurred while exporting the member as ${format}.`);
        }

    },



    async importmember(_data) {
        const response = await api.post(API_URL + "/import/data", _data);
        return response.data;
    },

    async createmember(_data) {
        const response = await api.post(API_URL, _data);
        return response.data;
    },
    async updatemember(id, updatedData) {
        const response = await api.put(`${API_URL}/${id}`, updatedData);
        return response.data;
    },
    async deletemember(id) {
        const response = await api.delete(`${API_URL}/${id}`);
        return response.data;
    },
    async getmember(id) {
        const response = await api.get(`${API_URL}/detail/${id}`);
        console.log('response.data.member', response.data.member)
        return response.data.member;
    },
    async handlePrint({ start, limit, SelectedCom }) {
        try {
            const response = await api.get(API_URL + `/print/data?id=${SelectedCom}&start=${start}&limit=${limit}`, {
                responseType: "blob",
                headers: {
                    "Content-Type": "application/pdf",
                },
            });

            const blob = new Blob([response.data], { type: "application/pdf" });
            const pdfUrl = URL.createObjectURL(blob);
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src = pdfUrl;
            document.body.appendChild(iframe);
            iframe.onload = () => {
                iframe.contentWindow.print(); // Trigger print
            };
        } catch (error) {
            console.error("Error fetching PDF:", error);
            alert("Failed to fetch or print PDF.");
        }
    }
};
