import axios from "axios";
import api from '../api'

const API_URL = "/api/department";


export const departmentServices = {
    async getAllDepartments({ id, query = "", limit = 20, start = 0 }) {
        try {
            const response = await api.get(`${API_URL}?query=${query}&limit=${limit}&start=${start}&id=${id}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching Departments:', error);
            throw error;
        }
    }
    ,
    async getDepartmentsByName({ }) {
        try {
            const response = await api.get(`${API_URL}/user/id`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    async exportDepartments(query) {
        let format = query;
        try {
            const response = await api.get(`${API_URL}/export`, {
                params: { query: format },
                responseType: format.type === 'pdf' ? "" : "blob",
            });
            if (format.type === 'pdf') {
                const pdfUrl = api.getUri() + "/api" + response.data.url;
                const link = document.createElement("a");
                link.href = pdfUrl;
                const timestamp = new Date();
                const fileName = `Departments_${timestamp}.pdf`;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else if (format.type === 'excel') {
                const contentDisposition = response.headers["content-disposition"];
                let fileName = `Department-List.xlsx`;
                if (contentDisposition) {
                    const match = contentDisposition.match(/filename="?(.+)"?/);
                    if (match) fileName = match[1];
                }
                const blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error(`Error exporting Departments (${format}):`, error);
            alert(`An error occurred while exporting the Departments as ${format}.`);
        }

    },



    async importDepartment(data) {
        const response = await api.post(API_URL + "/import", data);
        return response.data;
    },

    async createDepartment(data) {
        const response = await api.post(API_URL, data);
        return response.data;
    },
    async updateDepartment(id, data) {
        const response = await api.put(`${API_URL}/${id}`, data);
        return response.data;
    },
    async deleteDepartment(id) {
        const response = await api.delete(`${API_URL}/${id}`);
        return response.data;
    },
    async handlePrint(id) {
        try {
            const response = await api.get("/api/department/print?id=" + id, {
                responseType: "blob",
                headers: {
                    "Content-Type": "application/pdf",
                },
            });

            const blob = new Blob([response.data], { type: "application/pdf" });
            const pdfUrl = URL.createObjectURL(blob);

            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src = pdfUrl;
            document.body.appendChild(iframe);

            iframe.onload = () => {
                iframe.contentWindow.print();
            };
        } catch (error) {
            console.error("Error fetching PDF:", error);
            alert("Failed to fetch or print PDF.");
        }
    }
};
