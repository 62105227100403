import { Input, Typography } from 'antd'
import React from 'react'

function FileReport() {
    return (



        <div style={{ backgroundColor: 'white', width: '100%', height: '100%', padding: '24px 24px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Typography.Title level={4}>File Report</Typography.Title>
                <div style={{ width: "400px" }}>
                    <Input

                    />
                </div>
            </div>
        </div>
    )
}

export default FileReport