import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { Table, Button, Flex, Select, Spin, Upload, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { get_MemberShip, ImportMembership } from '../../App/Slice/membershipSlice';
import Swal from 'sweetalert2';
import { get_phases } from '../../App/Slice/phaseSlice';
import { UploadOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Card, Row, Col } from 'antd';
import { uploadFile } from '../../App/Slice/uploadSlice';

const { Text } = Typography;

const PlotAllocationTable = ({ Loading, files, fetchFiles }) => {
    const [data, setData] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [columns, setColumns] = useState([]);
    const [selectedPhase, setSelectedPhase] = useState();
    const { loading: phaseLoading, phases } = useSelector((state) => state.Phase);
    const [tableKey, setTableKey] = useState(0);
    const { file, uploading } = useSelector((state) => state.upload);
    const [dataloading, setDataloading] = useState(false)


    const dispatch = useDispatch();
    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await dispatch(uploadFile(formData)).unwrap();
            notification.success({
                message: 'Upload Successful',
                description: 'Your file has been uploaded successfully.',
            });
            fetchFiles()
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const beforeUpload = (file) => {
        const isExcel =
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isExcel) {
            console.error('You can only upload Excel files!');
        }
        return isExcel || Upload.LIST_IGNORE;
    };

    const handleSelect = (value) => {
        setSelectedPhase(value);
    };

    const handleCardClick = (file) => {
        setSelectedCard(file._id);
        setData(file);
    };

    const handleExtractData = async () => {
        setDataloading(true)
        if (!selectedPhase) {
            notification.error({
                message: 'No Phase Selected',
                description: 'Please select a phase before extracting data.',
            });
            return;
        }
        if (!selectedCard) {
            notification.error({
                message: 'No Card Selected',
                description: 'Please select a card before extracting data.',
            });
            return;
        }
        await dispatch(ImportMembership({ phase: selectedPhase, data: data }))
        dispatch(get_MemberShip({
            start: 1,
            end: 100
        }));
        setDataloading(false)

    };

    useEffect(() => {
        dispatch(get_phases({ query: '' }));
    }, [dispatch]);

    return (
        <div>
            <Flex justify="space-between">
                <h2>Membership Data</h2>
                <div>
                    <Select
                        placeholder="Select Phase"
                        style={{ width: '200px', marginRight: '20px' }}
                        onSelect={handleSelect}
                    >
                        {phases &&
                            phases.map((dta, i) => (
                                <Select.Option key={i} value={dta._id}>
                                    {dta.phase_name}
                                </Select.Option>
                            ))}
                    </Select>

                    <Upload
                        name="file"
                        beforeUpload={beforeUpload}
                        customRequest={({ file }) => handleFileUpload(file)}
                        showUploadList={false}
                    >
                        <Button icon={<UploadOutlined />} disabled={uploading}>
                            {uploading ? 'Uploading...' : 'Click to Upload'}
                        </Button>
                    </Upload>
                </div>
            </Flex>

            <Spin spinning={Loading} fullscreen={Loading}>
                <Row gutter={[16, 16]} justify="center">
                    {files.map((file) => (
                        <Col xs={24} sm={12} md={8} lg={6} key={file._id}>
                            <Card
                                hoverable
                                style={{
                                    textAlign: 'center',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    border:
                                        selectedCard === file._id
                                            ? '2px solid green'
                                            : '1px solid #ddd',
                                }}
                                cover={
                                    <FileExcelOutlined
                                        style={{
                                            fontSize: '48px',
                                            color: selectedCard === file._id ? '#4CAF50' : '#ddd',
                                            marginTop: '20px',
                                        }}
                                    />
                                }
                                onClick={() => handleCardClick(file)}
                            >
                                <Text strong>{file.originalName}</Text>
                                <p style={{ margin: '8px 0', color: 'gray' }}>{file.filePath}</p>
                                <Text type={file.status === 'PENDING' ? 'warning' : 'success'}>
                                    {file.status}
                                </Text>
                                <p style={{ marginTop: '8px', fontSize: '12px', color: '#aaa' }}>
                                    {new Date(file.createdAt).toLocaleString()}
                                </p>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Spin>
            <Spin fullscreen={dataloading} spinning={dataloading} >

            </Spin>

            <Flex justify="center" style={{ marginTop: '20px' }}>
                <Button
                    type="primary"
                    onClick={handleExtractData}
                    disabled={!selectedPhase || !selectedCard}
                >
                    Extract Data
                </Button>
            </Flex>
        </div>
    );
};

export default PlotAllocationTable;
