import React, { useEffect, useRef, useState } from 'react';
import { Table, Button, Input, Dropdown, Menu, Pagination, Card, Flex, Modal, Form, Space, Upload, notification, Typography, Row, Col, Tooltip, Select, Spin, InputNumber, Avatar } from 'antd';
import { FilterOutlined, FileExcelOutlined, FilePdfOutlined, PrinterOutlined, ImportOutlined, PrinterFilled } from '@ant-design/icons';
import {
    deletemember, exportmember, importmember, selectError, selectLoading, addmember, updatemember, handlePrint,
    fetchmember,
    selectmembers,
    addUser,

} from '../app/Service/memberslice';
import {
    fetchCompanies,
    selectCompanies,
    selectcompanies_name,
    getCompaniesByName
} from '../app/Service/companySlice';
import { useDispatch, useSelector } from 'react-redux'
import { uploadSingleFile } from '../app/Service/fileSlice';
import { userService } from '../app/Service/userService';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom'
import MemberShipModal from './MemberShipModal';
import { memberService } from '../app/Service/memberService';
import Compact from 'antd/es/space/Compact';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import api from '../app/api';

dayjs.extend(relativeTime); // ✅ Enable fromNow() functionality

const Member = () => {
    const [valuestext, setvaluestext] = useState('')
    const [Company_id, setcompany_id] = useState('')
    const [modalOpen, setModalOpen] = useState(false);
    const [form] = useForm();
    const [initialValues, setInitialValues] = useState()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const Member = useSelector(selectmembers);
    const loading = useSelector(selectLoading);
    const [isLoading, setLoading] = useState(loading)
    const error = useSelector(selectError);
    const company_name = useSelector(selectcompanies_name)
    const [SelectedCom, setSelectedCom] = useState();
    const scrollRef = useRef(null);
    const [isBottom, setIsBottom] = useState(false);
    const [Users_, setUser_] = useState([]);
    const [limit, setLimit] = useState(100);
    const [start, setStart] = useState(0);

    const handleDelete = (id) => {
        Modal.confirm({
            title: "Are you sure?",
            content: "This action cannot be undone.",
            okText: "Yes, Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk: async () => {
                await dispatch(deletemember(id))
                dispatch(fetchmember({}));

            },
        });
    };


    const columns = [
        {
            title: '#',
            dataIndex: 'rowNumber',
            render: (_text, _record, index) => index + 1,
            width: 50,
        },
        {
            title: 'MSN',
            dataIndex: 'membership_id',
            key: 'membership_id',
            // width: 120,
        },
        {
            title: 'Name',
            dataIndex: 'owner_name',
            key: 'owner_name',
            width: 270,
            render: (text, data) => {
                if (!text) return null; // Handle undefined/null text

                const nameParts = text.split(" ");
                const firstInitial = nameParts[0]?.[0] || "";
                const secondInitial = nameParts[1]?.[0] || "";
                return (
                    <Space onClick={() => { navigate("/member/detail/" + data?._id) }} style={{ cursor: "pointer", color: "blue" }}>
                        <Avatar src={api.getUri() + "/api/" + data?.image} style={{ fontSize: "14px" }}>
                            {firstInitial + secondInitial}
                        </Avatar>
                        <Space direction={"vertical"}>
                            {text}
                            <Typography.Paragraph type={"secondary"}>{data?.fatherName}</Typography.Paragraph>
                        </Space>
                    </Space>
                );
            },
        },
        // {
        //     title: 'Father Name',
        //     dataIndex: 'fatherName',
        //     key: 'fatherName',
        //     width: 200,
        // },
        {
            title: 'Files',
            dataIndex: 'properties_owned',
            key: 'properties_owned',
            render: (data) => data?.length || 0, // Handle undefined case
            width: 50,
        },
        // {
        //     title: 'Email',
        //     dataIndex: 'email',
        //     key: 'email',
        //     width: 200,
        // },
        {
            title: 'CNIC',
            dataIndex: 'cnic',
            key: 'cnic',
            width: 120,
        },
        // {
        //     title: 'CNIC Expiry',
        //     dataIndex: 'cnicExpire',
        //     key: 'cnicExpire',
        //     width: 150,
        //     render: (date) => {
        //         if (!date) return "N/A";

        //         const today = dayjs();
        //         const expiryDate = dayjs(date);
        //         const daysRemaining = expiryDate.diff(today, 'day');

        //         return `${expiryDate.format("YYYY-MM-DD")} (${daysRemaining} days left)`;
        //     }
        // },
        // {
        //     title: 'Address',
        //     dataIndex: 'address',
        //     key: 'address',
        //     width: 250,
        // },
        {
            title: 'Phone',
            dataIndex: 'contact_number',
            key: 'contact_number',
            // width: 150,
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            // width: 150,
        },
        // {
        //     title: 'Nationality',
        //     dataIndex: 'nationality',
        //     key: 'nationality',
        //     width: 150,
        // },
        // {
        //     title: 'Company',
        //     dataIndex: ['company_id', 'companyName'],
        //     key: 'company',
        //     width: 150,
        // },
        // {
        //     title: 'Profession',
        //     dataIndex: 'profession',
        //     key: 'profession',
        //     width: 150,
        // },
        {
            title: 'NTN',
            dataIndex: 'ntn',
            key: 'ntn',
            // width: 160,
        },
        {
            title: 'Passport No.',
            dataIndex: 'passport_number',
            key: 'passport_number',
            // width: 180,
        },
        {
            title: 'Date of Birth',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            // width: 180,
            render: (date) => date ? `${dayjs(date).format("YYYY-MM-DD")} ` : "N/A"
        },

        {
            title: 'Action',
            // key: 'action',
            width: 120,
            render: (_, record) => (
                <Button type="primary" onClick={() => handleEdit(record)}>Edit</Button>
            ),
        },
    ];

    // Example edit function
    const handleEdit = (record) => {
        console.log("Editing:", record);
        setInitialValues(record)
        setModalOpen(true)

        // Add logic to open a modal or form for editing
    };


    const handelUploadFile = async ({ file }) => {
        setLoading(true)
        try {
            if (SelectedCom) {

                const res = await dispatch(uploadSingleFile(file));

                if (res.meta.requestStatus === "fulfilled") {
                    const file = res?.payload.file;

                    await dispatch(importmember({ id: SelectedCom, file }));

                    await dispatch(fetchmember({ id: SelectedCom }));
                    notification.success({
                        message: 'File Uploaded Successfully',
                        description: 'The file was uploaded and companies imported successfully.',
                        placement: 'topRight',
                    });
                    setLoading(false)

                }
            } else {
                notification.error({
                    message: 'Please Select Company',
                    description: 'There was an error uploading the file. Please try again.',
                    placement: 'topRight',
                });
                setLoading(false)
            }

        } catch (error) {
            console.log('error', error)
            notification.error({
                message: 'File Upload Failed',
                description: 'There was an error uploading the file. Please try again.',
                placement: 'topRight',
            });

            console.error('Error handling file upload:', error);
        }
    };

    useEffect(() => {
        dispatch(getCompaniesByName({}));
    }, []);

    useEffect(() => {
        if (company_name?.length > 0) {
            setSelectedCom(company_name[0]?._id)
            // dispatch(fetchmember({ id: company_name[0]?._id, query: "", limit, start }));
        }
    }, [company_name])


    useEffect(() => {
        if (SelectedCom) {
            setUser_([])
            dispatch(fetchmember({ id: SelectedCom, query: valuestext, limit, start }));
        }
    }, [SelectedCom])

    const handleSearch = (e) => {
        if (SelectedCom) {
            setUser_([])
            setvaluestext(e.target.value)
            dispatch(fetchmember({ id: SelectedCom, query: e.target.value, limit }));
        }

    };
    let array1 = Member?.members || []
    let table = [...array1, ...Users_]

    const handleScroll = async () => {
        console.log('scrolling')
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                let array2 = Users_ ? Users_ : []
                if (table.length < Member.totalCount) {
                    setLoading(true)
                    const data = await memberService.getAllmember({ id: SelectedCom, query: valuestext, limit: limit, start: start + limit })
                    setStart(start + limit);
                    let newdata = [...array2, ...data?.members]
                    setUser_(newdata)
                    setLoading(false)
                }
            } else {
                setIsBottom(false);

            }
        }
    };
    const handleScroll_ = async (event) => {
        const { target } = event;
        if (target.scrollTop + target.clientHeight >= target.scrollHeight - 10) {
            let array2 = Users_ ? Users_ : []
            if (table.length < Member.totalCount) {
                setLoading(true)
                const data = await memberService.getAllmember({ id: SelectedCom, query: valuestext, limit: limit, start: start + limit })
                setStart(start + limit);
                let newdata = [...array2, ...data?.members]
                setUser_(newdata)
                setLoading(false)
            }
        }
    };
    return (
        <Card title="Member List" extra={<><Space >
            <Button type="primary" onClick={() => {
                setModalOpen(true)
                setLimit(100)
            }}>+ Add Member</Button>

        </Space></>}>
            <MemberShipModal
                open={modalOpen}
                onCancel={() => {
                    setModalOpen(false)
                    setInitialValues(null)
                    setUser_([])

                }}
                title={initialValues ? "Update Member" : "Add Member"}
                initialValues={initialValues}
                SelectedCom={SelectedCom}
                limit={limit}
            />

            <div>

                <Flex justify={"space-between"} align={"center"}>
                    <div>
                        <Space>
                            <Select
                                value={SelectedCom}  // Set the first company's _id as default value
                                options={company_name?.map(company => ({
                                    label: company?.companyName,
                                    value: company?._id,
                                }))}
                                style={{ width: 300 }}
                                onChange={(e) => setSelectedCom(e)}
                            />
                            <Input
                                placeholder="Search Employee"
                                onChange={handleSearch}
                                value={valuestext}
                                style={{ width: 300, }}
                            />
                        </Space>

                    </div>
                    <div style={{ marginBottom: 16 }}>
                        <Typography.Text style={{ marginRight: "20px" }}>
                            Showing {table?.length} of {Member?.totalCount}
                        </Typography.Text>

                        <Tooltip title="Import">
                            <Upload
                                accept=".xls,.xlsx"
                                onChange={handelUploadFile}
                                showUploadList={false}
                                beforeUpload={() => false}>
                                <Button variant={'outlined'} color={'danger'} icon={<ImportOutlined />} style={{ marginRight: 8 }}></Button>
                            </Upload>
                        </Tooltip>

                        <Tooltip title="Export in to Excel"> <Button variant={'outlined'} onClick={async () => {
                            setLoading(true)
                            await dispatch(exportmember({ type: "excel", id: SelectedCom, start, limit }))
                            setLoading(false)

                        }} color={"primary"} icon={<FileExcelOutlined />} style={{ marginRight: 8 }} /></Tooltip>
                        <Tooltip title="Export in to Pdf">
                            <Button variant={'outlined'} onClick={async () => {
                                setLoading(true)
                                await dispatch(exportmember({ type: "pdf", id: SelectedCom, start, limit }))
                                setLoading(false)

                            }} icon={<FilePdfOutlined />} style={{ marginRight: 8, borderColor: "green", color: "green" }} /> </Tooltip>
                        <Tooltip title="Print"><Button variant={'outlined'} onClick={async () => {
                            setLoading(true)
                            await dispatch(handlePrint({ start, limit, SelectedCom }))
                            setLoading(false)
                        }} style={{ borderColor: "orange", color: "orange" }} icon={<PrinterFilled />} /> </Tooltip>
                    </div>
                </Flex>
                <Table
                    columns={columns}
                    dataSource={table}
                    loading={isLoading}
                    scroll={{ y: `calc(100vh - 180px)` }} // Enables vertical scrolling with a height of 600px
                    pagination={false} // Optional: Disable pagination if you want full scrolling
                    onScroll={handleScroll_}

                />



            </div >
        </Card >
    );
};

export default Member;
