import React, { useState } from "react";
import { Button, Divider, Flex, Modal, Progress, Space, Upload, message, Typography, notification, Row, Col, Card, Statistic } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, InboxOutlined } from "@ant-design/icons";
import { uploadSingleFile } from "../app/Service/fileSlice";
import { useDispatch } from "react-redux";

const { Text } = Typography;

function ImportCharges({ Company }) {
    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const [summary, setSummary] = useState({ added: 0, updated: 0, skipped: 0, missed: 0 });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleUploadFile = async ({ file }) => {
        try {
            if (!Company) {
                notification.error({
                    message: "Please Select a Company",
                    description: "Company selection is required before uploading a file.",
                    placement: "topRight",
                });
                return;
            }

            setSummary({ added: 0, updated: 0, skipped: 0, missed: 0 });
            setProgress(0);
            setLoading(true);

            const res = await dispatch(uploadSingleFile(file));
            if (res.meta.requestStatus !== "fulfilled") {
                throw new Error("File upload failed");
            }

            const fileId = res?.payload.file._id;
            const url = process.env.REACT_APP_SEVER;
            const response = await fetch(
                `${url}/api/charges/import?fileId=${fileId}&id=${Company}`,
                {
                    method: "GET",
                    credentials: "include",
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value, { stream: true }).trim();
                const lines = chunk.split("\n");

                for (const line of lines) {
                    if (!line.startsWith("data: ")) continue;

                    const data = JSON.parse(line.replace("data: ", ""));
                    console.log('data', data)
                    if (data.error) {
                        notification.error({
                            message: "Import Error",
                            description: data.error,
                            placement: "topRight",
                        });
                        // setLoading(false);
                        // return;
                    }

                    if (data.progress !== undefined) setProgress(data.progress);
                    if (data.addedRecords !== undefined) setSummary(prev => ({ ...prev, added: data.addedRecords }));
                    if (data.updatedRecords !== undefined) setSummary(prev => ({ ...prev, updated: data.updatedRecords }));

                    console.log('data.skippedRecords', data.skippedRecords)

                    if (data.skippedRecords !== undefined) setSummary(prev => ({ ...prev, skipped: data.skippedRecords }));
                    if (data.missedRecords !== undefined) setSummary(prev => ({ ...prev, missed: data.missedRecords }));

                    if (data.success) {
                        notification.success({
                            message: "Import Completed",
                            description: "The file import was successful.",
                            placement: "topRight",
                        });
                        setLoading(false);
                    }
                }
            }
        } catch (error) {
            console.error("File upload error:", error);
            notification.error({
                message: "Upload Failed",
                description: "There was an error uploading the file. Please try again.",
                placement: "topRight",
            });
            setLoading(false);
        }
    };
    return (
        <div>
            <Button onClick={() => setOpen(true)} color="cyan" variant="filled" loading={loading}>
                Import Charges
            </Button>
            <Modal
                title="Import Charges"
                open={open}
                onCancel={() => setOpen(false)}
                style={{ top: 20 }}
                width={800}
                footer={null}
            >
                <Upload.Dragger
                    name="file"
                    multiple={false}
                    maxCount={1}
                    showUploadList={true}
                    beforeUpload={() => false}
                    accept=".xls,.xlsx"
                    onChange={handleUploadFile}
                    disabled={loading}
                    style={{ padding: 20, borderRadius: 10 }}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{ color: "#1890ff", fontSize: 48 }} />
                    </p>
                    <p className="ant-upload-text">Click or drag an Excel file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Only .xls and .xlsx files are allowed. You can upload only one file at a time.
                    </p>
                </Upload.Dragger>
                <Divider>Progress</Divider>
                <Progress percent={progress} />

                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Card variant="borderless">
                            <Statistic
                                title="Added"
                                value={summary?.added}
                                // precision={2}
                                valueStyle={{
                                    color: '#3f8600',
                                }}
                                prefix={<ArrowUpOutlined />}
                            // suffix="%"
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card variant="borderless">
                            <Statistic
                                title="Updated"
                                value={summary?.updated}
                                valueStyle={{
                                    color: 'blue',
                                }}
                                prefix={<ArrowUpOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card variant="borderless">
                            <Statistic
                                title="Idle"
                                value={summary?.skipped}
                                valueStyle={{
                                    color: '#cf1322',
                                }}
                                prefix={<ArrowDownOutlined />}
                            />
                        </Card>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
}

export default ImportCharges;
