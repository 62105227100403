import axios from "axios";
import api from "../api";

const API_URL = "/api/co/account";

// Fetch all accounts
const getAccounts = async () => {
    try {
        const response = await api.get(API_URL);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

// Add a new account
const addAccount = async (accountData) => {
    try {
        const response = await api.post(`${API_URL}/add`, accountData);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

// Delete an account
const deleteAccount = async (accountId) => {
    try {
        const response = await api.delete(`${API_URL}/${accountId}`);
        return response.data;
    } catch (error) {
        return handleError(error);
    }
};

// Handle API errors
const handleError = (error) => {
    if (error.response) {
        // Server responded with a status other than 2xx
        console.error("API Error:", error.response.data);
        return { error: error.response.data.message || "Server Error" };
    } else if (error.request) {
        // No response from the server
        console.error("No response from server:", error.request);
        return { error: "No response from server. Please try again later." };
    } else {
        // Other errors
        console.error("Error:", error.message);
        return { error: error.message || "Something went wrong" };
    }
};

const accountService = {
    getAccounts,
    addAccount,
    deleteAccount,
};

export default accountService;
