import React, { useEffect, useState } from 'react'
import { Button, Card, Flex, Select, Space, Table } from 'antd'
import ModalResidentials from './ModalResidential'
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, fetchResidentials, selectResidentials } from '../app/Service/DataSlice';

function Residentials() {
  const [OpenResidentials, setOpenResidentials] = useState(false);
  const dispatch = useDispatch();
  const company_name = useSelector(selectcompanies_name);
  const Residentials = useSelector(selectResidentials);
  const [company, setCompany] = useState(null);

 

  useEffect(() => {
    if (company_name.length > 0) {
      const defaultCompanyId = company_name[0]?._id;
      setCompany(defaultCompanyId);
      dispatch(fetchResidentials({ modelName: "Residential", company_id: defaultCompanyId }));
    }
  }, [company_name, dispatch]);

  // Handle company selection change
  const handleCompanyChange = (value) => {
    setCompany(value);
    dispatch(fetchResidentials({ modelName: "Residential", company_id: value }));
  };
  return (
    <>
      <ModalResidentials OpenResidential={OpenResidentials} setOpenResidential={setOpenResidentials} />

      <Card
        title={"Residentials"}
        extra={
          <Select
            style={{ width: "200px" }}
            value={company}
            placeholder="Select Company"
            options={company_name?.map((dta) => ({ label: dta.companyName, value: dta._id }))}
            onChange={handleCompanyChange}
          />
        }
      >
        <Flex style={{ marginBottom: "10px" }}>
          <Space>
            <Button type="primary" onClick={() => setOpenResidentials(true)}>+ Add Residentials</Button>
          </Space>
        </Flex>

        <Table
          columns={[
            {
              key: "1",
              title: "Residentials Name",
              dataIndex: "Residential",
              width: "80%"
            },
            {
              key: "2",
              title: "Action",
              dataIndex: "_id",
              render: (id) => <Button type='text' danger>Delete</Button>
            }
          ]}
          dataSource={Residentials?.data?.map((Residentials) => ({ key: Residentials._id, ...Residentials }))}
        />
      </Card>
    </>
  );
}

export default Residentials;
