import { Button, Form, Input, Modal, Select, notification } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createData, fetchPhases } from '../app/Service/DataSlice';
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';

function ModalPhase({ openPhase, setOpenPhase }) {
    const [form] = Form.useForm(); // Use Ant Design Form instance
    const dispatch = useDispatch();
    const company_name = useSelector(selectcompanies_name);

    // Notification function
    const openNotification = (type, message, description) => {
        notification[type]({
            message,
            description,
            placement: 'topRight',
        });
    };

    const handleFinish = async (values) => {
        const res = await dispatch(createData({ modelName: "Phase", data: values }));

        if (res.meta.requestStatus === "fulfilled") {
            openNotification('success', 'Success', 'Phase created successfully');
            form.resetFields(); // Reset form fields
            setOpenPhase(false); // Close modal
            dispatch(fetchPhases({ modelName: "Phase", company_id: values?.company_id }));

        } else {
            openNotification('error', 'Error', res.payload?.error || 'Something went wrong');
        }
    };

    useEffect(() => {
        dispatch(getCompaniesByName({}));
    }, [dispatch]);

    return (
        <Modal
            open={openPhase}
            title="Phase"
            onCancel={() => {
                form.resetFields(); // Reset form when closing modal
                setOpenPhase(false);
            }}
            footer={null}
        >
            <Form form={form} onFinish={handleFinish} layout="vertical">
                {/* Company Name */}
                <Form.Item label="Company" name="company_id" rules={[{ required: true, message: "Please select a company" }]}>
                    <Select placeholder="Select Company" options={company_name?.map((dta) => ({ label: dta.companyName, value: dta._id }))} />
                </Form.Item>

                {/* Phase Name */}
                <Form.Item label="Phase" name="Phase" rules={[{ required: true, message: "Please enter a phase name" }]}>
                    <Input placeholder="Phase Name" />
                </Form.Item>

                <br />
                <Button type="primary" htmlType="submit">Ok</Button>
            </Form>
        </Modal>
    );
}

export default ModalPhase;
