import React, { useEffect, useState } from 'react'
import { Button, Card, Flex, Select, Space, Table } from 'antd'
import ModalBlock from './ModalBlock'
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, fetchBlocks, selectBlocks, fetchPhases, selectPhases } from '../app/Service/DataSlice';

function Block({key}) {
  const [OpenBlock, setOpenBlock] = useState(false);
  const dispatch = useDispatch();
  const company_name = useSelector(selectcompanies_name);
  const Blocks = useSelector(selectBlocks);
  const [company, setCompany] = useState(null);

  

  useEffect(() => {
    if (company_name.length > 0 ) {
      const defaultCompanyId = company_name[0]?._id;
      setCompany(defaultCompanyId);
      dispatch(fetchBlocks({ modelName: "Block", company_id: defaultCompanyId }));
      dispatch(fetchPhases({ modelName: "Phase", company_id: defaultCompanyId }));

    }
  }, [company_name, dispatch]);

  // Handle company selection change
  const handleCompanyChange = (value) => {
    setCompany(value);
    dispatch(fetchBlocks({ modelName: "Block", company_id: value }));
  };
  return (
    <>
      <ModalBlock OpenBlock={OpenBlock} setOpenBlock={setOpenBlock} />

      <Card
        title={"Block"}
        extra={
          <Select
            style={{ width: "200px" }}
            value={company}
            placeholder="Select Company"
            options={company_name?.map((dta) => ({ label: dta.companyName, value: dta._id }))}
            onChange={handleCompanyChange}
          />
        }
      >
        <Flex style={{ marginBottom: "10px" }}>
          <Space>
            <Button type="primary" onClick={() => setOpenBlock(true)}>+ Add Block</Button>
          </Space>
        </Flex>

        <Table
          columns={[
            {
              key: "1",
              title: "Phase Name",
              dataIndex: ["Phase_id", "Phase"],
              width: "40%"
            },
            {
              key: "1",
              title: "Block Name",
              dataIndex: "Block",
              width: "40%"
            },
            {
              key: "2",
              title: "Action",
              dataIndex: "_id",
              render: (id) => <Button type='text' danger>Delete</Button>
            }
          ]}
          dataSource={Blocks?.data?.map((Block) => ({ key: Block._id, ...Block }))}
        />
      </Card>
    </>
  );
}

export default Block;
