import React, { useEffect, useRef, useState } from 'react';
import { Table, Button, Input, Card, Flex, Form, Space, Select, Tag, Typography, Divider } from 'antd';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import { fetchplot, selectLoading, } from '../app/Service/plotSlice';
import { useDispatch, useSelector } from 'react-redux'
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { useForm } from 'antd/es/form/Form';
import { fetchRejectAndAccept, fetchScrollTransferplot, fetchTransferplot, selecttransferPlot } from '../app/Service/AssignSlice';
import Swal from 'sweetalert2';
import TransferApplicationForm from '../Reports/TransferFormPrint';
const { Title } = Typography
const TransferList = () => {
  const [start, setStart] = useState(0)
  const [limit, setLimit] = useState(50)
  const [Company, setCompany] = useState()
  const company_name = useSelector(selectcompanies_name)
  const Plots = useSelector(selecttransferPlot)
  const dispatch = useDispatch()
  const loading = useSelector(selectLoading)
  const scrollRef = useRef(null);
  const [form] = useForm()
  const [Plots__, setPlots__] = useState([]);
  const contentRef = useRef()
  useEffect(() => {
    dispatch(getCompaniesByName({}));
  }, []);

  useEffect(() => {
    if (company_name?.length > 0) {
      form.setFieldsValue({
        company: company_name[0]?._id
      })
      setCompany(company_name[0]?._id)
      dispatch(fetchTransferplot({ id: company_name[0]?._id, query: "", limit, start }));
    }
  }, [company_name])

  useEffect(() => {
    if (Company?.length > 0) {
    }
  }, [Company])

  const handleFiinish = async (values) => {
    console.log('values', values)
    setPlots__([])
    dispatch(fetchTransferplot({ id: values.company, type: values?.type, query: values.text, limit, start: 0 }));
  }
  const showSelectDialog = (id) => {
    Swal.fire({
      title: "Are you sure you want to transfer the file?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      input: "select",
      inputOptions: {
        Transfered: "Transfered",
        Rejected: "Rejected",
      },
      inputPlaceholder: "Select Transfer Status",
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        dispatch(fetchRejectAndAccept({ id, status: result.value })).then((res) => {

          console.log('message', res)
          if (res.meta.requestStatus === "fulfilled") {
            Swal.fire({
              title: "Success!",
              text: `Transfer request ${result.value.toLowerCase()} successfully.`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: res.payload || "Something went wrong!",
              icon: "error",
            });
          }
        });
      }
    });
  };
  let array1 = Plots || []
  // let table = [...array1, ...Plots__]
  const columns = [
    {
      title: "#",
      key: "index",
      width: 50,
      render: (text, record, index) => index + 1 // Adding row count
    },
    { title: "File Number", dataIndex: ["File", "file_id", "File_ID"], key: "File_ID", width: 100 },

    {
      title: "Sellers",
      dataIndex: "sellers",
      key: "sellers_name",
      width: 250,
      render: (Owners) => {
        if (!Array.isArray(Owners) || Owners.length === 0) return "N/A";  // Ensure safe access

        return (
          <div>
            {Owners.length > 0 ? (
              Owners.map((owner) => (
                <div key={owner?._id}>
                  <strong>{owner?.name?.owner_name || "N/A"}</strong>
                  <br />
                  {owner?.name?.cnic || "No CNIC"} <Divider type={"vertical"} /> {owner?.name?.membership_id || "No Membership ID"}
                </div>
              ))
            ) : (
              <p>No owners found.</p>
            )}
          </div>

        );
      },
    },


    {
      title: "Purchasers",
      dataIndex: "purchasers",
      key: "purchasers_name",
      width: 250,
      render: (data) => {
        if (!Array.isArray(data) || data.length === 0) return "N/A";  // Ensure safe access

        return (
          <div>
            {data.map((owner) => (
              <div key={owner?._id}>
                <strong>{owner?.name?.owner_name || "N/A"}</strong>
                <br />
                {owner?.name?.cnic + "-" + owner?.name?.membership_id}
              </div>
            ))}
          </div>
        );
      },
    },

    {
      title: "Agent",
      dataIndex: "agent",
      key: "name",
      width: 150,
      render: (agent) => {
        return (
          <div>
            <div >
              <strong>{agent?.name || "N/A"}</strong>
              <br />
              {agent?.cnic || "N/A"}
            </div>
          </div>
        );
      },
    },


    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (status) => <Tag color={status === "Pending" ? "#2db7f5" : status === "Rejected" ? "#f50" : "#00bf85"
      } > {status}</Tag >,
    },
    { title: "Print", dataIndex: "printStatus", key: "print", width: 100, render: (text, data, i) => text === "New" ? <TransferApplicationForm data={data} index={i} /> : "N/A" },

    {
      title: "Action",
      dataIndex: ["File", "file_id", "file_status"],
      key: "status",
      width: 100,
      render: (status, records, index) => <Button disabled={records?.status !== "Pending" || records?.purchasers?.length < 1} size={"small"} onClick={() => showSelectDialog(records?._id)}>Respond</Button>,
    },
  ];

  const handleScroll_ = async (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 1 && Plots?.count > Plots?.data?.length) {
      console.log('bottom')
      const values = form.getFieldsValue();
      dispatch(fetchScrollTransferplot({ id: values.company, type: values?.type, query: values.text, limit, start: start + limit }));
      setStart(start + limit)
    }
  };

  return (
    <Card style={{ height: "100vh" }} title={<Title level={4} style={{ margin: 0 }}>
      📂 Transfer List
    </Title>}  >
      <div>
        <Flex justify={"space-between"} align={"center"}>
          <Form form={form} onFinish={handleFiinish} style={{ marginBottom: "5px" }}>
            <Space>
              <Form.Item noStyle name={"company"}>
                <Select
                  placeholder="Select Company"
                  value={Company}
                  options={company_name?.map(company => ({
                    label: company?.companyName,
                    value: company?._id,
                  }))}
                  style={{ width: 200 }}
                  onChange={(e) => setCompany(e)}
                />
              </Form.Item>
              <>
                <Form.Item noStyle name={"type"} >
                  <Select placeholder="Select type"
                    allowClear
                    style={{ width: 180 }}
                    options={[
                      { label: "File Number", value: "file" },
                      // { label: "Name", value: "name" },
                      { label: "CNIC", value: "cnic" },
                      { label: "MSM", value: "msm" },
                      { label: "Agent", value: "agent" },
                      { label: "Status", value: "status" },
                    ]}
                  />
                </Form.Item>

                <Form.Item noStyle name={"text"}>
                  <Input
                    placeholder="Search File"
                    style={{ width: 180 }}
                  />
                </Form.Item>
              </>
              <Button htmlType='submit' icon={<SearchOutlined />} type="primary"> </Button>
            </Space>
          </Form>

          <div style={{ marginBottom: 16 }}>
            <span color='success' style={{ marginRight: "10px" }}>
              show {Plots?.data?.length} of {Plots?.count}
            </span>
          </div>
        </Flex>
        <Table
          scroll={{ y: `calc(100vh - 150px)` }}
          columns={columns}
          dataSource={Plots?.data}
          onScroll={handleScroll_}
          pagination={false}
          bordered={1}
          size={"small"}

        >
        </Table>
      </div>
    </Card >
  );
};
export default TransferList