import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { Typography } from "antd";

// Sample memberships data
const memberships = [
    {
        _id: "673d5d3bb0da540eb30c6607",
        newMembershipId: "ICHS-2",
        oldMembershipId: "T.26519-4",
        userId: "66e3990efc17b1d3bd90e4aa",
        phase: {
            _id: "673d5cffb0da540eb30c5f89",
            phase_name: "Phase 1",
            phase_location: "https://maps.app.goo.gl/8HHHhCRBP1tguwZq9",
        },
        block: {
            _id: "673d5d3bb0da540eb30c65f7",
            block_name: "Exec.",
        },
        status: "Draft",
        landSize: 500,
        landUnit: "Sq. Yds",
        address: "1",
        plotNo: "2",
        fileCount: 2,
        owners: [
            {
                owner: {
                    ownerName: "Mr. Muhammad Iftikharullah Qureshi",
                    phoneNumber: "+03-001-2345333",
                    cnic: "15101-0417855-9",
                    permanentAddress: "House No.26/E, Street No.75, Sector G-6/4, Islamabad.",
                },
                share: "100",
            },
        ],
        features: [],
        nominees: [],
    },
    {
        _id: "673d5d3bb0da540eb30c660e",
        newMembershipId: "ICHS-3",
        oldMembershipId: "T.26519-1",
        userId: "66e3990efc17b1d3bd90e4aa",
        phase: {
            _id: "673d5cffb0da540eb30c5f89",
            phase_name: "Phase 1",
            phase_location: "https://maps.app.goo.gl/8HHHhCRBP1tguwZq9",
        },
        block: {
            _id: "673d5d3bb0da540eb30c65f7",
            block_name: "Exec.",
        },
        status: "Draft",
        landSize: 500,
        landUnit: "Sq. Yds",
        address: "1",
        plotNo: "3",
        fileCount: 3,
        owners: [
            {
                owner: {
                    ownerName: "Mr. Muhammad Iftikharullah Qureshi",
                    phoneNumber: "+03-001-2345333",
                    cnic: "15101-0417855-9",
                    permanentAddress: "House No.26/E, Street No.75, Sector G-6/4, Islamabad.",
                },
                share: "100",
            },
        ],
        features: [],
        nominees: [],
    },
];

// Flatten nested fields into rows
const rows = memberships.map((membership, index) => ({
    id: index + 1,
    membershipId: membership.newMembershipId,
    oldMembershipId: membership.oldMembershipId,
    phaseName: membership.phase?.phase_name || "N/A",
    phaseLocation: membership.phase?.phase_location || "N/A",
    blockName: membership.block?.block_name || "N/A",
    status: membership.status,
    landSize: `${membership.landSize} ${membership.landUnit}`,
    address: membership.address,
    plotNo: membership.plotNo,
    fileCount: membership.fileCount,
    ownerName: membership.owners[0]?.owner?.ownerName || "N/A",
    ownerPhone: membership.owners[0]?.owner?.phoneNumber || "N/A",
    ownerCNIC: membership.owners[0]?.owner?.cnic || "N/A",
    ownerAddress: membership.owners[0]?.owner?.permanentAddress || "N/A",
}));

// Define columns for DataGrid
const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "membershipId", headerName: "Membership ID", width: 150 },
    { field: "oldMembershipId", headerName: "Old Membership ID", width: 150 },
    { field: "phaseName", headerName: "Phase Name", width: 130 },
    { field: "phaseLocation", headerName: "Phase Location", width: 200 },
    { field: "blockName", headerName: "Block Name", width: 130 },
    { field: "status", headerName: "Status", width: 100 },
    { field: "landSize", headerName: "Land Size", width: 130 },
    { field: "address", headerName: "Address", width: 100 },
    { field: "plotNo", headerName: "Plot No", width: 100 },
    // { field: "fileCount", headerName: "File Count", width: 100 },
    { field: "ownerName", headerName: "Owner Name", width: 200 },
    { field: "ownerPhone", headerName: "Owner Phone", width: 150 },
    { field: "ownerCNIC", headerName: "Owner CNIC", width: 200 },
    { field: "ownerAddress", headerName: "Owner Address", width: 300 },
];

const MembershipGrid = () => {
    return (
        <Box sx={{ height: 600, width: "100%", marginTop: 4 }}>
            <Typography.Title level={3}>Owner Member Ship</Typography.Title>
            <DataGrid

                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10]}
            />
        </Box>
    );
};

export default MembershipGrid;
