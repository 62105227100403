import React, { useEffect, useState } from "react";
import { Table, Button, message, Card, Tag, Space, Modal, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchPurchaseOrders, deletePurchaseOrder } from "../app/Service/purchaseOrderSlice";
import { Input, Select, DatePicker, Row, Col } from "antd";
import { selectPurchaseOrders, selectPurchaseOrderLoading } from "../app/Service/purchaseOrderSlice";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function PurchaseOrderList() {
    const dispatch = useDispatch();
    const purchaseOrders = useSelector(selectPurchaseOrders);
    const loading = useSelector(selectPurchaseOrderLoading);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    // Fetch purchase orders on component mount
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        dispatch(fetchPurchaseOrders());

    }, [dispatch]);

    const handleDelete = async (id) => {
        try {
            await dispatch(deletePurchaseOrder(id)).unwrap();
            message.success("Purchase order deleted successfully!");
        } catch (error) {
            message.error("Failed to delete purchase order.");
        }
    };
    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            // setLoading(true);
            // await onSubmit(values);
            // setLoading(false);
            // form.resetFields();
            // onClose();
        } catch (error) {
            // setLoading(false);
        }
    };

    const columns = [

        {
            title: "#",
            dataIndex: "srNo",
            key: "srNo",
            render: (text, record, i) => i + 1
        },

        {
            title: "Invoice",
            dataIndex: "srNo",
            key: "srNo",
        },
        {
            title: "Supplier",
            dataIndex: "supplier",
            key: "supplier",
            render: (supplier) => supplier?.name || "Unknown",
        },
        {
            title: "Order Date",
            dataIndex: "orderDate",
            key: "orderDate",
            render: (date) => new Date(date).toLocaleDateString(),
        },
        {
            title: "Grand Total ",
            dataIndex: "grandTotal",
            key: "grandTotal",
            render: (total) => `${total?.toFixed(2)}`,
        },
        {
            title: "Payment Status",
            dataIndex: "paymentStatus",
            key: "paymentStatus",
            render: (status) => {
                let color = "default";
                if (status === "Paid") color = "#87d068";
                else if (status === "Partial") color = "#2db7f5";
                else if (status === "Unpaid") color = "#f50";
                return <Tag color={color}>{status.toUpperCase()}</Tag>;
            },
        },

        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_, record) => (
                <>

                    <Button
                        color="pink"
                        variant={"filled"}
                        size={"small"}

                    >
                        {record?.status}
                    </Button>
                </>
            ),
        },

        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <>
                    {record?.status === "Returned" ? "" :
                        <Button
                            color="purple"
                            size={"small"}

                            variant={"filled"}
                            onClick={() => {

                                navigate(`/purchase-orders/edit/${record._id}?PurchaseReturn=true`);
                            }}
                        >
                            Return Order
                        </Button>}
                </>
            ),
        }

    ];

    return (
        <>

            <Modal
                width={800}
                style={{ top: 20 }}
                open={isModalOpen}
                title="Payment"
                onCancel={() => setIsModalOpen(false)}
                footer={[
                    <Button key="cancel" onClick={() => setIsModalOpen(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Submit Payment
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical">
                    <Row gutter={16}>
                        {/* Invoice Selection */}
                        <Col span={12}>
                            <Form.Item
                                name="invoiceId"
                                label="Select Invoice"
                                rules={[{ required: true, message: "Please select an invoice" }]}
                            >
                                <Select placeholder="Select Invoice">
                                    {/* {invoices.map((invoice) => (
                                        <Select.Option key={invoice._id} value={invoice._id}>
                                            {invoice.srNo} - {invoice.supplierName}
                                        </Select.Option>
                                    ))} */}
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* Supplier Selection */}
                        <Col span={12}>
                            <Form.Item
                                name="supplierId"
                                label="Select Supplier"
                                rules={[{ required: true, message: "Please select a supplier" }]}
                            >
                                <Select placeholder="Select Supplier">
                                    {/* {suppliers.map((supplier) => (
                                        <Select.Option key={supplier._id} value={supplier._id}>
                                            {supplier.name}
                                        </Select.Option>
                                    ))} */}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        {/* Payment Date */}
                        <Col span={12}>
                            <Form.Item
                                name="paymentDate"
                                label="Payment Date"
                                rules={[{ required: true, message: "Please select a payment date" }]}
                            >
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        {/* Amount Paid */}
                        <Col span={12}>
                            <Form.Item
                                name="amountPaid"
                                label="Amount Paid"
                                rules={[{ required: true, message: "Please enter the payment amount" }]}
                            >
                                <Input type="number" placeholder="Enter amount" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        {/* Payment Method */}
                        <Col span={12}>
                            <Form.Item
                                name="paymentMethod"
                                label="Payment Method"
                                rules={[{ required: true, message: "Please select a payment method" }]}
                            >
                                <Select placeholder="Select Payment Method">
                                    <Select.Option value="Cash">Cash</Select.Option>
                                    <Select.Option value="Bank Transfer">Bank Transfer</Select.Option>
                                    <Select.Option value="Credit Card">Credit Card</Select.Option>
                                    <Select.Option value="Cheque">Cheque</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* Transaction ID (Optional) */}
                        <Col span={12}>
                            <Form.Item name="transactionId" label="Transaction ID (if applicable)">
                                <Input placeholder="Enter Transaction ID" />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Remarks (Optional) */}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="remarks" label="Remarks">
                                <Input.TextArea rows={3} placeholder="Enter any additional remarks" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Card title="Payment" style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }} extra={<Space>
                <Button color="purple" variant="filled" onClick={() => setIsModalOpen(true)}>
                    Make Payment
                </Button>
            </Space>}>
                <Table
                    dataSource={purchaseOrders?.filter((item) => (item.status === "Completed" || item.status === "Returned")) || []}
                    columns={columns}
                    loading={loading}
                    rowKey="_id"
                    pagination={{ pageSize: 12 }}
                />
            </Card>
        </>

    );
}

export default PurchaseOrderList;
