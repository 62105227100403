import api from '../api';

const API_URL = '/api/charges';

export const fetchChargesApi = async ({ id, fid, query, limit = 40, start = 0 }) => {
    console.log('Request Params:', { id, fid, query, limit, start });
    try {
        const response = await api.get(
            `${API_URL}?id=${id}&fid=${fid}&query=${query}&limit=${limit}&start=${start}`
        );
        return response.data;
    } catch (error) {
        throw error.response?.data || 'Error fetching data';
    }
};

export const addChargesApi = async (data) => {
    try {
        const response = await api.post(API_URL, data);
        return response.data;
    } catch (error) {
        throw error.response?.data || 'Error add data';
    }
};
export const updateChargesApi = async (data) => {
    try {
        const response = await api.put(API_URL, data);
        return response.data;
    } catch (error) {
        throw error.response?.data || 'Error update data';
    }
};

export const ReceviePayments = async (data) => {
    try {
        const response = await api.post(API_URL+"/payment", data);
        return response.data;
    } catch (error) {
        throw error.response?.data || 'Error update data';
    }
};

