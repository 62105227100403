import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { companyService } from "./companyService";

// Fetch companies
export const fetchCompanies = createAsyncThunk(
    "companies/fetchAll",
    async ({ query, limit, start }, { rejectWithValue }) => {
        try {
            return await companyService.getAllCompanies({ query, limit, start });
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.error : error.message);
        }
    }
);
export const getCompaniesByName = createAsyncThunk(
    "companies/name",
    async ({ }, { rejectWithValue }) => {
        try {
            return await companyService.getCompaniesByName({});
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data.error : error.message);
        }
    }
);

export const handlePrint = createAsyncThunk(
    "companies/print",
    async (_, { rejectWithValue }) => {
        try {
            return await companyService.handlePrint();
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

export const exportCompanies = createAsyncThunk(
    "companies/export",
    async (query, { rejectWithValue }) => {
        try {
            return await companyService.exportCompanies(query);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Add new company
export const addCompany = createAsyncThunk(
    "companies/add",
    async (companyData, { rejectWithValue }) => {
        try {
            return await companyService.createCompany(companyData);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Import company data
export const importCompany = createAsyncThunk(
    "companies/import",
    async (companyData, { rejectWithValue }) => {
        try {
            return await companyService.importCompany(companyData);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Update existing company
export const updateCompany = createAsyncThunk(
    "companies/update",
    async ({ id, updatedData }, { rejectWithValue }) => {
        try {
            return await companyService.updateCompany(id, updatedData);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Delete company
export const deleteCompany = createAsyncThunk(
    "companies/delete",
    async (id, { rejectWithValue }) => {
        try {
            await companyService.deleteCompany(id);
            return id;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

const companySlice = createSlice({
    name: "companies",
    initialState: {
        companies: [],
        loading: false,
        error: null,
        importLoading: false, // Track import loading state
        importError: null,   // Track import error state
        importSuccess: false, // Track import success state
        companies_name: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch companies
            .addCase(fetchCompanies.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCompanies.fulfilled, (state, action) => {
                state.loading = false;
                state.companies = action.payload;
            })
            .addCase(fetchCompanies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })

            .addCase(getCompaniesByName.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompaniesByName.fulfilled, (state, action) => {
                state.loading = false;
                state.companies_name = action.payload;
            })
            .addCase(getCompaniesByName.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            // Add company
            .addCase(addCompany.fulfilled, (state, action) => {

            })

            // Update company
            .addCase(updateCompany.fulfilled, (state, action) => {

            })

            // Delete company
            .addCase(deleteCompany.fulfilled, (state, action) => {

            })

            // Import companies
            .addCase(importCompany.pending, (state) => {
                state.importLoading = true;
                state.importSuccess = false; // Reset success flag when import starts
                state.importError = null;    // Reset any previous error
            })
            .addCase(importCompany.fulfilled, (state, action) => {
                state.importLoading = false;
                state.importSuccess = true;  // Set success flag when import completes

            })
            .addCase(importCompany.rejected, (state, action) => {
                state.importLoading = false;
                state.importError = action.error.message;  // Capture error message
            });
    },
});

export const selectCompanies = (state) => state.companySlice.companies;
export const selectcompanies_name = (state) => state.companySlice.companies_name;
export const selectLoading = (state) => state.companySlice.loading;
export const selectError = (state) => state.companySlice.error;
export const selectImportLoading = (state) => state.companySlice.importLoading; // Get import loading state
export const selectImportError = (state) => state.companySlice.importError;   // Get import error state
export const selectImportSuccess = (state) => state.companySlice.importSuccess; // Get import success state

export default companySlice.reducer;
