import { Button, Form, Input, Modal, Select, notification } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createData, fetchStatus } from '../app/Service/DataSlice';
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';

function ModalStatus({ OpenStatus, setOpenStatus }) {
  const [form] = Form.useForm(); // Use Ant Design Form instance
  const dispatch = useDispatch();
  const company_name = useSelector(selectcompanies_name);

  // Notification function
  const openNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      placement: 'topRight',
    });
  };

  const handleFinish = async (values) => {
    const res = await dispatch(createData({ modelName: "Status", data: values }));

    if (res.meta.requestStatus === "fulfilled") {
      openNotification('success', 'Success', 'Status created successfully');
      form.resetFields(); // Reset form fields
      dispatch(fetchStatus({ modelName: "Status", company_id: values.company_id }));

      setOpenStatus(false); // Close modal
    } else {
      openNotification('error', 'Error', res.payload?.error || 'Something went wrong');
    }
  };

  useEffect(() => {
    dispatch(getCompaniesByName({}));
  }, [dispatch]);

  return (
    <Modal
      open={OpenStatus}
      title="Status"
      onCancel={() => {
        form.resetFields(); // Reset form when closing modal
        setOpenStatus(false);
      }}
      footer={null}
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        {/* Company Name */}
        <Form.Item label="Company" name="company_id" rules={[{ required: true, message: "Please select a company" }]}>
          <Select placeholder="Select Company" options={company_name?.map((dta) => ({ label: dta.companyName, value: dta._id }))} />
        </Form.Item>

        {/* Status Name */}
        <Form.Item label="Status" name="Status" rules={[{ required: true, message: "Please enter a Status name" }]}>
          <Input placeholder="Status Name" />
        </Form.Item>

        <br />
        <Button type="primary" htmlType="submit">Ok</Button>
      </Form>
    </Modal>
  );
}

export default ModalStatus;
