import React, { useEffect, useState } from "react";
import { Table, Select, Card, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchStockTransaction, selectstockCheckout } from "../app/Service/purchaseOrderSlice";

const { Option } = Select;

const StockTransaction = () => {
    const dispatch = useDispatch();
    const transactions = useSelector(selectstockCheckout);
    const [filterType, setFilterType] = useState("All");

    useEffect(() => {
        dispatch(fetchStockTransaction());
    }, [dispatch]);

    const filteredTransactions = filterType === "All"
        ? transactions
        : transactions.filter((t) => t.transactionType === filterType);

    // Define colors for each transaction type
    const getTransactionTag = (type) => {
        const colorMap = {
            "Stock In": "green",
            "Stock Out": "red",
            "Purchase Return": "blue",
            "Sale Return": "orange"
        };
        return <Tag color={colorMap[type] || "default"}>{type}</Tag>;
    };

    const columns = [
        {
            title: "Product",
            dataIndex: "product",
            key: "product",
            render: (product) => product?.name || "Unknown"
        },
        {
            title: "Transaction Type",
            dataIndex: "transactionType",
            key: "transactionType",
            render: (type) => getTransactionTag(type),
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "Transaction Date",
            dataIndex: "transactionDate",
            key: "transactionDate",
            render: (date) => new Date(date).toLocaleDateString(),
        },
    ];

    return (
        <Card title="Stock Transactions">
            <Select
                value={filterType}
                onChange={setFilterType}
                style={{ width: 200, marginBottom: 16 }}
            >
                <Option value="All">All</Option>
                <Option value="Stock In">Stock In</Option>
                <Option value="Stock Out">Stock Out</Option>
                <Option value="Purchase Return">Purchase Return</Option>
                <Option value="Sale Return">Sale Return</Option>
            </Select>

            <Table
                dataSource={filteredTransactions}
                columns={columns}
                rowKey="_id"
                pagination={{ pageSize: 12 }}
            />
        </Card>
    );
};

export default StockTransaction;
