// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    padding: 0px;
    margin: 0px;
}

.ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 64px;
    background: rgba(250, 251, 252, .1);
}

.app-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
}
.myPlotTabs.ant-tabs .ant-tabs-tab{
background-color: #f0f0f04a;
}
.myPlotTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #00ff1121;
}
.myPlotTabs.ant-tabs-left >.ant-tabs-nav .ant-tabs-ink-bar{
    left: 0px;
}
.myPlotTabs.ant-tabs .ant-tabs-content-holder{
    width: 98%;
    height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5px 33px 10px 0px;
}

/* .ant-drawer .ant-drawer-body{
    padding: 12px 0px;
} */
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
    height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,0BAA0B;IAC1B,iBAAiB;IACjB,mCAAmC;AACvC;;AAEA;IACI,eAAe;IACf,WAAW;IACX,MAAM;IACN,UAAU;AACd;AACA;AACA,2BAA2B;AAC3B;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,SAAS;AACb;AACA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;;GAEG;AACH;IACI,aAAa;AACjB","sourcesContent":["* {\n    padding: 0px;\n    margin: 0px;\n}\n\n.ant-layout-header {\n    height: 64px;\n    padding: 0 50px;\n    color: rgba(0, 0, 0, 0.88);\n    line-height: 64px;\n    background: rgba(250, 251, 252, .1);\n}\n\n.app-header {\n    position: fixed;\n    width: 100%;\n    top: 0;\n    z-index: 1;\n}\n.myPlotTabs.ant-tabs .ant-tabs-tab{\nbackground-color: #f0f0f04a;\n}\n.myPlotTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {\n    background-color: #00ff1121;\n}\n.myPlotTabs.ant-tabs-left >.ant-tabs-nav .ant-tabs-ink-bar{\n    left: 0px;\n}\n.myPlotTabs.ant-tabs .ant-tabs-content-holder{\n    width: 98%;\n    height: 90%;\n    overflow-x: hidden;\n    overflow-y: auto;\n    padding: 5px 33px 10px 0px;\n}\n\n/* .ant-drawer .ant-drawer-body{\n    padding: 12px 0px;\n} */\n.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{\n    height: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
