import React, { useEffect, useState } from 'react';
import {
    AppstoreOutlined,
    BankOutlined,
    CalendarOutlined,
    FileOutlined,
    MailOutlined,
    MoneyCollectOutlined,
    PlusOutlined,
    ProductOutlined,
    SettingOutlined,
    TableOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Avatar, Button, Flex, Layout, Menu, Switch, Dropdown, Drawer, message } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import api from '../app/api'
import Cookies from 'js-cookie'
import { Modal } from 'antd';
const { Header, Content, Sider, Footer } = Layout;
function Index() {
    const navigate = useNavigate()
    const [theme, setTheme] = useState('light');
    const [collapsed, setCollapsed] = useState(false);
    const [open, setOpen] = useState(false)
    const roles = Cookies.get('roles');
    const toggleTheme = (checked) => {
        setTheme(checked ? 'dark' : 'light');
    };
    const isAdmin = roles?.includes('ADMINISTRATOR');


    const handleMenuClick = async ({ key }) => {
        if (key === '2') {
            try {
                await api.get('/api/auth/logout');
                Cookies.remove('token');
                Cookies.remove('user');
                Cookies.remove('roles');
                message.success('Logged out successfully');
                navigate('/login');
            } catch (error) {
                message.error('Logout failed. Please try again.');
            }
        }
    };

    const items = [
        {
            key: '',
            label: 'Dashboard',
            icon: <AppstoreOutlined />
        },
        {
            key: 'company',
            label: 'Company ',
            icon: <BankOutlined />,
            children: [
                isAdmin &&
                {
                    key: 'company',
                    label: 'Company'
                },
                {
                    key: 'department',
                    label: 'Departments'
                },
            ]
        },
        {
            key: 'employee',
            label: 'Employee ',
            icon: <UserOutlined />,
            children: [
                // {
                //     key: 'employee',
                //     label: 'Create Employee'
                // },
                {
                    key: 'employee-list',
                    label: 'Employee List'
                }
            ]
        },


        {
            key: 'project',
            label: 'Projects',
            icon: <UserOutlined />,

        },
        {
            key: 'member',
            label: 'Member',
            icon: <UserOutlined />,

        },
        {
            key: 'file ',
            label: 'File ',
            icon: <FileOutlined />,
            children: [

                {
                    key: 'File',
                    label: 'Basic Setup'
                },

                {
                    key: 'file-list',
                    label: 'File List'
                },

                {
                    key: 'assign-file-list',
                    label: 'Assign File List'
                }, {
                    key: 'transfer-file-list',
                    label: 'Transfer History'
                },
                {
                    key: 'charges',
                    label: 'Charges'
                },

            ]

        },

        {
            key: 'Supplier',
            label: 'Supplier',
            icon: <UserOutlined />,

        },
        {
            key: 'Products',
            label: 'Products',
            icon: <ProductOutlined />,

        },
        {
            key: 'user',
            label: 'Purchase ',
            icon: <ProductOutlined />,
            children: [
                {
                    key: 'Purchase-Order',
                    label: 'Purchase Order',

                },
                {
                    key: 'Purchase-Order-list',
                    label: 'Purchase Order List',

                },
                {
                    key: 'Order',
                    label: 'Order',

                },
                {
                    key: 'po-confirmed',
                    label: 'Purchase Invoice',

                },

            ]
        },
        {
            key: 'Sale',
            label: 'Sale ',
            icon: <ProductOutlined />,
            children: [
                {
                    key: '/Sale/Order',
                    label: 'Sale Order',

                },
                {
                    key: '/Sale/Order/list',
                    label: 'Sale Order List',

                },
                // {
                //     key: 'Order',
                //     label: 'Order',

                // },
                // {
                //     key: 'po-confirmed',
                //     label: 'PO  Confirmed',

                // },

            ]
        },

        {
            key: 'inventory',
            label: 'Inventory',
            icon: <TableOutlined />,

        },
        {
            key: 'stock-transaction',
            label: 'Stock Transaction',
            icon: <TableOutlined />,

        },





        // {
        //     key: 'bank',
        //     label: 'Bank ',
        //     icon: <BankOutlined />,
        //     children: [
        //         {
        //             key: 'bank',
        //             label: 'Add Bank'
        //         },
        //         {
        //             key: 'bank-list',
        //             label: ' Bank List'
        //         },

        //     ]
        // },
        {
            key: '',
            label: 'Accounting ',
            icon: <MoneyCollectOutlined />,
            children: [
                {
                    key: 'Chart-Of-Accounts',
                    label: 'Chart Of  Accounts'
                },
                {
                    key: 'Journal Entries',
                    label: 'Journal Entries'
                },
                {
                    key: 'Trial-Balance',
                    label: 'Trial Balance'
                },
                // {
                //     key: 'Income',
                //     label: 'Income'
                // },
                // {
                //     key: 'payment-Invoice',
                //     label: ' Payment Invoice'
                // },
                // {
                //     key: 'payment-list-Invoice',
                //     label: ' Payment list Invoice'
                // },

            ]
        },
        // {
        //     key: 'Reports',
        //     label: 'Reports ',
        //     icon: <BankOutlined />,
        //     children: [
        //         {
        //             key: 'Income',
        //             label: 'Income'
        //         },
        //         {
        //             key: 'Transaction',
        //             label: 'Transection'
        //         },
        //         {
        //             key: 'Account Balance',
        //             label: 'Account Balance'
        //         },
        //         {
        //             key: 'Expense',
        //             label: 'Expense'
        //         },
        //         {
        //             key: 'Trial Balance',
        //             label: 'Trial Balance'
        //         },
        //         {
        //             key: 'Balance Sheet',
        //             label: 'Balance Sheet'
        //         }
        //     ]
        // },
    ];


    const profileMenu = (
        <Menu
            onClick={handleMenuClick} // Attach click handler
            items={[
                {
                    key: '1',
                    label: 'Account Settings'
                },
                {
                    key: '2',
                    label: 'Logout'
                }
            ]}
        />
    );
    const handleChangeMeue = (path) => {
        setOpen(false)
        navigate(path?.key)
    }
    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const response = await api.get('/api/auth/authorized');
            } catch (error) {

                Modal.error({
                    title: 'Unauthorized Access',
                    content: error?.response?.data?.error + " . Please log in to access this page." || 'You are not logged in. Please log in to access this page.',
                    onOk: () => {
                        Cookies.remove('token');
                        Cookies.remove('user');
                        Cookies.remove('roles');
                        window.location.href = '/login';
                    },
                });
            }
        };

        checkLoginStatus();
    }, [navigate]); //


    return (
        <Layout style={{ minHeight: '100vh', overflowY: "hidden" }}>
            <Layout >
                <Sider
                    collapsed={false}
                    theme='dark'
                    style={{ height: "100vh", overflowY: "auto" }}
                // style={{ marginTop: "40px" }}
                >


                    <Menu
                        mode='inline'
                        theme={"dark"}
                        items={items}
                        onClick={handleChangeMeue}
                    />


                </Sider>
                {/* <Header
                    style={{
                        position: 'fixed',
                        top: 0,
                        width: `100%`,
                        zIndex: 1000,
                        height: "40px",
                        background: theme === 'dark' ? '#001529' : 'white',
                        color: theme === 'dark' ? 'white' : '#001529',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0 20px'
                    }}
                >
                    <div style={{ fontWeight: 'bold' }}>Dashboard</div>
                    <Flex gap={10} align='center'>

                        <Button onClick={() => setOpen(true)}>
                            <PlusOutlined />
                        </Button>
                        
                        <Dropdown overlay={profileMenu} trigger={['click']}>
                            <Avatar style={{ cursor: 'pointer' }}>U</Avatar>
                        </Dropdown>
                    </Flex>
                </Header> */}

                <Drawer open={open} onClose={() => setOpen(false)} placement='left' width={300}
                    title={<Header style={{ background: "white", height: "40px" }} >Header</Header>}
                    footer={<Footer >Footer</Footer>}
                    closeIcon={false}

                >

                    <Menu
                        mode='inline'
                        theme={theme}
                        items={items}
                        onClick={handleChangeMeue}
                    />
                </Drawer>
                <Content
                    style={{
                        background: theme === 'dark' ? '#111111' : '#f0f0f0',
                        color: theme === 'dark' ? '#ffffff' : '#000000'
                    }}
                >
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
}

export default Index;
