import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchChargesApi, addChargesApi, updateChargesApi, ReceviePayments } from './ChargesService';

export const fetchChargesApplied = createAsyncThunk(
    'fetch/get',
    async (id, { rejectWithValue }) => {
        try {
            return await fetchChargesApi(id);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const fetchWithScrollChargesApplied = createAsyncThunk(
    'fetch/scrollget',
    async (id, { rejectWithValue }) => {
        try {
            return await fetchChargesApi(id);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const postReceviePayments = createAsyncThunk(
    'fetch/post',
    async (data, { rejectWithValue }) => {
        try {
            return await ReceviePayments(data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const addChargesApplied = createAsyncThunk(
    'fetch/add',
    async (data, { rejectWithValue }) => {
        try {
            return await addChargesApi(data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const updateWitness = createAsyncThunk(
    'fetch/update',
    async (data, { rejectWithValue }) => {
        try {
            return await updateChargesApi(data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

// 


const ChargesSlice = createSlice({
    name: 'fetchChargesSlice',
    initialState: {
        chargesApplied: { count: 0, data: [] },
        loading: false,
        error: null,
        chargesIds: []
    },
    reducers: {
        setChargeIds: (state, action) => {
            state.chargesIds = action.payload;
        },
        clearChargeIds: (state) => {
            state.chargesIds = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchChargesApplied.pending, (state) => {
                state.loading = true;
                state.error = null;
            }).addCase(fetchChargesApplied.fulfilled, (state, action) => {
                state.loading = false;
                state.chargesApplied = action.payload;
            }).addCase(fetchChargesApplied.rejected, (state, action) => {
                state.loading = false;
                state.chargesApplied = []
                state.error = action.payload;
            })
            .addCase(fetchWithScrollChargesApplied.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWithScrollChargesApplied.fulfilled, (state, action) => {
                state.loading = false;
                console.log('action.payload', action.payload)
                const newData = action.payload?.data || [];
                const count = action.payload?.count || 0;
                console.log('state.chargesApplied', state.chargesApplied)
                state.chargesApplied.count = count
                state.chargesApplied.data = [...state.chargesApplied.data, ...newData];
            })
            .addCase(fetchWithScrollChargesApplied.rejected, (state, action) => {
                state.loading = false;
                state.chargesApplied = [];
                state.error = action.payload;
            });


    },
});

export const selectChargesApplied = (state) => state.ChargesSlice.chargesApplied;
export const selectChargesAppliedLoading = (state) => state.ChargesSlice.loading;
export const selectChargesAppliedError = (state) => state.ChargesSlice.error;
export const selectChargeIds = (state) => state.ChargesSlice.chargesIds;
export const { setChargeIds, clearChargeIds } = ChargesSlice.actions;
export default ChargesSlice.reducer;
