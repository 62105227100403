import React, { useEffect, useState } from "react";
import { Card, Table, InputNumber, Select, Button, DatePicker, message, Row, Col, Flex, Tag } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchPurchaseOrderById, updatePurchaseOrder, selectPurchaseOrderLoading, selectSinglePurchaseOrder, returnPurchaseOrder } from "../app/Service/purchaseOrderSlice";
import { fetchSuppliers, selectSuppliers } from "../app/Service/supplierSlice";
import { fetchProducts, selectProducts } from "../app/Service/productSlice";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { fetchAccounts, selectAccounts } from "../app/Service/accountsSlice";

const { Option } = Select;

function EditPurchaseOrder() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accounts = useSelector(selectAccounts);
    const [debitAcc, setDebitAcc] = useState(null);
    const [creditAcc, setCreditAcc] = useState(null);

    const order = useSelector(selectSinglePurchaseOrder);
    const loading = useSelector(selectPurchaseOrderLoading);
    const suppliers = useSelector(selectSuppliers);
    const products = useSelector(selectProducts);
    const [searchParams] = useSearchParams();
    const isReturn = searchParams.get("PurchaseReturn") === "true";
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [status, setstatus] = useState(null);

    const [orderDate, setOrderDate] = useState(null);
    const [data, setData] = useState([]);

    // Fetch purchase order and suppliers
    useEffect(() => {
        dispatch(fetchPurchaseOrderById(id));
        dispatch(fetchSuppliers());
        dispatch(fetchProducts());
    }, [dispatch, id]);
    useEffect(() => {
        dispatch(fetchAccounts());
    }, [dispatch]);

    // Set form fields once order is loaded
    useEffect(() => {
        if (order) {
            setSelectedSupplier(order.supplier?._id || null);
            setOrderDate(dayjs(order.orderDate));
            setstatus(order.status)
            setData(order.products.map((item) => ({
                ...item,
                key: item._id, // Ensure unique key
                totalPrice: item.quantity * item.rate, // Calculate total
            })));
        }
    }, [order]);
    const flattenHierarchy = (nodes, parentPath = "") => {
        const result = [];

        const traverse = (items, parentPath) => {
            items.forEach(item => {
                const currentPath = parentPath ? `${parentPath} > ${item.name}` : item.name;
                if (item.children && item.children.length) {
                    traverse(item.children, currentPath);
                } else {
                    result.push({ ...item, hierarchy: currentPath });
                }
            });
        };

        traverse(nodes, parentPath);
        return result;
    };

    const acc_ = flattenHierarchy(accounts)

    const handleInputChange = (value, key, column) => {
        const newData = data.map((item) =>
            item.key === key
                ? {
                    ...item,
                    [column]: value,
                    totalPrice: column === "quantity"
                        ? value * item.rate  // Update total when quantity changes
                        : item.quantity * value // Update total when rate changes
                }
                : item
        );
        setData(newData);
    };

    // Handle product selection change
    const handleProductChange = (productId, key) => {
        const selectedProduct = products.find((product) => product._id === productId);
        if (selectedProduct) {
            const newData = data.map((item) =>
                item.key === key
                    ? {
                        ...item,
                        product: selectedProduct,
                        rate: selectedProduct.purchasePrice || 0, // Set Rate as purchasePrice
                        quantity: 1,
                        totalPrice: (selectedProduct.purchasePrice || 0) * 1 // Set total price
                    }
                    : item
            );
            setData(newData);
        }
    };

    // Delete product row
    const handleDeleteRow = (key) => {
        const newData = data.filter((item) => item.key !== key);
        setData(newData);
    };

    // Add new product row without default selection
    const handleAddProduct = () => {
        setData([
            ...data,
            {
                key: Date.now(),
                product: null,
                productId: null,
                quantity: 1,
                rate: 0,
                totalPrice: 0,
            },
        ]);
    };

    // Save updated order
    const saveUpdatedOrder = async () => {
        if (!selectedSupplier || !orderDate) {
            message.error("Please select a supplier and order date.");
            return;
        }

        if (status === "Completed" && (!debitAcc || !creditAcc)) {
            message.error("For completed orders, Debit and Credit accounts must be selected.");
            return;
        }
        try {
            await dispatch(
                updatePurchaseOrder({
                    id,
                    orderData: {
                        status,
                        inventoryAccountId: debitAcc,
                        accountsPayableAccountId: creditAcc,
                        supplier: selectedSupplier,
                        orderDate: orderDate.toISOString(),
                        products: data.map(({ key, totalPrice, ...rest }) => rest), // Send only required fields
                    },
                })
            ).unwrap();

            message.success("Purchase order updated successfully!");
            navigate("/Purchase-Order-list");
        } catch (error) {
            message.error("Failed to update purchase order.");
        }
    };
    const returnPurchaseOrder_ = async () => {
        if (!selectedSupplier || !orderDate) {
            message.error("Please select a supplier and order date.");
            return;
        }

        try {
            await dispatch(
                returnPurchaseOrder({
                    id,
                    orderData: {
                        status,
                        supplier: selectedSupplier,
                        orderDate: orderDate.toISOString(),
                        products: data.map(({ key, totalPrice, ...rest }) => rest), // Send only required fields
                    },
                })
            ).unwrap();

            message.success("Purchase order updated successfully!");
            navigate("/Purchase-Order-list");
        } catch (error) {
            message.error("Failed to update purchase order.");
        }
    };

    // Table columns
    const columns = [
        {
            title: "Product",
            dataIndex: "product",
            render: (_, record) => (
                <Select
                    style={{ width: 200 }}
                    value={record.product?._id || undefined} // Ensure selection starts empty
                    onChange={(value) => handleProductChange(value, record.key)}
                    placeholder="Select Product"
                >
                    {products.map((product) => (
                        <Option key={product._id} value={product._id}>
                            {product.name}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            render: (_, record) => (
                <InputNumber
                    min={1}
                    value={record.quantity}
                    onChange={(value) => handleInputChange(value, record.key, "quantity")}
                />
            ),
        },
        {
            title: "Rate",
            dataIndex: "rate",
            render: (_, record) => (
                <InputNumber
                    min={0}
                    value={record.rate}
                    onChange={(value) => handleInputChange(value, record.key, "rate")}
                />
            ),
        },
        {
            title: "Total Price",
            dataIndex: "totalPrice",
            render: (_, record) => <span>{record.totalPrice.toFixed(2)}</span>,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (_, record) => (
                <Button type="link" danger onClick={() => handleDeleteRow(record.key)}>
                    <DeleteOutlined />
                </Button>
            ),
        },
    ];

    // Handlers for selection changes
    const handleDebitSelect = (value) => {
        setDebitAcc(value);
    };

    const handleCreditSelect = (value) => {
        setCreditAcc(value);
    };
    // Function to generate options
    const renderOptions = (accounts) =>
        accounts?.map((item) => ({
            value: item?.srNo,
            label: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "bold", color: "#333" }}>{item?.srNo}</span>
                    <span>{item?.hierarchy}</span>
                </div>
            ),
        }));

    // Function for search filtering
    const filterOption = (input, option) =>
        (option?.label?.props?.children[1]?.props?.children ?? "")
            .toLowerCase()
            .includes(input.toLowerCase());

    return (
        <Card title={(isReturn ? "Return" : "Edit") + " Purchase Order"} style={{ padding: 10 }} extra={<>
            <Row justify={"end"} style={{ width: "360px" }}>
                <Col span={12}>
                    <Button
                        disabled={isReturn}

                        type="dashed" onClick={handleAddProduct} >
                        <PlusOutlined /> Add More Product
                    </Button></Col>
                <Col span={8}>
                    <Button color="danger" variant="filled">
                        {order?.status}

                    </Button>
                </Col>
            </Row>
        </>}>
            <Row gutter={[16, 16]} align="middle">
                <Col span={8}>
                    <Select
                        placeholder="Select Supplier"
                        style={{ width: "100%" }}
                        value={selectedSupplier}
                        onChange={setSelectedSupplier}
                        disabled={isReturn}
                    >
                        {suppliers.map((supplier) => (
                            <Option key={supplier._id} value={supplier._id}>
                                {supplier.name}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={8}>
                    <DatePicker style={{ width: "100%" }} value={orderDate}
                        disabled={isReturn}
                        onChange={(date) => setOrderDate(date)} />
                </Col>
                <Col span={8}>
                    <Select
                        placeholder="Order Status"
                        style={{ width: "100%" }}
                        value={status}
                        onChange={(e) => setstatus(e)}
                        disabled={isReturn}
                    >
                        {['Pending', 'Completed', "Cancelled"].map((status) => (
                            <Option key={status} value={status}>
                                {status}
                            </Option>
                        ))}
                    </Select>
                </Col>
                {status === "Completed" && (
                    <>
                        <Col span={12}>
                            <Select
                                style={{ width: "100%" }}
                                value={debitAcc}  // Separate state for debit account
                                allowClear
                                showSearch
                                className="acc"
                                placeholder="Select Debit Account"
                                onChange={handleDebitSelect}
                                disabled={isReturn}
                                options={renderOptions(acc_)}
                                filterOption={filterOption}
                            />
                        </Col>
                        <Col span={12}>
                            <Select
                                style={{ width: "100%" }}
                                value={creditAcc}  // Separate state for credit account
                                allowClear
                                showSearch
                                className="acc"
                                placeholder="Select Credit Account"
                                onChange={handleCreditSelect}
                                disabled={isReturn}
                                options={renderOptions(acc_)}
                                filterOption={filterOption}
                            />
                        </Col>
                    </>
                )}


            </Row>

            <Table style={{ marginTop: "15px" }} dataSource={data} columns={columns} rowKey="key" pagination={{ pageSize: 5 }} />

            {/* Grand Total */}
            <h3 style={{ textAlign: "right" }}>
                Grand Total: {data.reduce((sum, item) => sum + item.totalPrice, 0).toFixed(2)}
            </h3>

            {/* Save Button */}
            <Flex justify="end">

                {isReturn ? <Button type="primary" onClick={returnPurchaseOrder_} style={{ marginTop: 10 }}>
                    Return Purchase
                </Button> :
                    <Button type="primary" onClick={saveUpdatedOrder} style={{ marginTop: 10 }}>
                        Save Changes
                    </Button>}
            </Flex>
        </Card>
    );
}

export default EditPurchaseOrder;
