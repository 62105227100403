import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { memberService } from "./memberService";

export const fetchmember = createAsyncThunk(
    "member/fetchAll",
    async ({ id, query, limit, start }, { rejectWithValue }) => {
        try {
            return await memberService.getAllmember({ id, query, limit, start });
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

export const handlePrint = createAsyncThunk(
    "member/print",
    async (id, { rejectWithValue }) => {
        try {
            return await memberService.handlePrint(id);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

export const exportmember = createAsyncThunk(
    "member/export",
    async (query, { rejectWithValue }) => {
        try {
            return await memberService.exportmember(query);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Add new company
export const addmember = createAsyncThunk(
    "member/add",
    async (_data, { rejectWithValue }) => {
        try {
            return await memberService.createmember(_data);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Import company data
export const importmember = createAsyncThunk(
    "member/import",
    async (_data, { rejectWithValue }) => {
        try {
            return await memberService.importmember(_data);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Update existing company
export const updatemember = createAsyncThunk(
    "member/update",
    async ({ id, updatedData }, { rejectWithValue }) => {
        try {
            return await memberService.updatemember(id, updatedData);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Delete company
export const deletemember = createAsyncThunk(
    "member/delete",
    async (id, { rejectWithValue }) => {
        try {
            await memberService.deletemember(id);
            return id;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);
export const getmember = createAsyncThunk(
    "member/get",
    async (id, { rejectWithValue }) => {
        try {
            return  await memberService.getmember(id);
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);


const memberSlice = createSlice({
    name: "members",
    initialState: {
        members: [],
        loading: false,
        member: null,
        error: null,
        importLoading: false, // Track import loading state
        importError: null,   // Track import error state
        importSuccess: false, // Track import success state
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchmember.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchmember.fulfilled, (state, action) => {
                state.loading = false;
                state.members = action.payload;
            })
            .addCase(fetchmember.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getmember.pending, (state) => {
                state.loading = true;
            })
            .addCase(getmember.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
            })
            .addCase(getmember.rejected, (state, action) => {
                state.loading = false;
                state.member = null;
                state.error = action.error.message;
            })
            .addCase(addmember.fulfilled, (state, action) => {

            })
            .addCase(updatemember.fulfilled, (state, action) => {

            })
            .addCase(deletemember.fulfilled, (state, action) => {

            })
            .addCase(importmember.pending, (state) => {
                state.importLoading = true;
                state.importSuccess = false; 
                state.importError = null;    
            })
            .addCase(importmember.fulfilled, (state, action) => {
                state.importLoading = false;
                state.importSuccess = true;  // Set success flag when import completes

            })
            .addCase(importmember.rejected, (state, action) => {
                state.importLoading = false;
                state.importError = action.error.message;  // Capture error message
            });
    },
});

export const selectmembers = (state) => state.memberSlice.members;
export const selectmember = (state) => state.memberSlice.member;

export const selectLoading = (state) => state.memberSlice.loading;
export const selectError = (state) => state.memberSlice.error;
export const selectImportLoading = (state) => state.memberSlice.importLoading; // Get import loading state
export const selectImportError = (state) => state.memberSlice.importError;   // Get import error state
export const selectImportSuccess = (state) => state.memberSlice.importSuccess; // Get import success state

export default memberSlice.reducer;
