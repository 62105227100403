import React, { useState } from "react";
import { Modal, Input, Form, Button, message, Select, Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { fetchChargesApplied, postReceviePayments } from "../app/Service/ChargesSlice";
import Swal from "sweetalert2";

function ReceivePayment({ Company, limit, setStart }) {
    const [form] = Form.useForm();
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch()
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => {
        form.resetFields();
        setIsOpen(false);
    };


    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            console.log("Payment Data:", values);

            const response = await dispatch(postReceviePayments({ ...values, company_id: Company }));

            if (response.meta.requestStatus === "fulfilled") {
                Swal.fire({
                    title: "Payment Successful!",
                    text: `Payment of ${values.amount} received successfully.`,
                    icon: "success",
                    confirmButtonText: "OK",
                });

                dispatch(fetchChargesApplied({ id: Company, limit, start: 0 }));
                setStart(0);
                // form.resetFields();
            } else {
                throw new Error("Payment processing failed.");
            }
        } catch (error) {
            console.error("Validation or Submission Failed:", error);
            Swal.fire({
                title: "Error!",
                text: error.message || "Something went wrong while processing the payment.",
                icon: "error",
                confirmButtonText: "Try Again",
            });
        }
    };


    return (
        <div style={{ padding: "20px" }}>
            <Button color="purple" variant="filled" onClick={handleOpen}>
                Receive Payment
            </Button>
            <Modal
                title="Receive Payment"
                open={isOpen}
                onCancel={handleClose}
                footer={[
                    <Button key="cancel" onClick={handleClose}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSubmit}>
                        Submit
                    </Button>,
                ]}
                style={{ top: 20 }}
                width={600}
            >
                <Form form={form} layout="vertical">
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Charge ID"
                                name="sr"
                                rules={[{ required: true, message: "Please enter an id" }]}
                            >
                                <Input type="number" placeholder="Enter amount" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Installment Number"
                                name="ins_id"
                                rules={[{ required: false, message: "Please enter an Instalment number" }]}
                            >
                                <Input type="number" placeholder="Enter amount" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[{ required: true, message: "Please enter an amount" }]}
                            >
                                <Input type="number" placeholder="Enter amount" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Payment Method"
                                name="paymentMethod"
                                rules={[{ required: true, message: "Please select a payment method" }]}
                            >
                                <Select placeholder="Select Payment Method">
                                    <Select.Option value="Cash">Cash</Select.Option>
                                    <Select.Option value="Bank Transfer">Bank Transfer</Select.Option>
                                    <Select.Option value="Credit Card">Credit Card</Select.Option>
                                    <Select.Option value="Cheque">Cheque</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Notes" name="notes">
                                <Input.TextArea placeholder="Optional notes" rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
}

export default ReceivePayment;
