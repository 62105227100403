import api from '../api'

const API_URL = "/api/plot/assign";
const API_URL_ = "/api/plot/transfer";

// reject-accept


export const assignServices = {
    async getAssignPlot({ id, query = "", limit = 20, start = 0 }) {
        try {
            const response = await api.get(`${API_URL}?query=${query}&limit=${limit}&start=${start}&id=${id}`);
            console.log('response.data', response.data)
            return response.data;
        } catch (error) {
            console.error('Error fetching plot:', error);
            throw error;
        }
    },
    async getTransferPlot({ id, query = "", limit = 20, start = 0, type = "" }) {
        try {
            const response = await api.get(`${API_URL_}/data?query=${query}&limit=${limit}&start=${start}&id=${id}&type=${type}`);
            console.log('response.data', response.data)
            return response.data;
        } catch (error) {
            console.error('Error fetching plot:', error);
            throw error;
        }
    },
    async rejectAndTransfer({ id, status = "", limit = 20, start = 0 }) {
        try {
            const response = await api.post(`/api/plot/reject?id=${id}&status=${status}`);
            return response.data;
        } catch (error) {
            console.log('error', error)
            console.error('Error fetching plot:', error);
            throw error;
        }
    }
    ,
    // async getplotByName({ }) {
    //     try {
    //         const response = await api.get(`${API_URL}/user/id`);
    //         return response.data;
    //     } catch (error) {
    //         throw error;
    //     }
    // },
    async exportassignplot({ id, type }) {

        try {
            const response = await api.get(`${API_URL}/exportexcelpdf`, {
                params: { id, type },
                responseType: "",
            });
            if (type === 'pdf') {
                const pdfUrl = api.getUri() + "/api/uploads/" + response.data.url;
                const link = document.createElement("a");
                link.href = pdfUrl;
                const timestamp = new Date();
                const fileName = `assign_plot${timestamp}.pdf`;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else if (type === 'excel') {
                const pdfUrl = api.getUri() + "/api/uploads/" + response.data.url;
                const link = document.createElement("a");
                link.href = pdfUrl;
                const timestamp = new Date();
                const fileName = `assign_plot${timestamp}.xlsx`;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error(`Error exporting plot (${type}):`, error);
            alert(`An error occurred while exporting the Departments as ${type}.`);
        }

    },



    // async importplot(data) {
    //     const response = await api.get(API_URL + "/import/data?id="+data.id+"&fileId="+data.fileId);
    //     if (!response.body) {
    //         throw new Error("ReadableStream not supported");
    //       }
    //     return response.data;
    // },

    async createAssignplot(data) {
        const response = await api.post(API_URL + "/create", data);
        return response.data;
    },
    async createTransferplot(data) {
        const response = await api.post(API_URL_ + "/create", data);
        return response.data;
    },
    // transfer
    // async updateplot(id, data) {
    //     const response = await api.put(`${API_URL}/${id}`, data);
    //     return response.data;
    // },
    // async deleteplot(id) {
    //     const response = await api.delete(`${API_URL}/${id}`);
    //     return response.data;
    // },
    // async handlePrint(id) {
    //     try {
    //         const response = await api.get(API_URL + "/print/data?id=" + id, {
    //             responseType: "blob",
    //             headers: {
    //                 "Content-Type": "application/pdf",
    //             },
    //         });

    //         const blob = new Blob([response.data], { type: "application/pdf" });
    //         const pdfUrl = URL.createObjectURL(blob);

    //         const iframe = document.createElement("iframe");
    //         iframe.style.display = "none";
    //         iframe.src = pdfUrl;
    //         document.body.appendChild(iframe);

    //         iframe.onload = () => {
    //             iframe.contentWindow.print();
    //         };
    //     } catch (error) {
    //         console.error("Error fetching PDF:", error);
    //         alert("Failed to fetch or print PDF.");
    //     }
    // }
};
