import React, { useEffect, useState } from 'react';
import { Typography, Tag, Select, Input, Button, Space, Flex, Divider, Drawer, Tabs } from 'antd';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";
import { get_MemberShip, get_MemberShip_byId } from '../../App/Slice/membershipSlice';
import TransferModal from '../../Components/Forms/TransferModal';
import TransferForm from '../../Components/View/TransferForm';
import { get_phases } from '../../App/Slice/phaseSlice';
import { get_Owners } from '../../App/Slice/ownerSlice';
import AddPlot from '../../Components/Forms/AddPlot';
import TabPane from 'antd/es/tabs/TabPane';
import PlotAllocationTable from '../../Components/Tabs/ImportExcelMembership';
import CreateCharges from '../../Components/Forms/CreateCharges';
import PrintDataModal from '../../Components/View/PrintDataModal';
import { get_Charges } from '../../App/Slice/chargesSlice';
import MemberShipPreview from '../../Components/Tabs/MemberShipPreview';
import { baseurl } from '../../utils/baseUrl';

const PlotTransfer = () => {
    const { memberships, loading } = useSelector(state => state.Membership);
    let data = useSelector(state => state.Membership);
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isOpen, setIsOpen] = useState(false)
    const [printModalVisible, setPrintModalVisible] = useState(false); // State 
    const [printData, setPrintData] = useState([]);
    const dispatch = useDispatch();
    const [dataSource, setDataSource] = useState([]);
    const [startRange, setStartRange] = useState(1); // Default start range as 1
    const [endRange, setEndRange] = useState(100); // Default end range as 100
    const [detailDrawer, setDetailDrawer] = useState(false);

    const [detailDrawer2, setDetailDrawer2] = useState(false);


    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);
    const [Loading, setLoading] = useState(false);

    const showDrawer = () => {
        dispatch(get_phases());
        dispatch(get_Owners());
        setOpen(true);
    };
    const fetchMemberships = () => {
        dispatch(get_MemberShip({
            start: startRange,
            end: endRange
        }));
    };
    const fetchFiles = async () => {
        setDetailDrawer2(true)
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(baseurl + '/api/files');
            if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }
            const data = await response.json();
            setFiles(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (memberships?.data?.length > 0) {
            const formattedData = memberships.data.map((membership, index) => ({
                id: membership._id,
                srNo: index + 1,
                newMembershipId: membership.newMembershipId,
                oldMembershipId: membership.oldMembershipId,
                phase: membership.phase?.phase_name || "N/A",
                block: membership.block?.block_name || "N/A",
                status: membership.status,
                landSize: membership.landSize || "N/A",
                landUnit: membership.landUnit || "N/A",
                cost: membership.cost || "N/A",
                property: membership.property || "N/A",
                propertyType: membership.propertyType || "N/A",
                D1: membership.D1 || "N/A",
                D2: membership.D2 || "N/A",
                address: membership.address || "N/A",
                plotNo: membership.plotNo || "N/A",
                owner: membership.owners.map(owner => owner.owner?.ownerName || "N/A").join(", "),
                cnic: membership.owners.map(owner => owner.owner?.cnic || "N/A").join(", "),
                share: membership.owners.map(owner => owner.share || "N/A").join(", "),
                transferCount: membership.transferCount,
                transferNo: membership.transferNo || "N/A",
            }));
            setDataSource(formattedData);
        }
    }, [memberships]);


    useEffect(() => {
        fetchMemberships()
    }, [])

    const handleStartRangeChange = (e) => {
        setStartRange(e.target.value); // Update start range filter
    };

    const handleEndRangeChange = (e) => {
        setEndRange(e.target.value); // Update end range filter
    };

    const statusColorMap = {
        Draft: 'default',
        'Submitted to Accounts': 'blue',
        'Approved by Accounts': 'green',
        'Rejected by Accounts': 'red',
        'Open': 'gold',
    };

    const columns = [
        { field: 'srNo', headerName: 'Sr #', width: 30 },
        { field: 'oldMembershipId', headerName: 'Old ID', width: 100 },
        { field: 'newMembershipId', headerName: 'New ID', width: 100 },
        {
            field: 'phase',
            headerName: 'Phase',
            width: 100,
        },
        {
            field: 'block',
            headerName: 'Block',
            width: 100,
        },
        { field: 'address', headerName: 'Street', width: 100 },
        { field: 'plotNo', headerName: 'Plot #', width: 100 },
        { field: 'property', headerName: 'Property', width: 200 },
        { field: 'propertyType', headerName: 'Property Type', width: 200 }, // Correct field name
        {
            field: 'dimension',
            headerName: 'Dimension',
            width: 200,
            renderCell: (params) => `${params.row.D1}x${params.row.D2}`, // Render D1 x D2
        },
        {
            field: 'Size',
            headerName: 'Size',
            width: 200,
            renderCell: (params) => `${params.row.landSize} ${params.row.landUnit}`,
        },
        { field: 'owner', headerName: 'Owner', width: 300 },
        { field: 'cnic', headerName: 'CNIC', width: 300 },
        { field: 'share', headerName: 'Share', width: 100 },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            renderCell: (params) => (
                <Tag color={statusColorMap[params.value] || 'default'}>
                    {params.value}
                </Tag>
            ),
        },
        {
            field: 'Action',
            headerName: 'Details',
            width: 100,
            pinned: 'right',
            renderCell: (params) => (
                <Button
                    type="primary"
                    danger
                    onClick={() => {
                        dispatch(get_MemberShip_byId(params.row.id));
                        dispatch(get_Charges(params.row.id));
                        setDetailDrawer(true);
                    }}
                >
                    Detail
                </Button>
            ),
        },
    ];


    return (
        <div style={{ height: 600, width: '100%' }}>
            <TransferForm />
            <AddPlot open={open} setOpen={setOpen} />
            <Drawer
                width="90%"
                closable={false}
                onClose={() => setDetailDrawer2(false)}
                open={detailDrawer2}
                destroyOnClose={true}
            >
                <Tabs>
                    <TabPane tab="Import Data" key={"1"}>
                        <PlotAllocationTable Loading={Loading} files={files} fetchFiles={fetchFiles} />
                    </TabPane>
                </Tabs>
            </Drawer >
            <Drawer
                width="90%"
                closable={false}
                onClose={() => setDetailDrawer(false)}
                open={detailDrawer}
            >
                <MemberShipPreview />
            </Drawer>
            <CreateCharges isOpen={isOpen} onClose={() => setIsOpen(false)} title={"Apply  Charges"} installment={false} selectedRowKeys={selectedRowKeys} />
            <TransferModal visible={visible} setTransferModalVisible={setVisible} />
            <PrintDataModal
                visible={printModalVisible}
                onClose={() => setPrintModalVisible(false)}
                data={printData} // Pass the selected data for printing
            />
            <Flex align='center' justify='space-between'>
                <Typography.Title level={4}>File System</Typography.Title>
                <Space>
                    {memberships?.data?.length &&
                        <Button type="text" disabled={selectedRowKeys.length < 1} style={{ backgroundColor: "blue", color: "white" }} onClick={() => {
                            const selectedData = memberships?.data?.filter(membership => selectedRowKeys.includes(membership._id));
                            setPrintModalVisible(true);
                            setPrintData(selectedData)
                        }} >
                            Print
                        </Button>}
                    {memberships?.data?.length &&
                        <Button type="primary" disabled={selectedRowKeys.length < 1} style={{ backgroundColor: "blue", color: "white" }} onClick={() => setIsOpen(true)} >
                            Create Charges
                        </Button>}
                    <Button type="text" style={{ backgroundColor: "blue", color: "white" }} onClick={fetchFiles}>
                        Import Data
                    </Button>
                    <Button type="text" style={{ backgroundColor: "blue", color: "white" }} onClick={() => {
                        dispatch(get_Owners());
                        setVisible(true)
                    }
                    } >
                        Transfer
                    </Button>
                    <Button type="primary" onClick={showDrawer}>
                        Add Membership
                    </Button>
                </Space>
            </Flex>
            <Divider />
            <Flex justify='space-between'>
                <Space style={{ marginBottom: 16 }}>
                    <Input
                        type="number"
                        value={startRange}
                        onChange={handleStartRangeChange}
                        style={{ width: 120, marginRight: 16 }}
                        placeholder='start'
                    />
                    <Input
                        type="number"
                        value={endRange}
                        onChange={handleEndRangeChange}
                        style={{ width: 120 }}
                        placeholder='end'
                    />
                    <Button type="primary" ghost onClick={fetchMemberships}>Search</Button>
                </Space>
                <Space style={{ marginBottom: 16 }}>

                    <Button type="primary" ghost >Download</Button>
                </Space>
            </Flex>
            <DataGrid
                rows={dataSource}
                columns={columns}
                rowCount={memberships?.data?.length || 0}
                loading={loading?.memberships}
                checkboxSelection
                onRowSelectionModelChange={(ids) => {
                    setSelectedRowKeys(ids)
                }}
                sx={{ width: "100%" }}
            />
        </div>
    );
};

export default PlotTransfer;
