import axios from "axios";
import api from "../api";

const API_URL = "/api/sales-orders"; // Change based on your backend URL

// 📌 Create Sales Order
const createSalesOrder = async (orderData) => {
    const response = await api.post(API_URL, orderData);
    return response.data;
};

// 📌 Get All Sales Orders
const getSalesOrders = async () => {
    const response = await api.get(API_URL);
    return response.data;
};

// 📌 Get Single Sales Order by ID
const getSalesOrderById = async (id) => {
    const response = await api.get(`${API_URL}/${id}`);
    return response.data;
};

// 📌 Update Sales Order
const updateSalesOrder = async (id, orderData) => {
    const response = await api.put(`${API_URL}/${id}`, orderData);
    return response.data;
};

// 📌 Delete Sales Order
const deleteSalesOrder = async (id) => {
    const response = await api.delete(`${API_URL}/${id}`);
    return response.data;
};

const salesOrderService = {
    createSalesOrder,
    getSalesOrders,
    getSalesOrderById,
    updateSalesOrder,
    deleteSalesOrder,
};

export default salesOrderService;
