import React, { useEffect, useState } from 'react'
import { Button, Card, Flex, Select, Space, Table } from 'antd'
import ModalProperty from './ModalProperty'
import { getCompaniesByName, selectcompanies_name } from '../app/Service/companySlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, fetchProperties, selectProperties } from '../app/Service/DataSlice';

function Property() {
  const [OpenProperty, setOpenProperty] = useState(false);
  const dispatch = useDispatch();
  const company_name = useSelector(selectcompanies_name);
  const Properties = useSelector(selectProperties);
  const [company, setCompany] = useState(null);

  

  useEffect(() => {
    if (company_name.length > 0) {
      const defaultCompanyId = company_name[0]?._id;
      setCompany(defaultCompanyId);
      dispatch(fetchProperties({ modelName: "Property", company_id: defaultCompanyId }));
    }
  }, [company_name, dispatch]);

  // Handle company selection change
  const handleCompanyChange = (value) => {
    setCompany(value);
    dispatch(fetchProperties({ modelName: "Property", company_id: value }));
  };
  return (
    <>
      <ModalProperty OpenProperty={OpenProperty} setOpenProperty={setOpenProperty} />

      <Card
        title={"Property"}
        extra={
          <Select
            style={{ width: "200px" }}
            value={company}
            placeholder="Select Company"
            options={company_name?.map((dta) => ({ label: dta.companyName, value: dta._id }))}
            onChange={handleCompanyChange}
          />
        }
      >
        <Flex style={{ marginBottom: "10px" }}>
          <Space>
            <Button type="primary" onClick={() => setOpenProperty(true)}>+ Add Property</Button>
          </Space>
        </Flex>

        <Table
          columns={[
            {
              key: "1",
              title: "Property Name",
              dataIndex: "Property",
              width: "80%"
            },
            {
              key: "2",
              title: "Action",
              dataIndex: "_id",
              render: (id) => <Button type='text' danger>Delete</Button>
            }
          ]}
          dataSource={Properties?.data?.map((Property) => ({ key: Property._id, ...Property }))}
        />
      </Card>
    </>
  );
}



export default Property
